import React from 'react'
import parse from 'html-react-parser'

import { Collapse } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

const { Panel } = Collapse

const Section10 = (props: any) => {
    return (
        <section id='program'>
            <article className='container CB10'>
                <h2>{parse(props.page.b10.text_1 || '')}</h2>

                <Collapse accordion bordered={false} className='items' expandIconPosition='end' expandIcon={({ isActive }) => <PlusOutlined style={{ color: `#FF9900`, fontSize: '26px' }} rotate={isActive ? -45 : 0} />}>
                    {!props.questionsList.length
                        ? ''
                        : props.questionsList.map((item: any) => {
                            return (
                                <Panel header={item.header} id={item.dcq_id} key={item.dcq_id} className='item'>
                                    {parse(item.text || '')}
                                </Panel>
                            )
                        })
                    }
                </Collapse>
            </article>
        </section>
    )
}

export default Section10