import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { AppStateType } from '../../../../bll/store'
import AddDeleteComments from './addDeleteComments'
import openNotification from '../../../common/ui/notification'
import errorHandler from '../../../../bll/errorHandler'
import { addCommentToCourse, clearComments, deleteCommentInCourse, getComments } from '../../../../bll/lkCourses/lkCoursesOperations'

const AddDeleteCommentsContainer = (props: any) => {
    const [selectedAddID, setSelectedAddID] = React.useState('') // Выбранный идентификатор отзыва для добавления
    const [selectedDeleteID, setSelectedDeleteID] = React.useState('') // Выбранный идентификатор отзыва для удаления


    React.useEffect(() => {
        if (props.getOpenAddDeleteComments) {
            props.getComments(props.getEditableCourseID).then((result: any) => {
                switch (result?.code) {
                    case 'OK': break

                    case "ERR_QUERY_STRING": { return openNotification("Отзывы...", props.msg.err_0096, "error", "bottomRight") }
                    default: errorHandler(result?.code, props.msg); break
                }
            })
        }

        return () => {
            props.clearComments()
        }
    }, [props.getOpenAddDeleteComments]) // eslint-disable-line react-hooks/exhaustive-deps


    // Добавление дисциплины в курс
    const eventAddToCourse = () => {
        props.addCommentToCourse(props.getEditableCourseID, selectedAddID).then((result: any) => {
            switch (result?.code) {
                case 'OK': 
                    setSelectedAddID('')
                break

                case "ERR_QUERY_STRING": { return openNotification("Добавление отзыва...", props.msg.err_0097, "error", "bottomRight") }
                default: errorHandler(result?.code, props.msg); break
            }
        })
    }

    // Удаление дисциплины из курса
    const eventDeleteInCourse = () => {
        props.deleteCommentInCourse(selectedDeleteID, props.getEditableCourseID).then((result: any) => {
            switch (result?.code) {
                case 'OK': 
                    setSelectedDeleteID('')
                break

                case "ERR_QUERY_STRING": { return openNotification("Удаление отзыва...", props.msg.err_0098, "error", "bottomRight") }
                default: errorHandler(result?.code, props.msg); break
            }
        })
    }


    // Свойства передаваемые в компоненту
    const propsToComponent = {
        selectedAddID, setSelectedAddID, // Выбранная дисциплина для добавления
        selectedDeleteID, setSelectedDeleteID, // Выбранная дисциплина для удаления

        eventAddToCourse, // Добавление дисциплины в курс
        eventDeleteInCourse, // Удаление дисциплины из курса
    }

    return (
        <AddDeleteComments {...props} {...propsToComponent} />
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        comments: state.courses.commentsList
    }
}

export default compose(connect(mapState, { 
    getComments, clearComments, addCommentToCourse, deleteCommentInCourse
}))(AddDeleteCommentsContainer)