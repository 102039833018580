import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import Zoom from '@mui/material/Zoom'


export const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow={true} TransitionComponent={Zoom} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#48FFFE",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#48FFFE",
        fontWeight: 600,
        fontSize: "13px",
        letterSpacing: "2px",
        fontFamily: 'Inter, sans-serif',
        color: "#303B44",
        textAlign: "center",
        borderRadius: "6px",
        boxShadow: '0px 0px 6px 2px #48FFFE'
    },
}))