import types from './authTypes'

let initialState = {
    isAuthorized: false, // Авторизация в системе
    isAppInitialized: false, // Инициализация личного кабинета

    myProfile: {
        login: '', // Логин оператора
        lastName: '', // Фамилия оператора
        firstName: '', // Имя оператора
        secondName: '', // Отчество оператора
        accountCreateDate: '', // Дата создания аккаунта оператора
        avatar: '', // Аватар оператора
    }
}

const authReducer = (state = initialState, action: any) => {
    switch (action.type) {
        
        // Выход из системы
        case types.AUTH_SET_LOGOUT: {
            return {
                ...state = initialState
            }
        }

        // Авторизация в системе
        case types.AUTH_SET_IS_AUTHORIZED: {
            return {
                ...state,
                isAuthorized: action.isAuthorized
            }
        }

        // Получение персональных данных оператора
        case types.AUTH_SET_MY_PROFILE: {
            return {
                ...state,
                myProfile: {
                    ...state.myProfile,
                    login: action.login,
                    lastName: action.lastName,
                    firstName: action.firstName,
                    secondName: action.secondName,
                    accountCreateDate: action.accountCreateDate,
                    avatar: action.avatar,
                }
            }
        }

        // Инициализация личного кабинета
        case types.AUTH_SET_APP_INITIALIZED: {
            return {
                ...state,
                isAppInitialized: action.isAppInitialized
            }
        }

        default: return state
    }
}

export default authReducer