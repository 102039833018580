import React from 'react'
import { Image } from 'antd'

import css from './nav-04.module.scss'

const Nav_04 = (props: any) => {
    const [visible, setVisible] = React.useState(false) // Открыто или закрыто окно, просмотр лицензии

    return (
        <section className={css.main}>
            <h5>Образование</h5>

            <ul>
                <li><b>Общество с ограниченной ответственностью «Информационно-коммуникативные технологии плюс» реализует следующие уровни образования:</b></li>
                <ol>
                    <li>Дополнительное профессиональное образование.</li>
                </ol>
            </ul>

            <p>Обучение проводится по очной, очно-заочной, заочной формам.</p>
            <p>Обучение по всем образовательным программам ведётся на русском языке.</p>
            <p>При реализации образовательных программ используются электронное обучение и дистанционные образовательные технологии.</p>
            
            <p className={css.lic} onClick={() => setVisible(true)}>Лицензия на осуществление образовательной деятельности (с приложениями).</p>

            <div style={{ display: 'none' }}>
                <Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis) }}>
                    {!props.files.license ? 'УПС' : props.files.license.map((item: any) => {
                        return (
                            <Image key={item.file_id} src={item.file_path} />
                        )
                    })}
                </Image.PreviewGroup>
            </div>
        </section>
    )
}

export default Nav_04