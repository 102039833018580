import types from './lkDisciplinesTypes'
import typesLogin from '../auth/authTypes'

let initialState = {
    disciplinesList: [], // Список дисциплин
}

const disciplinesReducer = (state = initialState, action: any) => {
    switch (action.type) {

        // Выход из системы
        case typesLogin.AUTH_SET_LOGOUT: {
            return {
                ...state = initialState
            }
        }

        // Получение списка дисциплин
        case types.LKDISCIPLINES_SET_DISCIPLINE_LIST: {
            return {
                ...state,
                disciplinesList: action.items
            }
        }
        
        default: return state
    }
}

export default disciplinesReducer