import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { AppStateType } from '../../../bll/store'

import Footer from './footer'

const FooterContainer = (props: any) => {
    return (
        <Footer {...props} />
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
    }
}

export default compose(connect(mapState, {}))(FooterContainer)