import React from 'react'

import { Drawer } from 'rsuite'

import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined'
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';

import { BootstrapTooltip } from '../../../common/ui/tooltip'
import css from './addDeletePrograms.module.scss'

const AddDeletePrograms = (props: any) => {
    return (
        <Drawer backdrop='static' size='full' autoFocus={true} open={props.getOpenAddDeletePrograms} style={{ maxWidth: '1542px' }}>
            <div className={css.main}>
                <header className={css.header}>
                    <h4>Добавление / Удаление программ</h4>

                    <BootstrapTooltip title="Закрыть" placement="bottom" arrow>
                        <div className='myButtonIconPrimaryBorder' onClick={() => props.setOpenAddDeletePrograms(false)}>
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_239_32)">
                                    <path d="M20.5833 6.94417L19.0558 5.41667L13 11.4725L6.94413 5.41667L5.41663 6.94417L11.4725 13L5.41663 19.0558L6.94413 20.5833L13 14.5275L19.0558 20.5833L20.5833 19.0558L14.5275 13L20.5833 6.94417Z" fill="currentColor" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_239_32">
                                        <rect width="26" height="26" fill="currentColor" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </BootstrapTooltip>
                </header>

                <main className={css.content}>
                    <section className={css.itemsContainer}>
                        <header className={css.itemsContainer_header}>
                            <h6>Дисциплины ({props.programs.itemsAll?.length})</h6>
                        </header>

                        <main className={css.itemsContainer_list}>
                            {!props.programs.itemsAll?.length 
                                ? '' 
                                : props.programs.itemsAll.map((item: any) => {
                                    return (
                                        <div key={item.discipline_id} 
                                            className={`${css.itemRow} ${props.selectedAddID === item.discipline_id ? css.itemRow_selected : ''}`} 
                                            onClick={() => props.setSelectedAddID(item.discipline_id)}
                                        >{item.discipline_name}</div>
                                    )
                                })
                            }
                        </main>
                    </section>

                    <div className='dividerPrimary' style={{ height: '100%' }}></div>

                    <aside className={css.btnBar}>
                        <BootstrapTooltip title="Удалить" placement="top" arrow>
                            <div>
                                <button className='myButtonIconPrimaryBorder'
                                    disabled={!props.selectedDeleteID}
                                    onClick={() => props.eventDeleteInCourse()}
                                ><KeyboardDoubleArrowLeftOutlinedIcon /></button>
                            </div>
                        </BootstrapTooltip>

                        <BootstrapTooltip title="Добавить" placement="bottom" arrow>
                            <div>
                                <button className='myButtonIconPrimaryBorder' 
                                    disabled={!props.selectedAddID}
                                    onClick={() => props.eventAddToCourse()}
                                ><KeyboardDoubleArrowRightOutlinedIcon /></button>
                            </div>
                        </BootstrapTooltip>
                    </aside>
                    
                    <div className='dividerPrimary' style={{ height: '100%' }}></div>
                    
                    <section className={css.itemsContainer}>
                        <header className={css.itemsContainer_header}>
                            <h6>Добавленные дисциплины ({props.programs.itemsCourse?.length})</h6>
                        </header>

                        <main className={css.itemsContainer_list}>
                            {!props.programs.itemsCourse?.length 
                                ? '' 
                                : props.programs.itemsCourse.map((item: any) => {
                                    return (
                                        <div key={item.cpd_id} 
                                            className={`${css.itemRow} ${props.selectedDeleteID === item.cpd_id ? css.itemRow_selected : ''}`} 
                                            onClick={() => props.setSelectedDeleteID(item.cpd_id)}
                                        >{item.discipline_name}</div>
                                    )
                                })
                            }
                        </main>
                    </section>
                </main>
            </div>
        </Drawer>
    )
}

export default AddDeletePrograms