import React from 'react'
// import { Image } from 'antd'

import css from './nav-06.module.scss'

const Nav_06 = (props: any) => {
    return (
        <section className={css.main}>
            <h5>Руководство. Педагогический (научно-педагогический) состав</h5>

            <span>1. Кармазин Виталий Юрьевич - генеральный директор.</span>
            <span>2. Апситис Екатерина Викторовна - заместитель генерального директора, практикующий психолог.</span>
        </section>
    )
}

export default Nav_06