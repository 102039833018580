import api from './../../api/apiCommon'
import actions from './commonActions'

// Получение основных контактных данных о школе
export const getSettings = () => async (dispatch: any) => {
    const responce = await api.getSettings()

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.setSettings(
                    responce.data.LOGO,
                    responce.data.PHONE,
                    responce.data.EMAIL,
                    responce.data.VK,
                    responce.data.TELEGRAM,
                ))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Получение прочих файлов
export const getOtherFiles = () => async (dispatch: any) => {
    const responce = await api.getOtherFiles()

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.setOtherFiles(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Получение списка всех отзывов
export const getCommentsAll = () => async (dispatch: any) => {
    const responce = await api.getCommentsAll()

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.setCommentsAll(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}



// Получение данных по главной странице
export const getLandingData = () => async (dispatch: any) => {
    const responce = await api.getLandingData()

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.setLandingData(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Получение списка курсов (Страница - Лэндинг)
export const getCourses = () => async (dispatch: any) => {
    const responce = await api.getCourses()

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.setCourses(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Получение списка отзывов (Страница - Лэндинг)
export const getCommentsLanding = () => async (dispatch: any) => {
    const responce = await api.getCommentsLanding()

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.setCommentsLanding(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Создание вопроса клиента (Страница - Лэндинг)
export const createQuestionLanding = (clientName: string, clientPhone: string) => async (dispatch: any) => {
    const responce = await api.createQuestionLanding(clientName, clientPhone)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}



// Получение данных по курсу (Страница - Курса)
export const getCourseData = (courseID: string) => async (dispatch: any) => {
    const responce = await api.getCourseData(courseID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.setCourseData(
                    responce.data[0].course_id,
                    responce.data[0].head_text,
                    responce.data[0].head_translit,
                    responce.data[0].sum_1,
                    responce.data[0].sum_2,
                    responce.data[0].sum_3,
                    responce.data[0].discount,
                    responce.data[0].b01,
                    responce.data[0].b02,
                    responce.data[0].b03,
                    responce.data[0].b04,
                    responce.data[0].b05,
                    responce.data[0].b06,
                    responce.data[0].b07,
                    responce.data[0].b08,
                    responce.data[0].b09,
                    responce.data[0].b10,
                    responce.data[0].b11
                ))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Получение списка програм (Страница - Курса)
export const getPrograms = (courseID: string) => async (dispatch: any) => {
    const responce = await api.getPrograms(courseID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.setProgram(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Получение списка отзывов (Страница - Курса)
export const getCommentsCourse = (courseID: string) => async (dispatch: any) => {
    const responce = await api.getCommentsCourse(courseID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.setCommentsCourse(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Получение списка вопросов (Страница - Курса)
export const getQuestions = (courseID: string) => async (dispatch: any) => {
    const responce = await api.getQuestions(courseID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.setQuestions(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Создание вопроса клиентом (Страница - Курса)
export const createQuestionCourse = (courseID: string, clientName: string, clientPhone: string) => async (dispatch: any) => {
    const responce = await api.createQuestionCourse(courseID, clientName, clientPhone)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Создание заявки клиентом (Страница - Курса)
export const createApplicationCourse = (courseID: string, clientName: string, clientPhone: string, packageName: string) => async (dispatch: any) => {
    const responce = await api.createApplicationCourse(courseID, clientName, clientPhone, packageName)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}



// Инициализация страницы - Лэндинг
export const landingInitialized = () => (dispatch: any) => {
    let promGetSettings = dispatch(getSettings())
    let promGetOtherFiles = dispatch(getOtherFiles())

    let promGetLandingData = dispatch(getLandingData())
    let promGetCourses = dispatch(getCourses())
    let promGetCommentsLanding = dispatch(getCommentsLanding())

    Promise.all([
        promGetSettings, // Получение настроек школы
        promGetOtherFiles, // Получение прочих файлов

        promGetLandingData, // Загрузка данных по главной странице
        promGetCourses, // Загрузка курсов
        promGetCommentsLanding, // Загрузка отзывов
    ]).then((result) => {
        // let criticalErr: boolean = false

        console.log(result)

        // switch (result[0]?.code) {
        //     case "ERR_NETWORK":
        //     case "ERR_UNEXPECTED":
        //     case "ERR_SERVER_AVAILABLE":
        //         criticalErr = true
        //         openNotification("Сервер не доступен...", msg.err_0001, "error", "bottomRight")
        //         break

        //     case "ERR_NOT_AUTHORIZED":
        //         criticalErr = true
        //         openNotification("Вы не авторизованы...", msg.err_0002, "error", "bottomRight")
        //         break
        // }

        // if (!criticalErr) {
        //     if (result[0]?.code === "NULL") { openNotification("Сервер не ответил...", msg.err_code, "error", "bottomRight") } // Сервер не ответил
        //     if (result[0]?.code === "ERR_QUERY_STRING") { openNotification("Ошибка в запросе...", msg.err_0050, "error", "bottomRight") } // Ошибка в запросе

        //     dispatch(mainActions.setMainAppInitialized(true))
        // } else {
        //     navigate('/login')

        //     localStorage.removeItem('token')
        //     dispatch(loginActions.setLogout())
        // }
    })
}

// Инициализация страницы - Курса
export const courseInitialized = (courseID: string) => (dispatch: any) => {
    let promGetSettings = dispatch(getSettings())
    let promGetOtherFiles = dispatch(getOtherFiles())

    let promGetProgram = dispatch(getPrograms(courseID))
    let promGetCommentsCourse = dispatch(getCommentsCourse(courseID))
    let promGetQuestions = dispatch(getQuestions(courseID))
    let promGetCourseData = dispatch(getCourseData(courseID))

    Promise.all([
        promGetSettings, // Получение настроек школы
        promGetOtherFiles, // Получение прочих файлов
        
        promGetProgram, // Загрузка программ
        promGetCommentsCourse, // Загрузка отзывов
        promGetQuestions, // Загрузка вопросов
        promGetCourseData, // Загрузка данных по курсу
    ]).then((result) => {
        // let criticalErr: boolean = false

        console.log(result)

        // switch (result[0]?.code) {
        //     case "ERR_NETWORK":
        //     case "ERR_UNEXPECTED":
        //     case "ERR_SERVER_AVAILABLE":
        //         criticalErr = true
        //         openNotification("Сервер не доступен...", msg.err_0001, "error", "bottomRight")
        //         break

        //     case "ERR_NOT_AUTHORIZED":
        //         criticalErr = true
        //         openNotification("Вы не авторизованы...", msg.err_0002, "error", "bottomRight")
        //         break
        // }

        // if (!criticalErr) {
        //     if (result[0]?.code === "NULL") { openNotification("Сервер не ответил...", msg.err_code, "error", "bottomRight") } // Сервер не ответил
        //     if (result[0]?.code === "ERR_QUERY_STRING") { openNotification("Ошибка в запросе...", msg.err_0050, "error", "bottomRight") } // Ошибка в запросе

        //     dispatch(mainActions.setMainAppInitialized(true))
        // } else {
        //     navigate('/login')

        //     localStorage.removeItem('token')
        //     dispatch(loginActions.setLogout())
        // }
    })
}

// Инициализация страницы - Об организации
export const contactsInitialized = (courseID: string) => (dispatch: any) => {
    let promGetSettings = dispatch(getSettings())
    let promGetOtherFiles = dispatch(getOtherFiles())

    // let promGetProgram = dispatch(getPrograms(courseID))
    // let promGetCommentsCourse = dispatch(getCommentsCourse(courseID))
    // let promGetQuestions = dispatch(getQuestions(courseID))
    // let promGetCourseData = dispatch(getCourseData(courseID))

    Promise.all([
        promGetSettings, // Получение настроек школы
        promGetOtherFiles, // Получение прочих файлов
        
        // promGetProgram, // Загрузка программ
        // promGetCommentsCourse, // Загрузка отзывов
        // promGetQuestions, // Загрузка вопросов
        // promGetCourseData, // Загрузка данных по курсу
    ]).then((result) => {
        // let criticalErr: boolean = false

        console.log(result)

        // switch (result[0]?.code) {
        //     case "ERR_NETWORK":
        //     case "ERR_UNEXPECTED":
        //     case "ERR_SERVER_AVAILABLE":
        //         criticalErr = true
        //         openNotification("Сервер не доступен...", msg.err_0001, "error", "bottomRight")
        //         break

        //     case "ERR_NOT_AUTHORIZED":
        //         criticalErr = true
        //         openNotification("Вы не авторизованы...", msg.err_0002, "error", "bottomRight")
        //         break
        // }

        // if (!criticalErr) {
        //     if (result[0]?.code === "NULL") { openNotification("Сервер не ответил...", msg.err_code, "error", "bottomRight") } // Сервер не ответил
        //     if (result[0]?.code === "ERR_QUERY_STRING") { openNotification("Ошибка в запросе...", msg.err_0050, "error", "bottomRight") } // Ошибка в запросе

        //     dispatch(mainActions.setMainAppInitialized(true))
        // } else {
        //     navigate('/login')

        //     localStorage.removeItem('token')
        //     dispatch(loginActions.setLogout())
        // }
    })
}

// Инициализация страницы - Контакты
export const organizationInitialized = (courseID: string) => (dispatch: any) => {
    let promGetSettings = dispatch(getSettings())
    let promGetOtherFiles = dispatch(getOtherFiles())

    // let promGetProgram = dispatch(getPrograms(courseID))
    // let promGetCommentsCourse = dispatch(getCommentsCourse(courseID))
    // let promGetQuestions = dispatch(getQuestions(courseID))
    // let promGetCourseData = dispatch(getCourseData(courseID))

    Promise.all([
        promGetSettings, // Получение настроек школы
        promGetOtherFiles, // Получение прочих файлов
        
        // promGetProgram, // Загрузка программ
        // promGetCommentsCourse, // Загрузка отзывов
        // promGetQuestions, // Загрузка вопросов
        // promGetCourseData, // Загрузка данных по курсу
    ]).then((result) => {
        // let criticalErr: boolean = false

        console.log(result)

        // switch (result[0]?.code) {
        //     case "ERR_NETWORK":
        //     case "ERR_UNEXPECTED":
        //     case "ERR_SERVER_AVAILABLE":
        //         criticalErr = true
        //         openNotification("Сервер не доступен...", msg.err_0001, "error", "bottomRight")
        //         break

        //     case "ERR_NOT_AUTHORIZED":
        //         criticalErr = true
        //         openNotification("Вы не авторизованы...", msg.err_0002, "error", "bottomRight")
        //         break
        // }

        // if (!criticalErr) {
        //     if (result[0]?.code === "NULL") { openNotification("Сервер не ответил...", msg.err_code, "error", "bottomRight") } // Сервер не ответил
        //     if (result[0]?.code === "ERR_QUERY_STRING") { openNotification("Ошибка в запросе...", msg.err_0050, "error", "bottomRight") } // Ошибка в запросе

        //     dispatch(mainActions.setMainAppInitialized(true))
        // } else {
        //     navigate('/login')

        //     localStorage.removeItem('token')
        //     dispatch(loginActions.setLogout())
        // }
    })
}

// Инициализация страницы - Об образовании
export const educationInitialized = (courseID: string) => (dispatch: any) => {
    let promGetSettings = dispatch(getSettings())
    let promGetOtherFiles = dispatch(getOtherFiles())

    // let promGetProgram = dispatch(getPrograms(courseID))
    // let promGetCommentsCourse = dispatch(getCommentsCourse(courseID))
    // let promGetQuestions = dispatch(getQuestions(courseID))
    // let promGetCourseData = dispatch(getCourseData(courseID))

    Promise.all([
        promGetSettings, // Получение настроек школы
        promGetOtherFiles, // Получение прочих файлов
        
        // promGetProgram, // Загрузка программ
        // promGetCommentsCourse, // Загрузка отзывов
        // promGetQuestions, // Загрузка вопросов
        // promGetCourseData, // Загрузка данных по курсу
    ]).then((result) => {
        // let criticalErr: boolean = false

        console.log(result)

        // switch (result[0]?.code) {
        //     case "ERR_NETWORK":
        //     case "ERR_UNEXPECTED":
        //     case "ERR_SERVER_AVAILABLE":
        //         criticalErr = true
        //         openNotification("Сервер не доступен...", msg.err_0001, "error", "bottomRight")
        //         break

        //     case "ERR_NOT_AUTHORIZED":
        //         criticalErr = true
        //         openNotification("Вы не авторизованы...", msg.err_0002, "error", "bottomRight")
        //         break
        // }

        // if (!criticalErr) {
        //     if (result[0]?.code === "NULL") { openNotification("Сервер не ответил...", msg.err_code, "error", "bottomRight") } // Сервер не ответил
        //     if (result[0]?.code === "ERR_QUERY_STRING") { openNotification("Ошибка в запросе...", msg.err_0050, "error", "bottomRight") } // Ошибка в запросе

        //     dispatch(mainActions.setMainAppInitialized(true))
        // } else {
        //     navigate('/login')

        //     localStorage.removeItem('token')
        //     dispatch(loginActions.setLogout())
        // }
    })
}

// Инициализация страницы - Комментарии
export const commentsInitialized = (courseID: string) => (dispatch: any) => {
    let promGetSettings = dispatch(getSettings())
    let promGetOtherFiles = dispatch(getOtherFiles())

    let promGetCommentsAll = dispatch(getCommentsAll())
    // let promGetCommentsCourse = dispatch(getCommentsCourse(courseID))
    // let promGetQuestions = dispatch(getQuestions(courseID))
    // let promGetCourseData = dispatch(getCourseData(courseID))

    Promise.all([
        promGetSettings, // Получение настроек школы
        promGetOtherFiles, // Получение прочих файлов
        
        promGetCommentsAll, // Получение списка всех комментариев
        // promGetCommentsCourse, // Загрузка отзывов
        // promGetQuestions, // Загрузка вопросов
        // promGetCourseData, // Загрузка данных по курсу
    ]).then((result) => {
        // let criticalErr: boolean = false

        console.log(result)

        // switch (result[0]?.code) {
        //     case "ERR_NETWORK":
        //     case "ERR_UNEXPECTED":
        //     case "ERR_SERVER_AVAILABLE":
        //         criticalErr = true
        //         openNotification("Сервер не доступен...", msg.err_0001, "error", "bottomRight")
        //         break

        //     case "ERR_NOT_AUTHORIZED":
        //         criticalErr = true
        //         openNotification("Вы не авторизованы...", msg.err_0002, "error", "bottomRight")
        //         break
        // }

        // if (!criticalErr) {
        //     if (result[0]?.code === "NULL") { openNotification("Сервер не ответил...", msg.err_code, "error", "bottomRight") } // Сервер не ответил
        //     if (result[0]?.code === "ERR_QUERY_STRING") { openNotification("Ошибка в запросе...", msg.err_0050, "error", "bottomRight") } // Ошибка в запросе

        //     dispatch(mainActions.setMainAppInitialized(true))
        // } else {
        //     navigate('/login')

        //     localStorage.removeItem('token')
        //     dispatch(loginActions.setLogout())
        // }
    })
}

// Инициализация страницы - Статьи
export const articlesInitialized = (courseID: string) => (dispatch: any) => {
    let promGetSettings = dispatch(getSettings())
    let promGetOtherFiles = dispatch(getOtherFiles())

    // let promGetProgram = dispatch(getPrograms(courseID))
    // let promGetCommentsCourse = dispatch(getCommentsCourse(courseID))
    // let promGetQuestions = dispatch(getQuestions(courseID))
    // let promGetCourseData = dispatch(getCourseData(courseID))

    Promise.all([
        promGetSettings, // Получение настроек школы
        promGetOtherFiles, // Получение прочих файлов
        
        // promGetProgram, // Загрузка программ
        // promGetCommentsCourse, // Загрузка отзывов
        // promGetQuestions, // Загрузка вопросов
        // promGetCourseData, // Загрузка данных по курсу
    ]).then((result) => {
        // let criticalErr: boolean = false

        console.log(result)

        // switch (result[0]?.code) {
        //     case "ERR_NETWORK":
        //     case "ERR_UNEXPECTED":
        //     case "ERR_SERVER_AVAILABLE":
        //         criticalErr = true
        //         openNotification("Сервер не доступен...", msg.err_0001, "error", "bottomRight")
        //         break

        //     case "ERR_NOT_AUTHORIZED":
        //         criticalErr = true
        //         openNotification("Вы не авторизованы...", msg.err_0002, "error", "bottomRight")
        //         break
        // }

        // if (!criticalErr) {
        //     if (result[0]?.code === "NULL") { openNotification("Сервер не ответил...", msg.err_code, "error", "bottomRight") } // Сервер не ответил
        //     if (result[0]?.code === "ERR_QUERY_STRING") { openNotification("Ошибка в запросе...", msg.err_0050, "error", "bottomRight") } // Ошибка в запросе

        //     dispatch(mainActions.setMainAppInitialized(true))
        // } else {
        //     navigate('/login')

        //     localStorage.removeItem('token')
        //     dispatch(loginActions.setLogout())
        // }
    })
}