import types from './lkQuestionsTypes'
import typesLogin from '../auth/authTypes'

let initialState = {
    questionsList: [], // Список вопросов
}

const questionsReducer = (state = initialState, action: any) => {
    switch (action.type) {

        // Выход из системы
        case typesLogin.AUTH_SET_LOGOUT: {
            return {
                ...state = initialState
            }
        }

        // Получение списка вопросов
        case types.LKQUESTIONS_SET_QUESTIONS_LIST: {
            return {
                ...state,
                questionsList: action.items
            }
        }
        
        default: return state
    }
}

export default questionsReducer