import React from 'react'
import parse from 'html-react-parser'

import WallpaperOutlinedIcon from '@mui/icons-material/WallpaperOutlined'
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined'

import empty from './../../../../css/img/empty.png'
import { BootstrapTooltip } from '../../../common/ui/tooltip'

const Section07 = (props: any) => {
    return (
        <section>
            <div className='container CB07' style={{ background: `${props.page.b07.bg_color} url(${props.page.b07.bg_img_path}) no-repeat center/100% 100%` }}>
                <article className='content'>
                    <BootstrapTooltip title="Редактировать" placement="top" arrow>
                        <h2 className='itemHover' onClick={() => props.openEditTextSecondary(true, '07', 'text_1', 'text', props.page.b07.text_1)}>
                            {parse(props.page.b07.text_1 || 'Редактировать')}
                        </h2>
                    </BootstrapTooltip>

                    <div className='items'>
                        <div className='item'>
                            <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                                <img className='itemHover' src={props.page.b07.img_1_path || empty} alt={props.page.b07.text_2?.replace(/<[^>]+>/g, '')} 
                                    onClick={() => props.openEditImage(true, '07', 'img_1_path', 'image', props.page.b07.img_1_path, props.page.b07.img_1_name)}
                                />
                            </BootstrapTooltip>

                            <article>
                                <BootstrapTooltip title="Редактировать" placement="top" arrow>
                                    <h6 className='itemHover' onClick={() => props.openEditTextPrimary(true, '07', 'text_2', 'text', props.page.b07.text_2 || '')}>
                                        {parse(props.page.b07.text_2 || 'Редактировать')}
                                    </h6>
                                </BootstrapTooltip>

                                <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                                    <div className='itemHover' onClick={() => props.openEditTextSecondary(true, '07', 'text_3', 'text', props.page.b07.text_3)}>
                                        {parse(props.page.b07.text_3 || 'Редактировать')}
                                    </div>
                                </BootstrapTooltip>
                            </article>
                        </div>

                        <div className='item'>
                            <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                                <img className='itemHover' src={props.page.b07.img_2_path || empty} alt={props.page.b07.text_4?.replace(/<[^>]+>/g, '')} 
                                    onClick={() => props.openEditImage(true, '07', 'img_2_path', 'image', props.page.b07.img_2_path, props.page.b07.img_2_name)}
                                />
                            </BootstrapTooltip>

                            <article>
                                <BootstrapTooltip title="Редактировать" placement="top" arrow>
                                    <h6 className='itemHover' onClick={() => props.openEditTextPrimary(true, '07', 'text_4', 'text', props.page.b07.text_4 || '')}>
                                        {parse(props.page.b07.text_4 || 'Редактировать')}
                                    </h6>
                                </BootstrapTooltip>
                                
                                <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                                    <div className='itemHover' onClick={() => props.openEditTextSecondary(true, '07', 'text_5', 'text', props.page.b07.text_5)}>
                                        {parse(props.page.b07.text_5 || 'Редактировать')}
                                    </div>
                                </BootstrapTooltip>
                            </article>
                        </div>

                        <div className='item'>
                            <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                                <img className='itemHover' src={props.page.b07.img_3_path || empty} alt={props.page.b07.text_6?.replace(/<[^>]+>/g, '')} 
                                    onClick={() => props.openEditImage(true, '07', 'img_3_path', 'image', props.page.b07.img_3_path, props.page.b07.img_3_name)}
                                />
                            </BootstrapTooltip>

                            <article>
                                <BootstrapTooltip title="Редактировать" placement="top" arrow>
                                    <h6 className='itemHover' onClick={() => props.openEditTextPrimary(true, '07', 'text_6', 'text', props.page.b07.text_6 || '')}>
                                        {parse(props.page.b07.text_6 || 'Редактировать')}
                                    </h6>
                                </BootstrapTooltip>

                                <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                                    <div className='itemHover' onClick={() => props.openEditTextSecondary(true, '07', 'text_7', 'text', props.page.b07.text_7)}>
                                        {parse(props.page.b07.text_7 || 'Редактировать')}
                                    </div>
                                </BootstrapTooltip>
                            </article>
                        </div>

                        <div className='item'>
                            <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                                <img className='itemHover' src={props.page.b07.img_4_path || empty} alt={props.page.b07.text_8?.replace(/<[^>]+>/g, '')} 
                                    onClick={() => props.openEditImage(true, '07', 'img_4_path', 'image', props.page.b07.img_4_path, props.page.b07.img_4_name)}
                                />
                            </BootstrapTooltip>

                            <article>
                                <BootstrapTooltip title="Редактировать" placement="top" arrow>
                                    <h6 className='itemHover' onClick={() => props.openEditTextPrimary(true, '07', 'text_8', 'text', props.page.b07.text_8 || '')}>
                                        {parse(props.page.b07.text_8 || 'Редактировать')}
                                    </h6>
                                </BootstrapTooltip>

                                <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                                    <div className='itemHover' onClick={() => props.openEditTextSecondary(true, '07', 'text_9', 'text', props.page.b07.text_9)}>
                                        {parse(props.page.b07.text_9 || 'Редактировать')}
                                    </div>
                                </BootstrapTooltip>
                            </article>
                        </div>

                        <div className='item'>
                            <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                                <img className='itemHover' src={props.page.b07.img_5_path || empty} alt={props.page.b07.text_10?.replace(/<[^>]+>/g, '')} 
                                    onClick={() => props.openEditImage(true, '07', 'img_5_path', 'image', props.page.b07.img_5_path, props.page.b07.img_5_name)}
                                />
                            </BootstrapTooltip>

                            <article>
                                <BootstrapTooltip title="Редактировать" placement="top" arrow>
                                    <h6 className='itemHover' onClick={() => props.openEditTextPrimary(true, '07', 'text_10', 'text', props.page.b07.text_10 || '')}>
                                        {parse(props.page.b07.text_10 || 'Редактировать')}
                                    </h6>
                                </BootstrapTooltip>

                                <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                                    <div className='itemHover' onClick={() => props.openEditTextSecondary(true, '07', 'text_11', 'text', props.page.b07.text_11)}>
                                        {parse(props.page.b07.text_11 || 'Редактировать')}
                                    </div>
                                </BootstrapTooltip>
                            </article>
                        </div>

                        <div className='item'>
                            <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                                <img className='itemHover' src={props.page.b07.img_6_path || empty} alt={props.page.b07.text_12?.replace(/<[^>]+>/g, '')} 
                                    onClick={() => props.openEditImage(true, '07', 'img_6_path', 'image', props.page.b07.img_6_path, props.page.b07.img_6_name)}
                                />
                            </BootstrapTooltip>

                            <article>
                                <BootstrapTooltip title="Редактировать" placement="top" arrow>
                                    <h6 className='itemHover' onClick={() => props.openEditTextPrimary(true, '07', 'text_12', 'text', props.page.b07.text_12 || '')}>
                                        {parse(props.page.b07.text_12 || 'Редактировать')}
                                    </h6>
                                </BootstrapTooltip>

                                <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                                    <div className='itemHover' onClick={() => props.openEditTextSecondary(true, '07', 'text_13', 'text', props.page.b07.text_13)}>
                                        {parse(props.page.b07.text_13 || 'Редактировать')}
                                    </div>
                                </BootstrapTooltip>
                            </article>
                        </div>
                    </div>
                </article>

                <section className='myPopoverVertical'>
                    <h5>Блок № 7</h5>
                    
                    <div className='dividerPrimary' style={{ height: '40px' }}></div>

                    <BootstrapTooltip title="Видимость блока" placement="bottom" arrow>
                        <input type="checkbox"
                            checked={props.page.b07.is_active === 1 ? true : false}
                            onChange={(e) => props.onChangeBlockActive('07', 'is_active', 'isActive', e.target.checked)}
                        />
                    </BootstrapTooltip>

                    {/* <BootstrapTooltip title="Редактировать высоту изображение заднего фона" placement="left" arrow>
                        <div className='myButtonIconSecondaryBorder' onClick={() => props.openEditTextNumber(true, '01', 'b1_bg_img_height', 'bg_img_size', props.b1.bg?.img_height)}>
                            <HeightOutlinedIcon />
                        </div>
                    </BootstrapTooltip> */}

                    {/* <BootstrapTooltip title="Редактировать ширину изображение заднего фона" placement="left" arrow>
                        <div className='myButtonIconSecondaryBorder' onClick={() => props.openEditTextNumber(true, '01', 'b1_bg_img_width', 'bg_img_size', props.b1.bg?.img_width)}>
                            <MultipleStopOutlinedIcon />
                        </div>
                    </BootstrapTooltip> */}

                    {/* <BootstrapTooltip title="Редактировать позицию изображение заднего фона" placement="left" arrow>
                        <div className='myButtonIconSecondaryBorder' onClick={() => props.openEditPosition(true, '01', 'b1_bg_img_position', 'position', props.b1.bg?.img_position)}>
                            <PictureInPictureAltOutlinedIcon />
                        </div>
                    </BootstrapTooltip> */}

                    <div className='dividerPrimary' style={{ height: '40px' }}></div>

                    <BootstrapTooltip title="Редактировать изображение заднего фона" placement="bottom" arrow>
                        <div className="myButtonIconSecondaryBorder" onClick={() => props.openEditImage(true, '07', 'bg_img_path', 'image', props.page.b07.bg_img_path, props.page.b07.bg_img_name)}>
                            <WallpaperOutlinedIcon />
                        </div>
                    </BootstrapTooltip>

                    <BootstrapTooltip title="Редактировать задний фон блока" placement="bottom" arrow>
                        <div className="myButtonIconSecondaryBorder" onClick={() => props.openEditColor(true, '07', 'bg_color', 'color_background', props.page.b07.bg_color)}>
                            <PaletteOutlinedIcon />
                        </div>
                    </BootstrapTooltip>
                </section>
            </div>
        </section>
    )
}

export default Section07