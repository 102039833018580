import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { AppStateType } from '../../../bll/store'
import Questions from './questions'
import errorHandler from '../../../bll/errorHandler'
import openNotification from '../../common/ui/notification'
import { clearQuestions, createQuestions, editQuestions, getQuestions } from '../../../bll/lkQuestions/lkQuestionsOperations'

const QuestionsContainer = (props: any) => {
    const [getIsVisibleAddEdit, setIsVisibleAddEdit] = React.useState(false) // Видимость окна создания (редактирования)
    const [getAction, setAction] = React.useState('') // Действие (создание или редактирование)

    const [getValueID, setValueID] = React.useState('') // Идентификатор вопроса
    const [getValueName, setValueName] = React.useState('') // Заголовок вопроса в окне создания (редактирования)
    const [getValueDescription, setValueDescription] = React.useState('') // Описание вопроса в окне создания (редактирования)

    const [getFilter, setFilter] = React.useState('') // Фильтр

    React.useEffect(() => {
        props.getQuestions().then((result: any) => {
            switch (result?.code) {
                case 'OK': break
                case "ERR_QUERY_STRING": { return openNotification("Список вопросов...", props.msg.err_0075, "error", "bottomRight") }
                default: errorHandler(result?.code, props.msg); break
            }
        })

        return () => {
            props.clearQuestions()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    // Открытие / Закрытие окна создания (редактирования вопроса)
    const openAddEdit = (isOpen: boolean, action: string = '', id: string = '', name: string = '', description: string = '') => {
        setValueID(id)
        setValueName(name)
        setValueDescription(description)
        
        setAction(action)
        setIsVisibleAddEdit(isOpen)
    }

    // Кнопка применить в окне создания (редактирования)
    const applyButton = () => {
        if (getAction === 'add') { // Создание
            props.createQuestions(getValueName, getValueDescription).then((result: any) => {
                switch (result?.code) {
                    case "OK": 
                        openAddEdit(false)
                    break

                    case "ERR_QUERY_STRING": { return openNotification("Создание вопроса...", props.msg.err_0076, "error", "bottomRight") }
                    default: errorHandler(result?.code, props.msg); break
                }
            })
        }

        if (getAction === 'edit') { // Редактирование
            props.editQuestions(getValueID, getValueName, getValueDescription).then((result: any) => {
                switch (result?.code) {
                    case 'OK': 
                        openAddEdit(false)
                    break

                    case "ERR_QUERY_STRING": { return openNotification("Редактирование вопроса...", props.msg.err_0077, "error", "bottomRight") }
                    default: errorHandler(result?.code, props.msg); break
                }
            })
        }
    }


    // Свойства передаваемые в компоненту
    const propsToComponents = {
        getIsVisibleAddEdit, // Видимость окна создания (редактирования)
        getAction, // Действие (создание или редактирование)

        openAddEdit, // Открытие / Закрытие окна создания (редактирования дисциплины)
        applyButton, // Кнопка применить в окне создания (редактирования) дисциплины

        getValueName, setValueName, // Наименование дисциплины в окне создания (редактирования)
        getValueDescription, setValueDescription, // Описание дисциплины в окне создания (редактирования)

        getFilter, setFilter, // Фильтр
    }

    return (
        <Questions {...props} {...propsToComponents} />
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        questionsList: state.questions.questionsList
    }
}

export default compose(connect(mapState, { 
    getQuestions, clearQuestions,
    createQuestions, editQuestions
}))(QuestionsContainer)