import types from './lkFilesTypes'
import typesLogin from '../auth/authTypes'

let initialState = {
    fileTypesList: [], // Список справочника - тип раздела файлов
    filesList: [], // Список файлов
}

const filesReducer = (state = initialState, action: any) => {
    switch (action.type) {

        // Выход из системы
        case typesLogin.AUTH_SET_LOGOUT: {
            return {
                ...state = initialState
            }
        }

        // Получение справочника - тип раздела файлов
        case types.LKFILES_SET_FILE_TYPES: {
            return {
                ...state,
                fileTypesList: action.items
            }
        }
        
        // Получение списка файлов
        case types.LKFILES_SET_FILES_LIST: {
            return {
                ...state,
                filesList: action.items
            }
        }

        default: return state
    }
}

export default filesReducer