import React from 'react'
import parse from 'html-react-parser'

const Section05 = (props: any) => {
    return (
        <section id='license'>
            <article className='container LB05'>
                <h2>{parse(props.page.b05_text_1?.value || '')}</h2>
                <h4>{parse(props.page.b05_text_2?.value || '')}</h4>
                <div>{parse(props.page.b05_text_3?.value || '')}</div>

                <button className='buttonSecondary_2' onClick={() => props.licVisible(true)}>Просмотр лицензии</button>
            </article>
        </section>
    )
}

export default Section05