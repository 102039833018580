import { Markup } from 'interweave'
import { notification } from 'antd'

import Icon from '@mdi/react'
import { mdiWindowClose } from '@mdi/js'

const openNotification = (
    title: string, message: string,
    type: 'info' | 'success' | 'warning' | 'error', 
    placement: 'topLeft' | 'top' | 'topRight' | 'bottomLeft' | 'bottom' | 'bottomRight') => {
    
    notification[type]({
        message: title,
        description: <Markup content={message} />,
        placement: placement,
        duration: 15,
        closeIcon: <Icon path={mdiWindowClose} size={0.8} />,
    })
}

export default openNotification