import React from 'react'
import { NavLink } from 'react-router-dom'

import css from './nav-02.module.scss'

const Nav_02 = (props: any) => {
    return (
        <section className={css.main}>
            <h5>Разнообразие психологии</h5>

            <p>
                На сегодняшний день психология играет не малую роль в нашей жизни. Сегодня появилось много направлений в области психологии, одни из самых популярных такие как: гештальт-терапия, арт-терапия, телесно-ориентированная психотерапия. Давайте разберём каждое из направлений, когда появились данные «терапии».
            </p>

            <p>
                Гештальт-терапия была разработана в 1940-50-х годах Фредериком (Фрицем) Перлзом и его женой Лаурой Перлз и Полом Гудменом, большую популярность получила в 1960- годах. Считается, что сама терапия основана на экспериментально-феноменологических экзистенциальных подходах. Гештальт – это гуманистическая терапия. Очень часто люди слышат «закрыть гештальт». Это обозначает, что человек прорабатывает прошлое и перенастраивает его в своем сознание и после начинает работает над самим собой. Человек, вспоминая свое детство, застревает в каких-либо ситуациях, которые его тормозят в будущем. Работа гештальта связана с воспоминанием человека, через его сны, телесными проявлениями, чувствами.
            </p>

            <p>
                Арт-терапия основана на применение творчества и искусства. Данное понятие впервые ввел художник Адриан Хилл в 1938 году. Рисунки с давним временем имели и имеют воздействие на психоэмоциональное состояние людей. Если мы возьмем пример детских рисунков, то мы сможем увидеть проблемы у ребенка, которые могут происходить в семье. Благодаря рисункам специалист, арт-терапевт, узнает о нынешнем состоянии человека.
            </p>

            <p>
                Телесно - ориентированная психотерапия была разработана в 1940-х Вильгельмом Райхом, австрийским и американским психологом, неофрейдистом. Тело человека может «рассказать» о характере и проблеме быстрее, чем это сделает сам человек. Данная терапия, направленная на проблемы невроза, через процедуру контакта человеческого тела. И только специалист может понять откуда пошли эти зажатия тела.
            </p>

            <p>
                Если Вас заинтересовали эти направления - можете пройти обучение по программе <NavLink to='/course/psikhologiya-dlya-nachinayushchikh' target='_blank'>«Психология для начинающих»</NavLink>
            </p>
        </section>
    )
}

export default Nav_02