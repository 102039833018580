import React from 'react'
import parse from 'html-react-parser'

const Section06 = (props: any) => {
    function priceWithSpaces(x: number) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    return (
        <section id='formats'>
            <article className='container CB06'>
                <h2>{parse(props.page.b06.text_1 || '')}</h2>

                <div className='items'>
                    <article className='item itemBorderColor_1'>
                        <h4>{parse(props.page.b06.text_2 || '')}</h4>

                        <div className='description'>
                            {parse(props.page.b06.text_3 || '')}
                        </div>

                        <div className='price'>
                            <span className='header'>{parse(props.page.b06.text_4 || '')}</span>
                            <span className='sumMain'>{priceWithSpaces(props.page.sum_1)} ₽</span>
                            {props.page.discount > 0 && <span className='withoutDisctount'>{priceWithSpaces(Math.round(props.page.sum_1 / (100 - props.page.discount)) * 100)} ₽ без скидки</span>}
                            {props.page.discount > 0 && <span className='discount'>-{priceWithSpaces((Math.round(props.page.sum_1 / (100 - props.page.discount)) * 100) - props.page.sum_1)} ₽ скидка</span>}
                        </div>

                        <button className='buttonCourse' onClick={() => props.openApplication(true, props.page.b06.text_2?.replace(/<[^>]+>/g, ''))}>Принять участие</button>
                    </article>
                    
                    <article className='item itemBorderColor_2'>
                        <h4>{parse(props.page.b06.text_5 || '')}</h4>

                        <div className='description'>
                            {parse(props.page.b06.text_6 || '')}
                        </div>

                        <div className='price'>
                            <span className='header'>{parse(props.page.b06.text_7 || '')}</span>
                            <span className='sumMain'>{priceWithSpaces(props.page.sum_2)} ₽</span>
                            {props.page.discount > 0 && <span className='withoutDisctount'>{priceWithSpaces(Math.round(props.page.sum_2 / (100 - props.page.discount)) * 100)} ₽ без скидки</span>}
                            {props.page.discount > 0 && <span className='discount'>-{priceWithSpaces((Math.round(props.page.sum_2 / (100 - props.page.discount)) * 100) - props.page.sum_2)} ₽ скидка</span>}
                        </div>

                        <button className='buttonCourse' onClick={() => props.openApplication(true, props.page.b06.text_5?.replace(/<[^>]+>/g, ''))}>Принять участие</button>
                    </article>

                    <article className='item itemBorderColor_3'>
                        <h4>{parse(props.page.b06.text_8 || '')}</h4>

                        <div className='description'>
                            {parse(props.page.b06.text_9 || '')}
                        </div>

                        <div className='price'>
                            <span className='header'>{parse(props.page.b06.text_10 || '')}</span>
                            <span className='sumMain'>{priceWithSpaces(props.page.sum_3)} ₽</span>
                            {props.page.discount > 0 && <span className='withoutDisctount'>{priceWithSpaces(Math.round(props.page.sum_3 / (100 - props.page.discount)) * 100)} ₽ без скидки</span>}
                            {props.page.discount > 0 && <span className='discount'>-{priceWithSpaces((Math.round(props.page.sum_3 / (100 - props.page.discount)) * 100) - props.page.sum_3)} ₽ скидка</span>}
                        </div>

                        <button className='buttonCourse' onClick={() => props.openApplication(true, props.page.b06.text_8?.replace(/<[^>]+>/g, ''))}>Принять участие</button>
                    </article>
                </div>
            </article>
        </section>
    )
}

export default Section06