import types from './commonTypes'

const setSettings = (
    logo: string, phone: string, email: string, vk: string, telegram: string
) => ({ type: types.COMMON_SET_SETTINGS, 
    logo, phone, email, vk, telegram
})

const setOtherFiles = (items: []) => ({ type: types.COMMON_SET_OTHER_FILES, items })
const setCommentsAll = (items: []) => ({ type: types.COMMON_SET_COMMENTS_ALL, items })

const setLandingData = (items: []) => ({ type: types.COMMON_SET_LANDING_DATA, items })
const setCourses = (items: []) => ({ type: types.COMMON_SET_COURSES, items })
const setCommentsLanding = (items: []) => ({ type: types.COMMON_SET_COMMENTS_LANDING, items })

const setProgram = (items: []) => ({ type: types.COMMON_SET_PROGRAM, items })
const setCommentsCourse = (items: []) => ({ type: types.COMMON_SET_COMMENTS_COURSE, items })
const setQuestions = (items: []) => ({ type: types.COMMON_SET_QUESTIONS, items })

const setCourseData = (
    course_id: string, head_text: string, head_translit: string, sum_1: number, sum_2: number, sum_3: number, discount: number,
    b01: {}, b02: {}, b03: {}, b04: {}, b05: {}, b06: {}, b07: {}, b08: {}, b09: {}, b10: {}, b11: {}
) => ({ type: types.COMMON_SET_COURSE_DATA, 
    course_id, head_text, head_translit, sum_1, sum_2, sum_3, discount,
    b01, b02, b03, b04, b05, b06, b07, b08, b09, b10, b11
})

const commonActions = {
    setSettings,
    setOtherFiles,
    setCommentsAll,

    setLandingData,
    setCourses,
    setCommentsLanding,
    
    setProgram,
    setCommentsCourse,
    setQuestions,
    setCourseData
}

export default commonActions