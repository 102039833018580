import types from './lkCommentsTypes'
import typesLogin from '../auth/authTypes'

let initialState = {
    commentsList: [], // Список комментариев
}

const commentsReducer = (state = initialState, action: any) => {
    switch (action.type) {

        // Выход из системы
        case typesLogin.AUTH_SET_LOGOUT: {
            return {
                ...state = initialState
            }
        }

        // Получение списка комментариев
        case types.LKCOMMENTS_SET_COMMENTS_LIST: {
            return {
                ...state,
                commentsList: action.items
            }
        }

        default: return state
    }
}

export default commentsReducer