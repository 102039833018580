import React from 'react'
import parse from 'html-react-parser'

import img01 from './../../../../css/img/courseB04_img01.png'
import img02 from './../../../../css/img/courseB04_img02.png'
import img03 from './../../../../css/img/courseB04_img03.png'
import img04 from './../../../../css/img/courseB04_img04.png'
import img05 from './../../../../css/img/courseB04_img05.png'
import img06 from './../../../../css/img/courseB04_img06.png'
import { BootstrapTooltip } from '../../../common/ui/tooltip'

const Section04 = (props: any) => {
    return (
        <section>
            <article className='container CB04'>
                <BootstrapTooltip title="Редактировать" placement="top" arrow>
                    <h2 className='itemHover' onClick={() => props.openEditTextSecondary(true, '04', 'text_1', 'text', props.page.b04.text_1)}>
                        {parse(props.page.b04.text_1 || 'Редактировать')}
                    </h2>
                </BootstrapTooltip>

                <div className='items'>
                    <div className='item'>
                        <img src={img01} alt={props.page.b04.text_2?.replace(/<[^>]+>/g, '')} />
                        
                        <BootstrapTooltip title="Редактировать" placement="top" arrow>
                            <h6 className='itemHover' onClick={() => props.openEditTextSecondary(true, '04', 'text_2', 'text', props.page.b04.text_2)}>
                                {parse(props.page.b04.text_2 || 'Редактировать')}
                            </h6>
                        </BootstrapTooltip>
                        
                        <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                            <div className='itemHover' onClick={() => props.openEditTextSecondary(true, '04', 'text_3', 'text', props.page.b04.text_3)}>
                                {parse(props.page.b04.text_3 || 'Редактировать')}
                            </div>
                        </BootstrapTooltip>
                    </div>

                    <div className='item'>
                        <img src={img02} alt={props.page.b04.text_4?.replace(/<[^>]+>/g, '')} />
                        
                        <BootstrapTooltip title="Редактировать" placement="top" arrow>
                            <h6 className='itemHover' onClick={() => props.openEditTextSecondary(true, '04', 'text_4', 'text', props.page.b04.text_4)}>
                                {parse(props.page.b04.text_4 || 'Редактировать')}
                            </h6>
                        </BootstrapTooltip>
                        
                        <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                            <div className='itemHover' onClick={() => props.openEditTextSecondary(true, '04', 'text_5', 'text', props.page.b04.text_5)}>
                                {parse(props.page.b04.text_5 || 'Редактировать')}
                            </div>
                        </BootstrapTooltip>
                    </div>

                    <div className='item'>
                        <img src={img03} alt={props.page.b04.text_6?.replace(/<[^>]+>/g, '')} />

                        <BootstrapTooltip title="Редактировать" placement="top" arrow>                        
                            <h6 className='itemHover' onClick={() => props.openEditTextSecondary(true, '04', 'text_6', 'text', props.page.b04.text_6)}>
                                {parse(props.page.b04.text_6 || 'Редактировать')}
                            </h6>
                        </BootstrapTooltip>
                        
                        <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                            <div className='itemHover' onClick={() => props.openEditTextSecondary(true, '04', 'text_7', 'text', props.page.b04.text_7)}>
                                {parse(props.page.b04.text_7 || 'Редактировать')}
                            </div>
                        </BootstrapTooltip>
                    </div>

                    <div className='item'>
                        <img src={img04} alt={props.page.b04.text_8?.replace(/<[^>]+>/g, '')} />
                        
                        <BootstrapTooltip title="Редактировать" placement="top" arrow>                        
                            <h6 className='itemHover' onClick={() => props.openEditTextSecondary(true, '04', 'text_8', 'text', props.page.b04.text_8)}>
                                {parse(props.page.b04.text_8 || 'Редактировать')}
                            </h6>
                        </BootstrapTooltip>
                        
                        <BootstrapTooltip title="Редактировать" placement="bottom" arrow>                        
                            <div className='itemHover' onClick={() => props.openEditTextSecondary(true, '04', 'text_9', 'text', props.page.b04.text_9)}>
                                {parse(props.page.b04.text_9 || 'Редактировать')}
                            </div>
                        </BootstrapTooltip>
                    </div>

                    <div className='item'>
                        <img src={img05} alt={props.page.b04.text_10?.replace(/<[^>]+>/g, '')} />
                        
                        <BootstrapTooltip title="Редактировать" placement="top" arrow>
                            <h6 className='itemHover' onClick={() => props.openEditTextSecondary(true, '04', 'text_10', 'text', props.page.b04.text_10)}>
                                {parse(props.page.b04.text_10 || 'Редактировать')}
                            </h6>
                        </BootstrapTooltip>
                        
                        <BootstrapTooltip title="Редактировать" placement="bottom" arrow>                        
                            <div className='itemHover' onClick={() => props.openEditTextSecondary(true, '04', 'text_11', 'text', props.page.b04.text_11)}>
                                {parse(props.page.b04.text_11 || 'Редактировать')}
                            </div>
                        </BootstrapTooltip>
                    </div>

                    <div className='item'>
                        <img src={img06} alt={props.page.b04.text_12?.replace(/<[^>]+>/g, '')} />
                        
                        <BootstrapTooltip title="Редактировать" placement="top" arrow>
                            <h6 className='itemHover' onClick={() => props.openEditTextSecondary(true, '04', 'text_12', 'text', props.page.b04.text_12)}>
                                {parse(props.page.b04.text_12 || 'Редактировать')}
                            </h6>
                        </BootstrapTooltip>
                        
                        <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                            <div className='itemHover' onClick={() => props.openEditTextSecondary(true, '04', 'text_13', 'text', props.page.b04.text_13)}>
                                {parse(props.page.b04.text_13 || 'Редактировать')}
                            </div>
                        </BootstrapTooltip>
                    </div>
                </div>

                <section className='myPopoverVertical'>
                    <h5>Блок № 4</h5>
                    
                    <div className='dividerPrimary' style={{ height: '40px' }}></div>
    
                    <BootstrapTooltip title="Видимость блока" placement="bottom" arrow>
                        <input type="checkbox"
                            checked={props.page.b04.is_active === 1 ? true : false}
                            onChange={(e) => props.onChangeBlockActive('04', 'is_active', 'isActive', e.target.checked)}
                        />
                    </BootstrapTooltip>
                </section>
            </article>
        </section>
    )
}

export default Section04