import React from 'react'
import moment from 'moment'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { AppStateType } from '../../../../bll/store'
import { clearCourseInfo, editCourseInfo, getCourseInfo, uploadFileCourseInfo } from '../../../../bll/lkCourses/lkCoursesOperations'
import openNotification from '../../../common/ui/notification'
import errorHandler from '../../../../bll/errorHandler'
import CourseInfo from './courseInfo'

const CourseInfoContainer = (props: any) => {
    const [getIsEditableCourseAuthor, setIsEditableCourseAuthor] = React.useState(false) // Разрешение на редактирование автора курса
    const [getIsEditableCourseName, setIsEditableCourseName] = React.useState(false) // Разрешение на редактирование названия курса
    const [getIsEditableCourseDuration, setIsEditableCourseDuration] = React.useState(false) // Разрешение на редактирование от скольки дней
    const [getIsEditableCourseValueHours, setIsEditableCourseValueHours] = React.useState(false) // Разрешение на редактирование от скольки часов
    const [getIsEditableCourseSum1, setIsEditableCourseSum1] = React.useState(false) // Разрешение на редактирование сумма пакет 1
    const [getIsEditableCourseSum2, setIsEditableCourseSum2] = React.useState(false) // Разрешение на редактирование сумма пакет 2
    const [getIsEditableCourseSum3, setIsEditableCourseSum3] = React.useState(false) // Разрешение на редактирование сумма пакет 3
    const [getIsEditableCourseDiscount, setIsEditableCourseDiscount] = React.useState(false) // Разрешение на редактирование скидка


    const [getValueCourseAuthor, setValueCourseAuthor] = React.useState('') // Данные поля, автор курса
    const [getValueCourseName, setValueCourseName] = React.useState('') // Данные поля, название курса
    const [getValueDuration, setValueDuration] = React.useState('') // Данные поля, от скольки дней
    const [getValueHours, setValueHours] = React.useState('') // Данные поля, от скольки часов
    const [getValueSum1, setValueSum1] = React.useState('') // Данные поля, сумма 1
    const [getValueSum2, setValueSum2] = React.useState('') // Данные поля, сумма 2
    const [getValueSum3, setValueSum3] = React.useState('') // Данные поля, сумма 3
    const [getValueDiscount, setValueDiscount] = React.useState('') // Данные поля, скидка

    const [getValueDateStart, setValueDateStart] = React.useState('') // Данные поля, старт курса
    const [getDateState, setDateState] = React.useState() // Выбранная дата в календаре
    const [getIsVisibleSelectDate, setIsVisibleSelectDate] = React.useState(false) // Видимость окна выбора даты

    const [editorState, setEditorState] = React.useState('') // Данные поля описание курса


    React.useEffect(() => {
        props.getIsOpenCourseInfo && props.getCourseInfo(props.getEditableCourseID).then((result: any) => {
            switch (result?.code) {
                case 'OK': break

                case "ERR_QUERY_STRING": { return openNotification("Информация о курсе...", props.msg.err_0052, "error", "bottomRight") }
                default: errorHandler(result?.code, props.msg); break
            }
        })

        return () => {
            props.clearCourseInfo()
            setEditorState('')
        }
    }, [props.getIsOpenCourseInfo]) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (props.getIsOpenCourseInfo) {
            setValueCourseAuthor(props.courseInfo.author)
            setValueCourseName(props.courseInfo.head_text)
            setValueDuration(props.courseInfo.duration)
            setValueHours(props.courseInfo.value_hours)
            setValueDateStart(new Date(props.courseInfo.date_start).toLocaleString('default', {day: 'numeric', month: 'long', year: 'numeric'}))
            setEditorState(props.courseInfo.title_text || '')
            
            setValueSum1(props.courseInfo.sum_1)
            setValueSum2(props.courseInfo.sum_2)
            setValueSum3(props.courseInfo.sum_3)
            setValueDiscount(props.courseInfo.discount)
        }
    }, [props.courseInfo]) // eslint-disable-line react-hooks/exhaustive-deps



    // Изменение активности курса
    const changeCourseActive = (isChecked: boolean) => {
        props.editCourseInfo(props.getEditableCourseID, 'is_active', isChecked).then((result: any) => {
            switch (result?.code) {
                case "ERR_QUERY_STRING": { return openNotification("Активность курса...", props.msg.err_0058, "error", "bottomRight") }
                default: errorHandler(result?.code, props.msg); break
            }
        })
    }

    // Изменение в полях ввода
    const changesFields = (fieldName: string) => {
        let fieldValue = '' // Значение поля

        switch (fieldName) {
            case 'author': setIsEditableCourseAuthor(false); fieldValue = getValueCourseAuthor; break
            case 'head_text': setIsEditableCourseName(false); fieldValue = getValueCourseName; break
            case 'duration': setIsEditableCourseDuration(false); fieldValue = getValueDuration; break
            case 'value_hours': setIsEditableCourseValueHours(false); fieldValue = getValueHours; break
            case 'date_start': setIsVisibleSelectDate(false); setDateState(undefined); fieldValue = moment(getDateState).format("YYYY-MM-DD"); break
            case 'title_text': fieldValue = editorState; break
            case 'sum_1': setIsEditableCourseSum1(false); fieldValue = getValueSum1; break
            case 'sum_2': setIsEditableCourseSum2(false); fieldValue = getValueSum2; break
            case 'sum_3': setIsEditableCourseSum3(false); fieldValue = getValueSum3; break
            case 'discount': setIsEditableCourseDiscount(false); fieldValue = getValueDiscount; break
        }

        props.editCourseInfo(props.getEditableCourseID, fieldName, fieldValue).then((result: any) => {
            switch (result?.code) {
                case "ERR_QUERY_STRING": { return openNotification("Редактирование курса...", props.msg.err_0053, "error", "bottomRight") }
                default: errorHandler(result?.code, props.msg); break
            }
        })
    }
    
    // Загрузка файлов на сервер
    const fileUploader = (fileList: any, previousFileName: string) => {
        fileList.forEach((file: any) => {
            props.uploadFileCourseInfo(file, props.getEditableCourseID, previousFileName, file.name).then((result: any) => {
                switch (result?.code) {
                    case "ERR_QUERY_STRING": { return openNotification("Ошибка загрузки файла...", props.msg.err_0054, "error", "bottomRight") }
                    case "ERR_FILE_NOT_FOUND": { return openNotification("Ошибка загрузки файла...", props.msg.err_0055, "error", "bottomRight") }
                    case "ERR_FILE_EXTNAME": { return openNotification("Ошибка загрузки файла...", props.msg.err_0056, "error", "bottomRight") }
                    case "ERR_FILE_SIZE": { return openNotification("Ошибка загрузки файла...", props.msg.err_0057, "error", "bottomRight") }
                    default: errorHandler(result?.code, props.msg); break
                }
            })
        })
    }

    // Редактирование цифровых полей с проверкой
    const changeNumberValue = (fieldName: string, value: string) => {
        const re = /^[0-9\b]+$/
        if (value === '' || re.test(value)) {
            switch (fieldName) {
                case 'duration': setValueDuration(value); break;
                case 'value_hours': setValueHours(value); break;
                case 'sum_1': setValueSum1(value); break;
                case 'sum_2': setValueSum2(value); break;
                case 'sum_3': setValueSum3(value); break;
                case 'discount': setValueDiscount(value); break;
            }
        }
    }

    // Свойства передаваемые в компоненту
    const propsToComponent = {
        changeCourseActive, // Изменение активности курса
        changesFields, // Изменение в полях ввода
        fileUploader, // Загрузка файлов на сервер
        changeNumberValue, // Редактирование цифровых полей с проверкой

        getIsEditableCourseAuthor, setIsEditableCourseAuthor, // Разрешение на редактирование автора курса
        getIsEditableCourseName, setIsEditableCourseName, // Разрешение на редактирование названия курса
        getIsEditableCourseDuration, setIsEditableCourseDuration, // Разрешение на редактирование от скольки дней
        getIsEditableCourseValueHours, setIsEditableCourseValueHours, // Разрешение на редактирование от скольки часов
        getIsEditableCourseSum1, setIsEditableCourseSum1, // Разрешение на редактирование сумма пакет 1
        getIsEditableCourseSum2, setIsEditableCourseSum2, // Разрешение на редактирование сумма пакет 2
        getIsEditableCourseSum3, setIsEditableCourseSum3, // Разрешение на редактирование сумма пакет 3
        getIsEditableCourseDiscount, setIsEditableCourseDiscount, // Разрешение на редактирование скидка

        getValueCourseAuthor, setValueCourseAuthor, // Данные поля, автор курса
        getValueCourseName, setValueCourseName, // Данные поля, название курса
        getValueDuration, // Данные поля, от скольки дней
        getValueHours, // Данные поля, от скольки часов
        getValueSum1, // Данные поля, сумма 1
        getValueSum2, // Данные поля, сумма 2
        getValueSum3, // Данные поля, сумма 3
        getValueDiscount, // Данные поля, скидка

        getValueDateStart, setValueDateStart, // Данные поля, старт курса
        getDateState, setDateState, // Выбранная дата в календаре
        getIsVisibleSelectDate, setIsVisibleSelectDate, // Видимость окна выбора даты

        editorState, setEditorState, // Данные поля описание курса
    }

    return (
        <>
            {props.getIsOpenCourseInfo && <CourseInfo {...props} {...propsToComponent} />}
        </>
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        courseInfo: state.courses.courseInfo,
    }
}

export default compose(connect(mapState, { 
    getCourseInfo, clearCourseInfo, editCourseInfo, uploadFileCourseInfo
}))(CourseInfoContainer)