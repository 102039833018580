import React from 'react'

import { Image } from 'antd'

import LandingHeader from './landingHeader'
import FooterContainer from '../common/footer/footerContainer'
import Section01 from './section_01'
import Section02 from './section_02'
import Section03 from './section_03'
import Section04 from './section_04'
import Section05 from './section_05'
import Section06 from './section_06'
import Section07 from './section_07'
import Section08 from './section_08'

const Landing = (props: any) => {
    const [visible, setVisible] = React.useState(false) // Открыто или закрыто окно, просмотр лицензии
    
    return (
        <>
            <LandingHeader {...props} />
            {props.page.b01_is_active?.value === 'true' ? <Section01 {...props} /> : ''} {/* Основной блок */}
            {props.page.b02_is_active?.value === 'true' ? <Section02 {...props} /> : ''} {/* О школе */}
            {props.page.b03_is_active?.value === 'true' ? <Section03 {...props} /> : ''} {/* Чего вы хотите */}
            {props.page.b04_is_active?.value === 'true' ? <Section04 {...props} /> : ''} {/* Курсы школы */}
            {props.page.b05_is_active?.value === 'true' ? <Section05 {...props} licVisible={setVisible} /> : ''} {/* Лицензия */}
            {props.page.b06_is_active?.value === 'true' ? <Section06 {...props} /> : ''} {/* Об авторе */}
            {props.page.b07_is_active?.value === 'true' ? <Section07 {...props} /> : ''} {/* Отзывы */}
            {props.page.b08_is_active?.value === 'true' ? <Section08 {...props} /> : ''} {/* Вопрос */}
            <FooterContainer {...props} />

            <div style={{ display: 'none' }}>
                <Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis) }}>
                    {!props.files.license ? 'УПС' : props.files.license.map((item: any) => {
                        return (
                            <Image key={item.file_id} src={item.file_path} />
                        )
                    })}
                </Image.PreviewGroup>
            </div>
        </>
    )
}

export default Landing