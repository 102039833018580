import React from 'react'
import { Drawer, Modal } from 'rsuite'
import { NavLink } from 'react-router-dom'
import SunEditor from 'suneditor-react'
import Dropzone from 'react-dropzone'
import { ColorPicker } from 'react-color-palette'
import 'react-color-palette/lib/css/styles.css'

import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined'

import { BootstrapTooltip } from '../../../common/ui/tooltip'
import empty_1 from './../../../../css/img/empty.png'
import css from './courseContent.module.scss'

import CourseHeader from './courseHeader'
import Section01 from './section_01'
import Section02 from './section_02'
import Section03 from './section_03'
import Section04 from './section_04'
import Section05 from './section_05'
import Section06 from './section_06'
import Section07 from './section_07'
import Section08 from './section_08'
import Section09 from './section_09'
import Section10 from './section_10'
import Section11 from './section_11'

import AddDeleteProgramsContainer from '../addDeletePrograms/addDeleteProgramsContainer'
import AddDeleteCommentsContainer from '../addDeleteComments/addDeleteCommentsContainer'
import AddDeleteQuestionsContainer from '../addDeleteQuestions/addDeleteQuestionsContainer'

const CourseContent = (props: any) => {
    return (
        <>
            <EditTextPrimary {...props} />
            <EditTextSecondary {...props} />
            <EditImage {...props} />
            <EditColor {...props} />

            <AddDeleteProgramsContainer {...props} />
            <AddDeleteCommentsContainer {...props} />
            <AddDeleteQuestionsContainer {...props} />

            <Drawer backdrop='static' size='full' autoFocus={true} open={props.getIsOpenCourseContent}>
                <div className={css.main}>
                    <header className={css.header}>
                        <div>
                            <h4>Контент курса</h4>
                            <h5>{props.page.head_text}</h5>
                        </div>

                        <NavLink to={`/course/${props.page.head_translit}`} className="myButtonIconTextPrimarySmall" style={{ textDecoration: "none" }} target="_blank">
                            <LinkOutlinedIcon />
                            <span>Предпросмотр курса</span>
                        </NavLink>

                        <BootstrapTooltip title="Закрыть" placement="bottom" arrow>
                            <div className='myButtonIconPrimaryBorder' onClick={() => props.openCourseContent(false)}>
                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_239_32)">
                                        <path d="M20.5833 6.94417L19.0558 5.41667L13 11.4725L6.94413 5.41667L5.41663 6.94417L11.4725 13L5.41663 19.0558L6.94413 20.5833L13 14.5275L19.0558 20.5833L20.5833 19.0558L14.5275 13L20.5833 6.94417Z" fill="currentColor" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_239_32">
                                            <rect width="26" height="26" fill="currentColor" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </BootstrapTooltip>
                    </header>

                    <main className={css.content}>
                        <div className={css.page}>
                            <div className={css.pageContent}>
                                <CourseHeader {...props} />
                                <Section01 {...props} />  {/* Основной блок */}
                                <Section02 {...props} /> {/* О курсе */}
                                <Section03 {...props} /> {/* Кому подойдет этот курс */}
                                <Section04 {...props} /> {/* Чему вы научитесь */}
                                <Section05 {...props} /> {/* Программа курса */}
                                <Section06 {...props} /> {/* Форматы обучения */}
                                <Section07 {...props} /> {/* Как проходит обучение */}
                                <Section08 {...props} /> {/* Лицензия */}
                                <Section09 {...props} /> {/* Отзывы */}
                                <Section10 {...props} /> {/* Вопросы */}
                                <Section11 {...props} /> {/* Вопрос */}
                            </div>
                        </div>
                    </main>
                </div>
            </Drawer>
        </>
    )
}


// Редактирование текста стандарт
const EditTextPrimary = (props: any) => {
    return (
        <Modal backdrop="static" size="sm" autoFocus={false} open={props.getOpenEditTextPrimary} className='myModalPrimary'>
            <div className="modalContent">
                <div className="modalHead">
                    <div>Редактирование текста...</div>

                    <BootstrapTooltip title="Закрыть" placement="bottom" arrow>
                        <div className="myButtonIconPrimaryBorder" onClick={() => props.openEditTextPrimary(false)}>
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_239_32)">
                                    <path d="M20.5833 6.94417L19.0558 5.41667L13 11.4725L6.94413 5.41667L5.41663 6.94417L11.4725 13L5.41663 19.0558L6.94413 20.5833L13 14.5275L19.0558 20.5833L20.5833 19.0558L14.5275 13L20.5833 6.94417Z" fill="currentColor" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_239_32">
                                        <rect width="26" height="26" fill="currentColor" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </BootstrapTooltip>
                </div>

                <div className="modalBody" style={{ display: 'block' }}>
                    <div className='myInputTextPrimary'>
                        <input type='text' placeholder='' autoComplete='off' 
                            value={props.getBlockValue} onChange={e => props.setBlockValue(e.target.value)}
                        />
                    </div>
                </div>

                <div className="modalFooter">
                    <div className='myButtonSecondary' onClick={() => props.deleteData()}>УДАЛИТЬ</div>
                    <div className='myButtonPrimary' onClick={() => props.applyData()}>ПРИМЕНИТЬ</div>
                </div>
            </div>
        </Modal>
    )
}

// Редактирование текста расширенное
const EditTextSecondary = (props: any) => {
    return (
        <Modal backdrop="static" size="lg" autoFocus={false} open={props.getOpenEditTextSecondary} className='myModalPrimary'>
            <div className="modalContent">
                <div className="modalHead" style={{ display: 'grid', gridTemplateColumns: '1fr auto' }}>
                    <div>Редактирование текста...</div>

                    <BootstrapTooltip title="Закрыть" placement="bottom" arrow>
                        <div className="myButtonIconPrimaryBorder" onClick={() => props.openEditTextSecondary(false)}>
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_239_32)">
                                    <path d="M20.5833 6.94417L19.0558 5.41667L13 11.4725L6.94413 5.41667L5.41663 6.94417L11.4725 13L5.41663 19.0558L6.94413 20.5833L13 14.5275L19.0558 20.5833L20.5833 19.0558L14.5275 13L20.5833 6.94417Z" fill="currentColor" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_239_32">
                                        <rect width="26" height="26" fill="currentColor" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </BootstrapTooltip>
                </div>

                <div className="modalBody">
                    <SunEditor lang='ru' setDefaultStyle="font-size: 20px;"
                        setContents={props.getBlockValue} onChange={props.setBlockValue}
                        setOptions={{
                            mode: 'classic',
                            width: '100%',
                            height: 'auto',
                            minHeight: '250',
                            maxHeight: '300',
                            buttonList: [
                                [
                                    "undo", // Отменить
                                    "redo", // Вернуть
                                ],
                                [
                                    "removeFormat", // Очистить форматирование
                                ],
                                [
                                    "bold", // Полужирный шрифт
                                    "italic", // Курсив шрифта
                                    "underline", // Подчеркнутый шрифт
                                    "strike", // Зачеркнутый шрифт
                                ],
                                [
                                    "fontColor", // Цвет шрифта
                                    "hiliteColor", // Цвет фона шрифта
                                ],
                                [
                                    "outdent", // Уменьшить отступ
                                    "indent", // Увеличить отступ
                                ],
                                [
                                    "list", // Списки
                                    "align", // Выравнивание
                                    "lineHeight", // Высота линии
                                ],
                                [
                                    "blockquote", // Цитата
                                    "horizontalRule", // Вставить горизонтальную линию
                                    "link", // Ссылка
                                ],


                                [
                                    "-right",
                                    "preview", // Предварительный просмотр
                                    "print" // Печать
                                ],
                                [
                                    "-right",
                                    "codeView", // Редактировать HTML
                                    "fullScreen", // Развернуть на полный экран
                                    "showBlocks", // Блочный вид
                                ],
                                // [
                                //     // "font", // Шрифт
                                //     // "fontSize", // Размер шрифта
                                //     // "formatBlock", // Стиль абзаца
                                //     // "paragraphStyle", // Стиль абзаца
                                //     // "subscript", // Шрифт в виде нижнего индекса
                                //     // "superscript", // Шрифт в виде верхнего индекса
                                //     // "textStyle", // Стиль текста
                                //     // "table", // Таблица
                                //     // "image", // Изображение
                                //     // "video", // Видео
                                //     // "audio", // Аудио
                                //     // "save", // Сохранить
                                // ]
                            ]
                        }}
                    />
                </div>

                <div className="modalFooter">
                    <div className='myButtonSecondary' onClick={() => props.deleteData()}>УДАЛИТЬ</div>
                    <div className='myButtonPrimary' onClick={() => props.applyData()}>ПРИМЕНИТЬ</div>
                </div>
            </div>
        </Modal>
    )
}

// Редактирование изображений
const EditImage = (props: any) => {
    return (
        <Modal backdrop="static" size="sm" autoFocus={false} open={props.getOpenEditImage} className='myModalPrimary'>
            <div className="modalContent">
                <div className="modalHead" style={{ display: 'grid', gridTemplateColumns: '1fr auto' }}>
                    <div>Редактирование изображения...</div>

                    <BootstrapTooltip title="Закрыть" placement="bottom" arrow>
                        <div className="myButtonIconPrimaryBorder" onClick={() => props.openEditImage(false)}>
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_239_32)">
                                    <path d="M20.5833 6.94417L19.0558 5.41667L13 11.4725L6.94413 5.41667L5.41663 6.94417L11.4725 13L5.41663 19.0558L6.94413 20.5833L13 14.5275L19.0558 20.5833L20.5833 19.0558L14.5275 13L20.5833 6.94417Z" fill="currentColor" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_239_32">
                                        <rect width="26" height="26" fill="currentColor" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </BootstrapTooltip>
                </div>

                <div className='myDividerHorizontal'></div>

                <div className="modalBody">
                    <Dropzone onDropAccepted={(files) => props.fileUploader(files)} multiple={false}>
                        {({ getRootProps, getInputProps, isDragAccept, isDragActive }) => (
                            <div {...getRootProps({ className: isDragAccept ? `${css.dropZone}` : `` })} style={{ maxWidth: `460px`, minHeight: '300px', borderRadius: '10px', cursor: 'pointer', margin: '0 auto' }}>
                                <input {...getInputProps()} />
                                <img src={props.getBlockValue || empty_1} alt="Изображение" style={{ maxWidth: `460px`, minHeight: '300px', }} />
                            </div>
                        )}
                    </Dropzone>
                </div>

                <div className='myDividerHorizontal'></div>

                <div className='myButtonPrimary' onClick={() => props.deleteData()}>УДАЛИТЬ</div>
            </div>
        </Modal>
    )
}

// Редактирование цвета
const EditColor = (props: any) => {
    return (
        <Modal backdrop="static" size="xs" autoFocus={false} open={props.getOpenEditColor} className='myModalPrimary'>
            <div className="modalContent">
                <div className="modalHead" style={{ display: 'grid', gridTemplateColumns: '1fr auto' }}>
                    <div>Редактирование цвета...</div>

                    <BootstrapTooltip title="Закрыть" placement="bottom" arrow>
                        <div className="myButtonIconPrimaryBorder" onClick={() => props.openEditColor(false)}>
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_239_32)">
                                    <path d="M20.5833 6.94417L19.0558 5.41667L13 11.4725L6.94413 5.41667L5.41663 6.94417L11.4725 13L5.41663 19.0558L6.94413 20.5833L13 14.5275L19.0558 20.5833L20.5833 19.0558L14.5275 13L20.5833 6.94417Z" fill="currentColor" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_239_32">
                                        <rect width="26" height="26" fill="currentColor" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </BootstrapTooltip>
                </div>

                <div className='myDividerHorizontal'></div>

                <div className="modalBody">
                    <ColorPicker width={360} height={150} color={props.getColor} onChange={props.setColor} hideRGB hideHSV dark />
                </div>

                <div className='myDividerHorizontal'></div>

                <div className="modalFooter">
                    <div className='myButtonSecondary' onClick={() => props.deleteData()}>УДАЛИТЬ</div>
                    <div className='myButtonPrimary' onClick={() => props.applyData()}>ПРИМЕНИТЬ</div>
                </div>
            </div>
        </Modal>
    )
}


export default CourseContent