import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { AppStateType } from '../../../bll/store'
import Files from './files'
import { clearFiles, clearFileTypes, createFile, editFiles, getFiles, getFileTypes, uploadFile } from '../../../bll/lkFiles/lkFilesOperations'
import openNotification from '../../common/ui/notification'
import errorHandler from '../../../bll/errorHandler'

const FilesContainer = (props: any) => {
    const [getIsVisibleFiles, setIsVisibleFiles] = React.useState(false) // Видимость окна создания (редактирования) файла
    const [getIsVisibleUploadFile, setIsVisibleUploadFile] = React.useState(false) // Видимость окна загрузки файла
    const [getAction, setAction] = React.useState('') // Действие (создание или редактирование)

    const [getValueID, setValueID] = React.useState('') // Идентификатор файла
    const [getValueComment, setValueComment] = React.useState('') // Описание файла в окне создания (редактирования)
    const [getValueFileName, setValueFileName] = React.useState('') // Имя редактируемого файла
    const [getCheckedFileTypeID, setCheckedFileTypeID] = React.useState('') // Выбранный тип файла в окне создания (редактирования) файла

    const [getFilter, setFilter] = React.useState('') // Фильтр файлов


    React.useEffect(() => {
        props.getFileTypes().then((result: any) => {
            switch (result?.code) {
                case "ERR_QUERY_STRING": { return openNotification("Список файлов...", props.msg.err_0091, "error", "bottomRight") }
                default: errorHandler(result?.code, props.msg); break
            }
        })

        props.getFiles().then((result: any) => {
            switch (result?.code) {
                case "ERR_QUERY_STRING": { return openNotification("Список файлов...", props.msg.err_0087, "error", "bottomRight") }
                default: errorHandler(result?.code, props.msg); break
            }
        })

        return () => {
            props.clearFileTypes()
            props.clearFiles()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    // Открытие (закрытие) окна создания (редактирования) файла
    const openAddEditFile = (isOpen: boolean, action: string = '', id: string = '', name: string = '', fileTypeID: string = '') => {
        setValueID(id)
        setValueComment(name)
        setCheckedFileTypeID(fileTypeID)

        setAction(action)
        setIsVisibleFiles(isOpen)
    }

    // Открытие (закрытие) окна загрузки файла
    const openUploadFile = (isOpen: boolean, id: string = '', fileName: string = '') => {
        setValueID(id)
        setValueFileName(fileName)
        setIsVisibleUploadFile(isOpen)
    }

    // Кнопка применить в окне создания (редактирования) файла
    const applyButton = () => {
        let errorFields = false

        if (getAction === 'add') { // Создание файла
            if (getValueComment.length === 0) {
                errorFields = true
                openNotification("Создание файла...", 'Необходимо ввести комментарий к файлу.', "warning", "bottomRight")
            }

            if (getCheckedFileTypeID.length === 0) {
                errorFields = true
                openNotification("Создание файла...", 'Необходимо выбрать раздел файла.', "warning", "bottomRight")
            }

            if (!errorFields) {
                props.createFile(getCheckedFileTypeID, getValueComment).then((result: any) => {
                    switch (result?.code) {
                        case "OK":  
                            openAddEditFile(false)
                        break

                        case "ERR_FILE_SINGLE": { return openNotification("Создание файла...", 'В выбранном Вами разделе, уже имеется файл.<br/>В данный раздел разрешено добавлять только один файл.', "warning", "bottomRight") }
                        case "ERR_QUERY_STRING": { return openNotification("Создание файла...", props.msg.err_0088, "error", "bottomRight") }
                        default: errorHandler(result?.code, props.msg); break
                    }
                })
            }
        }

        if (getAction === 'edit') { // Редактирование файла
            props.editFiles(getValueID, getCheckedFileTypeID, getValueComment).then((result: any) => {
                switch (result?.code) {
                    case "OK":  
                        openAddEditFile(false)
                    break

                    case "ERR_FILE_SINGLE": { return openNotification("Редактирование файла...", 'В выбранном Вами разделе, уже имеется файл.<br/>В данный раздел разрешено добавлять только один файл.', "warning", "bottomRight") }
                    case "ERR_QUERY_STRING": { return openNotification("Редактирование файла...", props.msg.err_0089, "error", "bottomRight") }
                    default: errorHandler(result?.code, props.msg); break
                }
            })
        }
    }

    // Функция по загрузке файлов на сервер
    const fileUploader = (fileList: any) => {
        fileList.forEach((file: any) => {
            props.uploadFile(file, getValueID, getValueFileName, file.name).then((result: any) => {
                switch (result?.code) {
                    case "OK": 
                        setIsVisibleUploadFile(false)
                    break

                    case "ERR_QUERY_STRING": { return openNotification("Ошибка загрузки файла...", props.msg.err_0054, "error", "bottomRight") }
                    case "ERR_FILE_NOT_FOUND": { return openNotification("Ошибка загрузки файла...", props.msg.err_0055, "error", "bottomRight") }
                    case "ERR_FILE_EXTNAME": { return openNotification("Ошибка загрузки файла...", props.msg.err_0056, "error", "bottomRight") }
                    case "ERR_FILE_SIZE": { return openNotification("Ошибка загрузки файла...", props.msg.err_0092, "error", "bottomRight") }
                    default: errorHandler(result?.code, props.msg); break
                }
            })
        })
    }

    // Копирование ссылки
    const unsecuredCopyToClipboard = (text: any) => { const textArea = document.createElement("textarea"); textArea.value=text; document.body.appendChild(textArea); textArea.focus();textArea.select(); try{document.execCommand('copy')}catch(err){console.error('Unable to copy to clipboard',err)}document.body.removeChild(textArea)};
    const copyLink = async (link: string) => {
        if (link) {
            openNotification("Копирование ссылки...", 'Ссылка успешно скопирована в буфер обмена', "success", "bottomRight")
            
            if (window.isSecureContext && navigator.clipboard) {
                navigator.clipboard.writeText(link)
            } else {
                unsecuredCopyToClipboard(link)
            }            
            
        } else {
            openNotification("Копирование ссылки...", 'Не удалось скопировать ссылку, т.к. файл отсутствует.', "error", "bottomRight")
        }
    }


    // Свойства передаваемые в компоненту
    const propsToComponent = {
        openAddEditFile, // Открытие (закрытие) окна создания (редактирования) файла
        openUploadFile, // Открытие (закрытие) окна загрузки файла
        applyButton, // Кнопка применить в окне создания (редактирования) файла
        fileUploader, // Функция по загрузке файлов на сервер
        copyLink, // Копирование ссылки

        getIsVisibleFiles, // Видимость окна создания (редактирования) файла
        getIsVisibleUploadFile, // Видимость окна загрузки файла
        getAction, setAction, // Действие (создание или редактирование)

        getValueComment, setValueComment, // Описание файла в окне создания (редактирования)
        getCheckedFileTypeID, setCheckedFileTypeID, // Выбранный тип файла в окне создания (редактирования) файла

        getFilter, setFilter, // Фильтр файлов
    }

    return (
        <Files {...props} {...propsToComponent} />
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        fileTypesList: state.files.fileTypesList,
        filesList: state.files.filesList,
    }
}

export default compose(connect(mapState, { 
    getFileTypes, clearFileTypes,
    getFiles, clearFiles, createFile, editFiles, uploadFile
}))(FilesContainer)