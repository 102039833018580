import React from 'react'
import parse from 'html-react-parser'

const Section07 = (props: any) => {
    return (
        <section>
            <div className='container CB07' style={{ background: `${props.page.b07.bg_color} url(${props.page.b07.bg_img_path}) no-repeat center/100% 100%` }}>
                <article className='content'>
                    <h2>{parse(props.page.b07.text_1 || '')}</h2>

                    <div className='items'>
                        <div className='item'>
                            {props.page.b07.img_1_path && <img src={props.page.b07.img_1_path} alt={props.page.b07.text_2?.replace(/<[^>]+>/g, '')} />}

                            <article>
                                <h6>{parse(props.page.b07.text_2 || '')}</h6>
                                <div>{parse(props.page.b07.text_3 || '')}</div>
                            </article>
                        </div>

                        <div className='item'>
                            {props.page.b07.img_2_path && <img src={props.page.b07.img_2_path} alt={props.page.b07.text_4?.replace(/<[^>]+>/g, '')} />}

                            <article>
                                <h6>{parse(props.page.b07.text_4 || '')}</h6>
                                <div>{parse(props.page.b07.text_5 || '')}</div>
                            </article>
                        </div>

                        <div className='item'>
                            {props.page.b07.img_3_path && <img src={props.page.b07.img_3_path} alt={props.page.b07.text_6?.replace(/<[^>]+>/g, '')} />}

                            <article>
                                <h6>{parse(props.page.b07.text_6 || '')}</h6>
                                <div>{parse(props.page.b07.text_7 || '')}</div>
                            </article>
                        </div>

                        <div className='item'>
                            {props.page.b07.img_4_path && <img src={props.page.b07.img_4_path} alt={props.page.b07.text_8?.replace(/<[^>]+>/g, '')} />}

                            <article>
                                <h6>{parse(props.page.b07.text_8 || '')}</h6>
                                <div>{parse(props.page.b07.text_9 || '')}</div>
                            </article>
                        </div>

                        <div className='item'>
                            {props.page.b07.img_5_path && <img src={props.page.b07.img_5_path} alt={props.page.b07.text_10?.replace(/<[^>]+>/g, '')} />}

                            <article>
                                <h6>{parse(props.page.b07.text_10 || '')}</h6>
                                <div>{parse(props.page.b07.text_11 || '')}</div>
                            </article>
                        </div>

                        <div className='item'>
                            {props.page.b07.img_6_path && <img src={props.page.b07.img_6_path} alt={props.page.b07.text_12?.replace(/<[^>]+>/g, '')} />}

                            <article>
                                <h6>{parse(props.page.b07.text_12 || '')}</h6>
                                <div>{parse(props.page.b07.text_13 || '')}</div>
                            </article>
                        </div>
                    </div>
                </article>
            </div>
        </section>
    )
}

export default Section07