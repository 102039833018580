import React from 'react'
import parse from 'html-react-parser'
import { BootstrapTooltip } from '../../../common/ui/tooltip'

const Section08 = (props: any) => {
    return (
        <section id='license'>
            <article className='container CB08'>
                <BootstrapTooltip title="Редактировать" placement="bottom-end" arrow>
                    <h2 className='itemHover' onClick={() => props.openEditTextSecondary(true, '08', 'text_1', 'text', props.page.b08.text_1)}>
                        {parse(props.page.b08.text_1 || 'Редактировать')}
                    </h2>
                </BootstrapTooltip>

                <BootstrapTooltip title="Редактировать" placement="bottom-end" arrow>
                    <h4 className='itemHover' onClick={() => props.openEditTextSecondary(true, '08', 'text_2', 'text', props.page.b08.text_2)}>
                        {parse(props.page.b08.text_2 || 'Редактировать')}
                    </h4>
                </BootstrapTooltip>

                <BootstrapTooltip title="Редактировать" placement="bottom-end" arrow>
                    <div className='itemHover' onClick={() => props.openEditTextSecondary(true, '08', 'text_3', 'text', props.page.b08.text_3)}>
                        {parse(props.page.b08.text_3 || 'Редактировать')}
                    </div>
                </BootstrapTooltip>

                <button className='buttonSecondary_2'>Просмотр лицензии</button>

                <section className='myPopoverVertical'>
                    <h5>Блок № 8</h5>
                    
                    <div className='dividerPrimary' style={{ height: '40px' }}></div>
    
                    <BootstrapTooltip title="Видимость блока" placement="bottom" arrow>
                        <input type="checkbox"
                            checked={props.page.b08.is_active === 1 ? true : false}
                            onChange={(e) => props.onChangeBlockActive('08', 'is_active', 'isActive', e.target.checked)}
                        />
                    </BootstrapTooltip>
                </section>
            </article>
        </section>
    )
}

export default Section08