import React from 'react'
// import { Image } from 'antd'

import css from './nav-07.module.scss'

const Nav_07 = (props: any) => {
    return (
        <section className={css.main}>
            <h5>Материально-техническое обеспечение и оснащённость образовательного процесса</h5>

            <span>Места осуществления образовательной деятельности, в том числе не указываемые в приложении к лицензии на осуществление образовательной деятельности:</span>
            
            <span>
                <b>Адрес места осуществления образовательной деятельности:</b> Российская Федерация, 410002, Приволжский федеральный округ, Саратовская область, город Саратов, ул. Мичурина, д. 150/154.
            </span>
        </section>
    )
}

export default Nav_07