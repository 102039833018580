import React from 'react'

import css from './nav-03.module.scss'

const Nav_03 = (props: any) => {
    return (
        <section className={css.main}>
            <h5>Документы</h5>

            {props.files.organization_documents?.map((item: any) => {
                return (
                    <a href={item.file_path} key={item.file_id} target='_blank' rel="noreferrer">{item.file_comment}</a>
                )
            })}

        </section>
    )
}

export default Nav_03