import api from './../../api/apiLkFiles'
import actions from './lkFilesActions'

// Получение справочника - тип раздела файлов
export const getFileTypes = () => async (dispatch: any) => {
    const responce = await api.getFileTypes()

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.setFileTypes(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}


// Получение списка файлов
export const getFiles = () => async (dispatch: any) => {
    const responce = await api.getFiles()

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.setFilesList(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Создание файла
export const createFile = (fileTypeID: string, comment: string) => async (dispatch: any) => {
    const responce = await api.createFile(fileTypeID, comment)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getFiles())
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Редактирование файла
export const editFiles = (fileID: string, fileTypeID: string, comment: string) => async (dispatch: any) => {
    const responce = await api.editFiles(fileID, fileTypeID, comment)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getFiles())
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Загрузка файла
export const uploadFile = (file: any, fileID: number, previousFileName: string, fileName: string) => async (dispatch: any) => {
    const responce = await api.uploadFile(file, fileID, previousFileName, fileName)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getFiles())
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}



// Очистка справочника - тип раздела файлов
export const clearFileTypes = () => async (dispatch: any) => {
    dispatch(actions.setFileTypes([]))
}

// Очистка списка файлов
export const clearFiles = () => async (dispatch: any) => {
    dispatch(actions.setFilesList([]))
}