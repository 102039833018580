import React from 'react'
import parse from 'html-react-parser'

const BlockSection03 = (props: any) => {
    return (
        <section>
            <article className='container LB03'>
                <h2>{parse(props.page.b03_text_1?.value || '')}</h2>

                <div className='content'>
                    <div className='item'>
                        <img src={props.page.b03_img_1_path?.value} alt={props.page.b03_text_2?.value?.replace(/<[^>]+>/g, '')} />

                        <article>
                            <h4>{parse(props.page.b03_text_2?.value || '')}</h4>
                            <div>{parse(props.page.b03_text_3?.value || '')}</div>
                        </article>
                    </div>

                    <div className='item'>
                        <img src={props.page.b03_img_2_path?.value} alt={props.page.b03_text_4?.value?.replace(/<[^>]+>/g, '')} />

                        <article>
                            <h4>{parse(props.page.b03_text_4?.value || '')}</h4>
                            <div>{parse(props.page.b03_text_5?.value || '')}</div>
                        </article>
                    </div>

                    <div className='item'>
                        <img src={props.page.b03_img_3_path?.value} alt={props.page.b03_text_6?.value?.replace(/<[^>]+>/g, '')} />

                        <article>
                            <h4>{parse(props.page.b03_text_6?.value || '')}</h4>
                            <div>{parse(props.page.b03_text_7?.value || '')}</div>
                        </article>
                    </div>

                    <div className='item'>
                        <img src={props.page.b03_img_4_path?.value} alt={props.page.b03_text_8?.value?.replace(/<[^>]+>/g, '')} />

                        <article>
                            <h4>{parse(props.page.b03_text_8?.value || '')}</h4>
                            <div>{parse(props.page.b03_text_9?.value || '')}</div>
                        </article>
                    </div>
                </div>
            </article>
        </section>
    )
}

export default BlockSection03