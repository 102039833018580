import React from 'react'
// import { Image } from 'antd'

import css from './nav-11.module.scss'

const Nav_11 = (props: any) => {
    return (
        <section className={css.main}>
            <h5>Вакантные места для приёма (перевода)</h5>

            <span>Информация о количестве вакантных мест для приема (перевода) за счет бюджетных ассигнований федерального бюджета, бюджетов субъектов Российской Федерации, местных бюджетов</span>
            <span><b>Обучение за счет бюджетных ассигнований федерального бюджета, бюджетов субъектов Российской Федерации, местных бюджетов не проводится.</b></span>
            
            <span>Информация о количестве вакантных мест для приема (перевода) по договорам об образовании за счет средств физических и (или) юридических лиц)</span>
            <span><b>Информацию о количестве вакантных мест для приема (перевода) по договорам об образовании за счет средств физических и (или) юридических лиц) структурирована ниже.</b></span>
            
            <span><b>Результаты приема:</b> все кандидаты, подавшие заявление на обучение, не имеющие медицинских противопоказаний и ограничений по возрасту, были приняты в учреждение.</span>
            
            <span><b>Условия приема на обучение:</b> документы слушателя согласно требований к абитуриентам, подписание договора на оказание платных образовательных услуг. Вступительных испытаний - нет.</span>
            
            <span>За счет бюджетных ассигнований федерального бюджета – мест не предусмотрено.</span>
            <span>За счет бюджетных ассигнований бюджетов субъектов Российской Федерации — мест не предусмотрено.</span>
            <span>За счет бюджетных ассигнований местных бюджетов — мест не предусмотрено.</span>
            <span>Ведется ежемесячный набор групп профессиональной подготовки (переподготовки).</span>
        </section>
    )
}

export default Nav_11