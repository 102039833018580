import thunk from 'redux-thunk'
import { applyMiddleware, combineReducers, createStore } from 'redux'

import messagesReducer from './messages'
import commonReducer from './common/commonReducer'
import authReducer from './auth/authReducer'
import lkCoursesReducer from './lkCourses/lkCoursesReducer'
import lkFilesReducer from './lkFiles/lkFilesReducer'
import lkDisciplinesReducer from './lkDisciplines/lkDisciplinesReducer'
import lkQuestionsReducer from './lkQuestions/lkQuestionsReducer'
import lkCommentsReducer from './lkComments/lkCommentsReducer'

let reducers = combineReducers({
    msg: messagesReducer,
    common: commonReducer,
    auth: authReducer,
    courses: lkCoursesReducer,
    disciplines: lkDisciplinesReducer,
    comments: lkCommentsReducer,
    questions: lkQuestionsReducer,
    files: lkFilesReducer,
})

export type AppStateType = ReturnType<typeof reducers>
export const store = createStore(reducers, applyMiddleware(thunk))

// @ts-ignore
// window._store = store