import api from './../../api/apiLkQuestions'
import actions from './lkQuestionsActions'

// Получение вопросов
export const getQuestions = () => async (dispatch: any) => {
    const responce = await api.getQuestions()

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.setQuestionsList(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Создание вопросов
export const createQuestions = (header: string, text: string) => async (dispatch: any) => {
    const responce = await api.createQuestions(header, text)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getQuestions())
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Редактирование вопросов
export const editQuestions = (questionID: string, header: string, text: string) => async (dispatch: any) => {
    const responce = await api.editQuestions(questionID, header, text)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getQuestions())
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}



// Очистка списка вопросов
export const clearQuestions = () => async (dispatch: any) => {
    dispatch(actions.setQuestionsList([]))
}