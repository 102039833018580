import React from 'react'

interface EmptyType {
    image: any,
    description?: string
    heightImg?: string
    heightBox?: string
    mainHeight?: string
}

const Empty: React.FC<EmptyType> = ({image, description, heightBox = "300px", heightImg = "250px", mainHeight = "100%"}) => {
    const styleEmpty: React.CSSProperties = {
        width: "100%",
        height: `${mainHeight}`,
    }

    const emptyContent: React.CSSProperties = {
        height: `${heightBox}`,
        position: "relative", 
        top: "50%", 
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    }

    const imageStyle: React.CSSProperties = {
        height: `${heightImg}`,
        
    }

    const descriptionStyle: React.CSSProperties = {
        textAlign: "center",
        letterSpacing: "2px",
        fontSize: "16px",
        fontWeight: "700",
        marginTop: "1rem",
        color: "#012155"
    }

    return (
        <div style={styleEmpty}>
            <div style={emptyContent}>
                <img src={`${image}`} alt="" style={imageStyle} />
                <div style={descriptionStyle}>{description}</div>
            </div>
        </div>
    )
}

export default Empty