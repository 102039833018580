import React from 'react'
import { Modal } from 'rsuite'
import Dropzone from 'react-dropzone'

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'

import { BootstrapTooltip } from '../../common/ui/tooltip'
import empty from './../../../css/img/empty.png'
import css from './comments.module.scss'
import SunEditor from 'suneditor-react'

const Comments = (props: any) => {
    return (
        <>
            <AddEditComment {...props} />
            <EditImageComment {...props} />

            <div className={css.main}>
                <header className={css.header}>
                    <h3>Отзывы школы</h3>

                    <aside>
                        <div className='myInputTextPrimary' style={{ width: '350px' }} onClick={() => props.setFilter('')}>
                            <SearchOutlinedIcon />

                            <input type='text' placeholder='Фильтр...' autoComplete='off'
                                value={props.getFilter} onChange={e => props.setFilter(e.target.value)}
                            />

                            {props.getFilter 
                                ? <BootstrapTooltip title="Очистить" placement="bottom" arrow>
                                    <div className='myButtonIconPrimaryBorder'>
                                        <HighlightOffOutlinedIcon sx={{ fontSize: "24px" }} />
                                    </div>
                                </BootstrapTooltip> 
                                : ''
                            }
                        </div>

                        <div className='dividerPrimary' style={{ height: '40px', margin: 'auto 0' }}></div>

                        <div className='myButtonIconTextPrimarySmall' onClick={() => props.openAddEditComment(true, 'add')}>
                            <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_229_703)">
                                    <path d="M14.0833 8.08332H11.9166V12.4167H7.58329V14.5833H11.9166V18.9167H14.0833V14.5833H18.4166V12.4167H14.0833V8.08332ZM13 2.66666C7.01996 2.66666 2.16663 7.51999 2.16663 13.5C2.16663 19.48 7.01996 24.3333 13 24.3333C18.98 24.3333 23.8333 19.48 23.8333 13.5C23.8333 7.51999 18.98 2.66666 13 2.66666ZM13 22.1667C8.22246 22.1667 4.33329 18.2775 4.33329 13.5C4.33329 8.72249 8.22246 4.83332 13 4.83332C17.7775 4.83332 21.6666 8.72249 21.6666 13.5C21.6666 18.2775 17.7775 22.1667 13 22.1667Z" fill="currentColor" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_229_703">
                                        <rect width="26" height="26" fill="currentColor" transform="translate(0 0.5)" />
                                    </clipPath>
                                </defs>
                            </svg>

                            <span>Создать</span>
                        </div>
                    </aside>
                </header>

                <main className={css.content}>
                    <section className={css.tHead}>
                        <span id={css.uid}></span>
                        <span id={css.name}>Отзыв</span>
                        <span id={css.uid}>Дата создания</span>
                        <span id={css.btn}></span>
                    </section>

                    <section className={css.tBody}>
                        {!props.commentsList.length
                            ? ''
                            : props.commentsList.filter((val: any) => {
                                let stringReturn

                                let header = val.header.toLowerCase().includes(props.getFilter.toLowerCase())

                                if (header) {
                                    stringReturn = val
                                }

                                return stringReturn
                            }).map((item: any) => {
                                return (
                                    <div key={item.comment_id} className={css.tRow}>
                                        <span id={css.uid}>{item.comment_id}</span>
                                        <span id={css.name}>{item.header}</span>
                                        <span id={css.uid}>{item.date_time_create}</span>
                                        <div id={css.btn}>
                                            <BootstrapTooltip title="Добавить / Заменить файл" placement="bottom-end" arrow>
                                                <div className='myButtonIconPrimaryBorder' onClick={() => props.openImageEdit(true, item.comment_id, item.img_path, item.img_name)}>
                                                    <CloudUploadOutlinedIcon />
                                                </div>
                                            </BootstrapTooltip>

                                            <BootstrapTooltip title="Редактировать" placement="bottom-end" arrow>
                                                <div className='myButtonIconPrimaryBorder' onClick={() => props.openAddEditComment(true, 'edit', item.comment_id, item.header, item.text)}>
                                                    <ModeEditOutlinedIcon />
                                                </div>
                                            </BootstrapTooltip>

                                        </div>
                                    </div>
                                )
                            })
                        }
                    </section>
                </main>

            </div>
        </>
    )
}

// Создание (Редактирование) отзыва
const AddEditComment = (props: any) => {
    return (
        <Modal backdrop="static" size="lg" autoFocus={false} open={props.getIsVisibleComments} className='myModalPrimary'>
            <div className="modalContent">
                <div className="modalHead" style={{ display: 'grid', gridTemplateColumns: '1fr auto' }}>
                    <div>
                        {
                            (props.getActionComments === '' && 'Создание / Редактирование отзыва...') ||
                            (props.getActionComments === 'add' && 'Создание отзыва...') ||
                            (props.getActionComments === 'edit' && 'Редактирование отзыва...')
                        }
                    </div>
                </div>

                <div className='myDividerHorizontal'></div>

                <div className="modalBody">

                    {/* Заголовок отзыва */}
                    <div className={css.txtItem}>
                        <span className={css.itemHead}>Заголовок отзыва</span>
                        <div className='myInputTextPrimary'>
                            <input type='text' placeholder='Заголовок отзыва' autoComplete='off' 
                                value={props.getValueCommentHead} onChange={e => props.setValueCommentHead(e.target.value)}
                            />
                        </div>
                    </div>

                    {/* Текст отзыва */}
                    <div className={css.txtItem}>
                        <div>Текст отзыва</div>
                        <SunEditor lang='ru' setDefaultStyle="font-size: 20px;"
                            setContents={props.getValueCommentText} onChange={props.setValueCommentText}
                            setOptions={{
                                mode: 'classic',
                                width: '100%',
                                height: 'auto',
                                minHeight: '250',
                                maxHeight: '300',
                                buttonList: [
                                    [
                                        "undo", // Отменить
                                        "redo", // Вернуть
                                    ],
                                    [
                                        "removeFormat", // Очистить форматирование
                                    ],
                                    [
                                        "bold", // Полужирный шрифт
                                        "italic", // Курсив шрифта
                                        "underline", // Подчеркнутый шрифт
                                        "strike", // Зачеркнутый шрифт
                                    ],
                                    [
                                        "fontColor", // Цвет шрифта
                                        "hiliteColor", // Цвет фона шрифта
                                    ],
                                    [
                                        "outdent", // Уменьшить отступ
                                        "indent", // Увеличить отступ
                                    ],
                                    [
                                        "list", // Списки
                                        "align", // Выравнивание
                                        "lineHeight", // Высота линии
                                    ],
                                    [
                                        "blockquote", // Цитата
                                        "horizontalRule", // Вставить горизонтальную линию
                                        "link", // Ссылка
                                    ],


                                    [
                                        "-right",
                                        "preview", // Предварительный просмотр
                                        "print" // Печать
                                    ],
                                    [
                                        "-right",
                                        "codeView", // Редактировать HTML
                                        "fullScreen", // Развернуть на полный экран
                                        "showBlocks", // Блочный вид
                                    ],
                                    // [
                                    //     // "font", // Шрифт
                                    //     // "fontSize", // Размер шрифта
                                    //     // "formatBlock", // Стиль абзаца
                                    //     // "paragraphStyle", // Стиль абзаца
                                    //     // "subscript", // Шрифт в виде нижнего индекса
                                    //     // "superscript", // Шрифт в виде верхнего индекса
                                    //     // "textStyle", // Стиль текста
                                    //     // "table", // Таблица
                                    //     // "image", // Изображение
                                    //     // "video", // Видео
                                    //     // "audio", // Аудио
                                    //     // "save", // Сохранить
                                    // ]
                                ]
                            }}
                        />

                    </div>
                </div>

                <div className="modalFooter">
                    <div className='myButtonSecondary' onClick={() => props.openAddEditComment(false)}>ОТМЕНА</div>
                    <div className='myButtonPrimary' onClick={() => props.applyButton()}>
                        {props.getActionComments === 'add' && 'ДОБАВИТЬ'}
                        {props.getActionComments === 'edit' && 'ПРИМЕНИТЬ'}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

// Редактирование картинки отзыва
const EditImageComment = (props: any) => {
    return (
        <Modal backdrop="static" size="sm" autoFocus={false} open={props.getIsVisibleImage} className='myModalPrimary'>
            <div className="modalContent">
                <div className="modalHead" style={{ display: 'grid', gridTemplateColumns: '1fr auto' }}>
                    <div>Добавить / Заменить изображение...</div>
                </div>

                <div className="modalBody">
                    <Dropzone onDropAccepted={(files) => props.fileUploader(files, props.getValueImgName)} multiple={false}>
                        {({ getRootProps, getInputProps, isDragAccept, isDragActive }) => (
                            <div {...getRootProps({ className: isDragAccept ? `myDropZone` : `` })} style={{ maxWidth: `460px`, minHeight: '300px', borderRadius: '10px', cursor: 'pointer', margin: '0 auto' }}>
                                <input {...getInputProps()} />
                                <img src={props.getValueImgPath || empty} alt="Изображение" style={{ maxWidth: `460px`, minHeight: '300px', }} />
                            </div>
                        )}
                    </Dropzone>
                </div>

                <div className='myButtonSecondary' onClick={() => props.openImageEdit(false)}>ОТМЕНА</div>
            </div>
        </Modal>
    )
}

export default Comments