import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { AppStateType } from '../../../bll/store'
import { clearDisciplines, createDisciplines, editDisciplines, getDisciplines } from '../../../bll/lkDisciplines/lkDisciplinesOperations'
import openNotification from '../../common/ui/notification'
import errorHandler from '../../../bll/errorHandler'
import Disciplines from './disciplines'

const DisciplinesContainer = (props: any) => {
    const [getIsVisibleAddEdit, setIsVisibleAddEdit] = React.useState(false) // Видимость окна создания (редактирования)
    const [getAction, setAction] = React.useState('') // Действие (создание или редактирование)

    const [getValueID, setValueID] = React.useState('') // Идентификатор дисциплины
    const [getValueName, setValueName] = React.useState('') // Наименование дисциплины в окне создания (редактирования)
    const [getValueDescription, setValueDescription] = React.useState('') // Описание дисциплины в окне создания (редактирования)

    const [getFilter, setFilter] = React.useState('') // Фильтр

    React.useEffect(() => {
        props.getDisciplines().then((result: any) => {
            switch (result?.code) {
                case 'OK': break
                case "ERR_QUERY_STRING": { return openNotification("Список дисциплин...", props.msg.err_0061, "error", "bottomRight") }
                default: errorHandler(result?.code, props.msg); break
            }
        })

        return () => {
            props.clearDisciplines()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    // Открытие / Закрытие окна создания (редактирования дисциплины)
    const openAddEdit = (isOpen: boolean, action: string = '', id: string = '', name: string = '', description: string = '') => {
        setValueID(id)
        setValueName(name)
        setValueDescription(description)
        
        setAction(action)
        setIsVisibleAddEdit(isOpen)
    }

    // Кнопка применить в окне создания (редактирования) дисциплины
    const applyButton = () => {
        if (getAction === 'add') { // Создание дисциплины
            props.createDisciplines(getValueName, getValueDescription).then((result: any) => {
                switch (result?.code) {
                    case "OK": 
                        openAddEdit(false)
                    break

                    case "ERR_QUERY_STRING": { return openNotification("Создание дисциплин...", props.msg.err_0062, "error", "bottomRight") }
                    default: errorHandler(result?.code, props.msg); break
                }
            })
        }

        if (getAction === 'edit') { // Редактирование дисциплин
            props.editDisciplines(getValueID, getValueName, getValueDescription).then((result: any) => {
                switch (result?.code) {
                    case 'OK': 
                        openAddEdit(false)
                    break

                    case "ERR_QUERY_STRING": { return openNotification("Редактирование дисциплин...", props.msg.err_0063, "error", "bottomRight") }
                    default: errorHandler(result?.code, props.msg); break
                }
            })
        }
    }


    // Свойства передаваемые в компоненту
    const propsToComponents = {
        getIsVisibleAddEdit, // Видимость окна создания (редактирования)
        getAction, // Действие (создание или редактирование)

        openAddEdit, // Открытие / Закрытие окна создания (редактирования дисциплины)
        applyButton, // Кнопка применить в окне создания (редактирования) дисциплины

        getValueName, setValueName, // Наименование дисциплины в окне создания (редактирования)
        getValueDescription, setValueDescription, // Описание дисциплины в окне создания (редактирования)

        getFilter, setFilter, // Фильтр
    }

    return (
        <Disciplines {...props} {...propsToComponents} />
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        disciplinesList: state.disciplines.disciplinesList
    }
}

export default compose(connect(mapState, { 
    getDisciplines, clearDisciplines,
    createDisciplines, editDisciplines
}))(DisciplinesContainer)