import React from 'react'
import parse from 'html-react-parser'

const Section08 = (props: any) => {
    return (
        <section id='license'>
            <article className='container CB08'>
                <h2>{parse(props.page.b08.text_1 || '')}</h2>
                <h4>{parse(props.page.b08.text_2 || '')}</h4>
                <div>{parse(props.page.b08.text_3 || '')}</div>

                <button className='buttonSecondary_2' onClick={() => props.licVisible(true)}>Просмотр лицензии</button>
            </article>
        </section>
    )
}

export default Section08