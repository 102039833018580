import React from 'react'
import parse from 'html-react-parser'

const Section06 = (props: any) => {
    return (
        <section id='author'>
            <div className='container LB06'>
                <div className='content'>
                    <img src={props.page.b06_img_1_path?.value} alt={props.page.b06_text_1?.value?.replace(/<[^>]+>/g, '')} />

                    <article>
                        <h2>{parse(props.page.b06_text_1?.value || '')}</h2>
                        <div>{parse(props.page.b06_text_2?.value || '')}</div>
                    </article>
                </div>
            </div>
        </section>
    )
}

export default Section06