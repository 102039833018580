import React from 'react'
import parse from 'html-react-parser'

import ExposureOutlinedIcon from '@mui/icons-material/ExposureOutlined'

import { Collapse } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { BootstrapTooltip } from '../../../common/ui/tooltip'

const { Panel } = Collapse

const Section10 = (props: any) => {
    return (
        <section id='program'>
            <article className='container CB05'>
                <BootstrapTooltip title="Редактировать" placement="top" arrow>
                    <h2 className='itemHover' onClick={() => props.openEditTextSecondary(true, '10', 'text_1', 'text', props.page.b10.text_1)}>
                        {parse(props.page.b10.text_1 || 'Редактировать')}
                    </h2>
                </BootstrapTooltip>

                <Collapse accordion bordered={false} className='items' expandIconPosition='end' expandIcon={({ isActive }) => <PlusOutlined style={{ color: `#FF9900`, fontSize: '26px' }} rotate={isActive ? -45 : 0} />}>
                    {!props.questionsList.length
                        ? ''
                        : props.questionsList.map((item: any) => {
                            return (
                                <Panel header={item.header} id={item.dcq_id} key={item.dcq_id} className='item'>
                                    {parse(item.text || '')}
                                </Panel>
                            )
                        })
                    }
                </Collapse>

                <section className='myPopoverVertical'>
                    <h5>Блок № 10</h5>
                    
                    <div className='dividerPrimary' style={{ height: '40px' }}></div>
    
                    <BootstrapTooltip title="Видимость блока" placement="bottom" arrow>
                        <input type="checkbox"
                            checked={props.page.b10.is_active === 1 ? true : false}
                            onChange={(e) => props.onChangeBlockActive('10', 'is_active', 'isActive', e.target.checked)}
                        />
                    </BootstrapTooltip>

                    <div className='dividerPrimary' style={{ height: '40px' }}></div>

                    <BootstrapTooltip title="Добавить / Удалить вопрос" placement="bottom" arrow>
                        <div className="myButtonIconSecondaryBorder" onClick={() => props.setOpenAddDeleteQuestions(true)}>
                            <ExposureOutlinedIcon />
                        </div>
                    </BootstrapTooltip>
                </section>
            </article>
        </section>
    )
}

export default Section10