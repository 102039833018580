import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { getOtherFiles } from '../../../bll/common/commonOperations'
import { AppStateType } from '../../../bll/store'

import Nav04 from './nav-04'

const Nav_04_Container = (props: any) => {
    const navigate = useNavigate()

    React.useEffect(() => {
        props.getOtherFiles().then((result: any) => {
            switch (result?.code) {
                case "OK":  break
                case "ERR_QUERY_STRING": navigate('/error'); break
                default:  navigate('/error'); break
            }
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <Nav04 {...props} />
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        files: state.common.otherFiles
    }
}

export default compose(connect(mapState, { getOtherFiles }))(Nav_04_Container)