import React from 'react'

import MyHeader from '../common/header/header'
import FooterContainer from '../common/footer/footerContainer'

import css from './education.module.scss'

const Education = (props: any) => {
    return (
        <>
            <MyHeader {...props} />

            <main className={`container ${css.main}`}>
                <article>
                    <h3>Подробнее об обучении</h3>
                </article>

                <div className={css.container}>
                    <span>Академия предоставляет одни из самых быстрых сроков обучения по программам переподготовки и повышения квалификации</span>

                    <h4>С нами выгодно и удобно обучаться</h4>

                    <span>Мы сочетаем в себе широкий спектр программ и проверенных технологий дистанционного обучения, а также индивидуальный подход к каждому слушателю. 13% можно вернуть в качестве налогового вычета за обучение.</span>

                    <span>✔ Поддержка куратора 24/7 - Мы всегда готовы проконсультировать по любым вопросам, на всех этапах обучения</span>
                    <span>✔ Более 8 лет опыта. Более 1 000 выданных дипломов</span>
                    <span>✔ Рассрочка без переплат вы не платите проценты за кредит и не тратите время на оформление документов для банка</span>
                    <span>✔ Индивидуальная цена для групп от 5 человек</span>
                    <span>✔ Совмещайте с работой - Дистанционное обучение без отрыва от работы</span>

                    <h4>Кому подойдет обучение</h4>
                    
                    <span><b>Специалистам для расширения своих профессиональных знаний</b></span>
                    <span>Актуальный лекционный материал, опытные преподаватели-эксперты. Престижный диплом после завершения курса.</span>

                    <span><b>Желающим сменить род деятельности</b></span>
                    <span>Если вы получили высшее образование, но не хотите работать по выбранной ранее специальности или не можете найти подходящую вакансию.</span>

                    <span><b>Корпоративным клиентам</b></span>
                    <span>Наши преподаватели находят подход к каждому слушателю. Обучение проходит в удобной форме без отрыва от работы.</span>

                    <span><b>Тем, кто постоянно развивается и ищет новые знания</b></span>
                    <span>Индивидуальный подход. Легкость обучения. Доступ к пройденным лекциям в личном кабинете.</span>

                    <h4>Схема нашей работы</h4>

                    <span><b>Формируем заявку</b></span>
                    <span>Вы заинтересованы в обучении и оставляете заявку</span>

                    <span><b>Связываемся с вами</b></span>
                    <span>Наши менеджеры связываются с Вами, уточняют текущий уровень образования и Ваши предпочтения</span>

                    <span><b>Подбираем программу</b></span>
                    <span>На основе уровня образования и предпочтений подбирают направление обучения</span>

                    <span><b>Составляем договор</b></span>
                    <span>Вы присылаете по электронной почте скан или фото необходимых документов. Специалист приемной комиссии составляет договор</span>

                    <span><b>Зачисляем на курс</b></span>
                    <span>Вы подаете документы и уже в следующем месяце приступаете к дистанционному обучению из своей страны / города. Да, зачисление ведется ежемесячно!</span>

                    <span><b>Выдаем диплом</b></span>
                    <span>Смотрите онлайн лекции, в личном кабинете проходите тесты, общаетесь с кураторами и в итоге получаете удостоверение или диплом установленного образца.</span>
                </div>
            </main>

            <FooterContainer {...props} />
        </>
    )
}

export default Education