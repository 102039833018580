import React from 'react'
// import { Image } from 'antd'

import css from './nav-12.module.scss'

const Nav_12 = (props: any) => {
    return (
        <section className={css.main}>
            <h5>Доступная среда</h5>

            <span>ОБЕСПЕЧЕНИЕ БЕСПРЕПЯТСТВЕННОГО ДОСТУПА В ЗДАНИЕ ОБРАЗОВАТЕЛЬНОЙ ОРГАНИЗАЦИИ</span>

            <div className={css.table}>
                <div className='dividerVerticalSecondary'></div>
    
                <div className={css.tRow} style={{ fontWeight: '600' }}>
                    <span id={css.center}>№</span>
                    <span>Условия для получения образования обучающимися с ограниченными возможностями здоровья</span>
                    <span id={css.center}>Наличие условий для получения образования обучающимися с ограниченными возможностями здоровья (да/нет, комментарии)</span>
                </div>

                <div className='dividerVerticalSecondary'></div>

                <div className={css.tRow}>
                    <span id={css.center}>1</span>
                    <span>Обеспечение беспрепятственного доступа обучающихся с ограниченными возможностями здоровья, имеющих нарушения опорно-двигательного аппарата, в учебные помещения и другие помещения</span>
                    <span id={css.center}>кнопка вызова при входе, пандус</span>
                </div>

                <div className='dividerVerticalSecondary'></div>

                <div className={css.tRow}>
                    <span id={css.center}>2</span>
                    <span>Предоставление услуг ассистента, оказывающего обучающимся с ограниченными возможностями здоровья необходимую техническую помощь</span>
                    <span id={css.center}>да</span>
                </div>

                <div className='dividerVerticalSecondary'></div>
            </div>

            <ul style={{ marginTop: '20px' }}>
                <li><b>Об электронных образовательных ресурсах, к которым обеспечивается доступ инвалидов и лиц с ограниченными возможностями здоровья</b></li>
                <ol>
                    <li>ЭБС</li>
                    <li>АО “Издательство “Просвещение”</li>
                </ol>
            </ul>

            <section>Доступ к информационным системам предоставлен со стационарных компьютеров, с личных компьютеров, с мобильных устройств.</section>

            <section>В образовательном процессе для обучающихся с инвалидностью и обучающихся с ОВЗ используются образовательные технологии, предусматривающие возможность приема-передачи информации в доступных для них формах.</section>

            <div className={css.table}>
                <div className='dividerVerticalSecondary'></div>

                <div className={css.tRow}>
                    <span id={css.center}>1</span>
                    <span>Наличие в образовательной организации электронной информационно-образовательной среды</span>
                    <span id={css.center}>да</span>
                </div>

                <div className='dividerVerticalSecondary'></div>

                <div className={css.tRow}>
                    <span id={css.center}>2</span>
                    <span>Общее количество ЭБС, к которым имеют доступ обучающиеся</span>
                    <span id={css.center}>3</span>
                </div>

                <div className='dividerVerticalSecondary'></div>

                <div className={css.tRow}>
                    <span id={css.center}>3</span>
                    <span>Наличие собственных электронных образовательных и информационных ресурсов</span>
                    <span id={css.center}>1</span>
                </div>

                <div className='dividerVerticalSecondary'></div>

                <div className={css.tRow}>
                    <span id={css.center}>4</span>
                    <span>Наличие сторонних электронных образовательных и информационных ресурсов</span>
                    <span id={css.center}>2</span>
                </div>

                <div className='dividerVerticalSecondary'></div>
            </div>

            <span><b>ОБЪЕКТЫ СПОРТА, ПРИСПОСОБЛЕННЫЕ ДЛЯ ИСПОЛЬЗОВАНИЯ ИНВАЛИДАМИ И ЛИЦАМИ С ОГРАНИЧЕННЫМИ ВОЗМОЖНОСТЯМИ ЗДОРОВЬЯ</b></span>

            <span>Занятия физической культурой не проводятся</span>

            <span><b>ИНФОРМАЦИЯ О НАЛИЧИИ СПЕЦИАЛЬНЫХ ТЕХНИЧЕСКИХ СРЕДСТВ ОБУЧЕНИЯ КОЛЛЕКТИВНОГО И ИНДИВИДУАЛЬНОГО ПОЛЬЗОВАНИЯ ДЛЯ ИНВАЛИДОВ И ЛИЦ С ОГРАНИЧЕННЫМИ ВОЗМОЖНОСТЯМИ ЗДОРОВЬЯ</b></span>

            <span>Для инвалидов и лиц с ограниченными возможностями здоровья открыт доступ к электронной-библиотечной системе. Библиотечный фонд располагает и учебной литературой для учебной и вне учебной работы с инвалидами и лицами с ОВЗ. Специальные учебные пособия и учебники для обучающихся с нарушениями зрения, слуха.</span>

            <span>Мультимедийные средства приема-передачи информации, которыми оборудованы специализированные аудитории, предусматривают наличие программного обеспечения альтернативного ввода информации в доступных формах для студентов с нарушениями опорно-двигательного аппарата. В работе с такими группами студентов рекомендовано использование специальных возможностей операционной системы, таких как экранная клавиатура, с помощью которой можно вводить текст, используя лишь компьютерную мышь.</span>

            <ul style={{ marginTop: '20px' }}>
                <li><b>Сведения о специально оборудованных учебных кабинетах приспособленных объектах для проведения практических занятий</b></li>
                <ol>
                    <li><b>Адрес:</b> Российская Федерация, 410002, Приволжский федеральный округ, Саратовская область, город Саратов, ул. Мичурина, д. 150/154.</li>
                    <li><b>Наименование оборудованных учебных кабинетов/объектов для проведения практических занятий:</b> Учебная аудитория № 1, 2, 3, 4 лекционного типа, занятий семинарского типа, групповых и индивидуальных консультаций, текущего контроля и промежуточной аттестации</li>
                    <li><b>Оснащенность оборудованных учебных кабинетов/объектов для проведения практических занятий:</b> Стандартная учебная мебель: Доска аудиторная; Столы; Стулья. Оборудование, технические средства обучения: Проектор; проекционный экран; Колонки; ПК. Учебно-наглядные пособия: стенды.</li>
                    <li><b>Оснащенность оборудованных учебных кабинетов/объектов для проведения практических занятий:</b> Достаточная ширина дверных проемов для проезда инвалидной коляски. Обеспечена возможность беспрепятственного доступа для слабовидящих, слабослышащих, лиц с инвалидностью по общим заболеваниям. Надлежащее размещение оборудования, для обеспечения беспрепятственного доступа к объектам. Использование электронных образовательных ресурсов, в формах, адаптированных к ограничениям здоровья.</li>
                    <li><b>Приспособленность для использования инвалидами и лицами с ограниченными возможностями здоровья:</b> Да.</li>
                </ol>
            </ul>

            <span><b>СПЕЦИАЛЬНЫЕ УСЛОВИЯ ПИТАНИЯ</b></span>
            <span>Отсутствуют</span>

            <span><b>СПЕЦИАЛЬНЫЕ УСЛОВИЯ ОХРАНЫ ЗДОРОВЬЯ</b></span>
            <span>Отсутствует</span>

            <span><b>О НАЛИЧИИ УСЛОВИЙ ДЛЯ БЕСПРЕПЯТСТВЕННОГО ДОСТУПА В ОБЩЕЖИТИИ, ИНТЕРНАТ</b></span>
            <span>Общежития и интерната нет</span>

            <span><b>О КОЛИЧЕСТВЕ ЖИЛЫХ ПОМЕЩЕНИЙ В ИНТЕРНАТЕ, ПРИСПОСОБЛЕННЫХ ДЛЯ ИСПОЛЬЗОВАНИЯ ИНВАЛИДАМИ И ЛИЦАМИ С ОГРАНИЧЕННЫМИ ВОЗМОЖНОСТЯМИ ЗДОРОВЬЯ</b></span>
            <span>Общежития и интерната нет</span>
        </section>
    )
}

export default Nav_12