import api from './api'

export default class ApiIndex {

    // Получение основных контактных данных о школе
    static async getSettings(): Promise<any> {
        try {
            return await api.get('/common/getSettings')
        } catch (error) {
            return error
        }
    }

    // Получение прочих файлов
    static async getOtherFiles(): Promise<any> {
        try {
            return await api.get('/common/getOtherFiles')
        } catch (error) {
            return error
        }
    }

    // Получение списка всех отзывов
    static async getCommentsAll(): Promise<any> {
        try {
            return await api.get('/common/getCommentsAll')
        } catch (error) {
            return error
        }
    }



    // Получение данных по главной странице
    static async getLandingData(): Promise<any> {
        try {
            return await api.get('/common/getLandingData')
        } catch (error) {
            return error
        }
    }
    
    // Получение списка курсов (Страница - Лэндинг)
    static async getCourses(): Promise<any> {
        try {
            return await api.get('/common/getCourses')
        } catch (error) {
            return error
        }
    }

    // Получение списка отзывов (Страница - Лэндинг)
    static async getCommentsLanding(): Promise<any> {
        try {
            return await api.get(`/common/getCommentsLanding`)
        } catch (error) {
            return error
        }
    }
    
    // Создание вопроса клиента (Страница - Лэндинг)
    static async createQuestionLanding(clientName: string, clientPhone: string): Promise<any> {
        try {
            return await api.post(`/common/createQuestionLanding?clientName=${clientName}&clientPhone=${clientPhone}`)
        } catch (error) {
            return error
        }
    }



    // Получение данных по курсу
    static async getCourseData(courseID: string): Promise<any> {
        try {
            return await api.get(`/common/getCourseData?courseID=${courseID}`)
        } catch (error) {
            return error
        }
    }
    
    // Получение списка програм (Страница - Курса)
    static async getPrograms(courseID: string): Promise<any> {
        try {
            return await api.get(`/common/getPrograms?courseID=${courseID}`)
        } catch (error) {
            return error
        }
    }

    // Получение списка отзывов (Страница - Курса)
    static async getCommentsCourse(courseID: string): Promise<any> {
        try {
            return await api.get(`/common/getCommentsCourse?courseID=${courseID}`)
        } catch (error) {
            return error
        }
    }

    // Получение списка вопросов (Страница - Курса)
    static async getQuestions(courseID: string): Promise<any> {
        try {
            return await api.get(`/common/getQuestions?courseID=${courseID}`)
        } catch (error) {
            return error
        }
    }
    
    // Создание вопроса клиентом (Страница - Курса)
    static async createQuestionCourse(courseID: string, clientName: string, clientPhone: string): Promise<any> {
        try {
            return await api.post(`/common/createQuestionCourse?courseID=${courseID}&clientName=${clientName}&clientPhone=${clientPhone}`)
        } catch (error) {
            return error
        }
    }

    // Создание заявки клиентом (Страница - Курса)
    static async createApplicationCourse(courseID: string, clientName: string, clientPhone: string, packageName: string): Promise<any> {
        try {
            return await api.post(`/common/createApplicationCourse?courseID=${courseID}&clientName=${clientName}&clientPhone=${clientPhone}&packageName=${packageName}`)
        } catch (error) {
            return error
        }
    }

}