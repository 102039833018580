import React from 'react'

import Nav01 from './nav-01'

const Nav_01_Container = (props: any) => {
    return (
        <Nav01 {...props} />
    )
}

export default Nav_01_Container