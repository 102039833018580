import React from 'react'
import { Navigate, NavLink, Route, Routes } from 'react-router-dom'

import css from './organization.module.scss'

import OrganizationHeader from './organizationHeader'
import FooterContainer from '../common/footer/footerContainer'

import Nav01Container from './nav-01/nav-01_Container'
import Nav02Container from './nav-02/nav-02_Container'
import Nav03Container from './nav-03/nav-03_Container'
import Nav04Container from './nav-04/nav-04_Container'
import Nav05Container from './nav-05/nav-05_Container'
import Nav06Container from './nav-06/nav-06_Container'
import Nav07Container from './nav-07/nav-07_Container'
import Nav08Container from './nav-08/nav-08_Container'
import Nav09Container from './nav-09/nav-09_Container'
import Nav10Container from './nav-10/nav-10_Container'
import Nav11Container from './nav-11/nav-11_Container'
import Nav12Container from './nav-12/nav-12_Container'
import Nav13Container from './nav-13/nav-13_Container'

const Organization = (props: any) => {
    return (
        <>
            <OrganizationHeader {...props} />

            <main className={`container ${css.main}`}>
                <article>
                    <h3>Сведения об образовательной организации</h3>
                </article>

                <div className={css.container}>
                    <aside className={css.nav}>
                        <NavLink id='nav-01' to="osnovnye-svedeniya" className={({isActive}) => (isActive ? css.linkActive : '')}>
                            Основные сведения
                        </NavLink>
                        
                        <NavLink id='nav-02' to="struktura-i-organy-upravleniya" className={({isActive}) => (isActive ? css.linkActive : '')}>
                            Структура и органы управления
                        </NavLink>

                        <NavLink id='nav-03' to="dokumenty" className={({isActive}) => (isActive ? css.linkActive : '')}>
                            Документы
                        </NavLink>
                        
                        <NavLink id='nav-04' to="obrazovanie" className={({isActive}) => (isActive ? css.linkActive : '')}>
                            Образование
                        </NavLink>
                        
                        <NavLink id='nav-05' to="obrazovatelnye-standarty" className={({isActive}) => (isActive ? css.linkActive : '')}>
                            Образовательные стандарты
                        </NavLink>
                        
                        <NavLink id='nav-06' to="rukovodstvo-pedagogicheskij-nauchno-pedagogicheskij-sostav" className={({isActive}) => (isActive ? css.linkActive : '')}>
                            Руководство. Педагогический (научно-педагогический) состав
                        </NavLink>
                        
                        <NavLink id='nav-07' to="materialno-tekhnicheskoe-obespechenie-i-osnashchennost-obrazovatelnogo-protsessa" className={({isActive}) => (isActive ? css.linkActive : '')}>
                            Материально-техническое обеспечение и оснащенность образовательного процесса
                        </NavLink>
                        
                        <NavLink id='nav-08' to="stipendii-i-inye-vidy-materialnoj-podderzhki" className={({isActive}) => (isActive ? css.linkActive : '')}>
                            Стипендии и иные виды материальной поддержки
                        </NavLink>
                        
                        <NavLink id='nav-09' to="platnye-obrazovatelnye-uslugi" className={({isActive}) => (isActive ? css.linkActive : '')}>
                            Платные образовательные услуги
                        </NavLink>
                        
                        <NavLink id='nav-10' to="finansovo-khozyajstvennaya-deyatelnost" className={({isActive}) => (isActive ? css.linkActive : '')}>
                            Финансово-хозяйственная деятельность
                        </NavLink>
                        
                        <NavLink id='nav-11' to="vakantnye-mesta-dlya-priema-perevoda" className={({isActive}) => (isActive ? css.linkActive : '')}>
                            Вакантные места для приёма (перевода)
                        </NavLink>
                        
                        <NavLink id='nav-12' to="dostupnaya-sreda" className={({isActive}) => (isActive ? css.linkActive : '')}>
                            Доступная среда
                        </NavLink>
                        
                        <NavLink id='nav-13' to="mezhdunarodnoe-sotrudnichestvo" className={({isActive}) => (isActive ? css.linkActive : '')}>
                            Международное сотрудничество
                        </NavLink>
                    </aside>

                    <>
                        <Routes>
                            <Route path='/' element={<Navigate to="osnovnye-svedeniya" />} />
                            
                            <Route path='/osnovnye-svedeniya' element={<Nav01Container />} />
                            <Route path='/struktura-i-organy-upravleniya' element={<Nav02Container />} />
                            <Route path='/dokumenty' element={<Nav03Container />} />
                            <Route path='/obrazovanie' element={<Nav04Container />} />
                            <Route path='/obrazovatelnye-standarty' element={<Nav05Container />} />
                            <Route path='/rukovodstvo-pedagogicheskij-nauchno-pedagogicheskij-sostav' element={<Nav06Container />} />
                            <Route path='/materialno-tekhnicheskoe-obespechenie-i-osnashchennost-obrazovatelnogo-protsessa' element={<Nav07Container />} />
                            <Route path='/stipendii-i-inye-vidy-materialnoj-podderzhki' element={<Nav08Container />} />
                            <Route path='/platnye-obrazovatelnye-uslugi' element={<Nav09Container />} />
                            <Route path='/finansovo-khozyajstvennaya-deyatelnost' element={<Nav10Container />} />
                            <Route path='/vakantnye-mesta-dlya-priema-perevoda' element={<Nav11Container />} />
                            <Route path='/dostupnaya-sreda' element={<Nav12Container />} />
                            <Route path='/mezhdunarodnoe-sotrudnichestvo' element={<Nav13Container />} />
                        </Routes>
                    </>
                </div>
            </main>

            <FooterContainer {...props} />
        </>
    )
}

export default Organization