import React from 'react'

import Nav02 from './nav-02'

const Nav_02_Container = (props: any) => {
    return (
        <Nav02 {...props} />
    )
}

export default Nav_02_Container