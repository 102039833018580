import axios from 'axios'

import { API_URL } from '../../api/api'
import api from '../../api/apiAuth'
import openNotification from '../../components/common/ui/notification'
import actions from './authActions'

// Авторизация оператора
export const auth = (login: string, password: string) => async (dispatch: any) => {
    const responce = await api.login(login, password)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                localStorage.setItem('token', responce.data.apiToken)
                dispatch(actions.setIsAuthorized(true))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Проверка авторизации оператора
export const checkAuth = () => async (dispatch: any) => {
    const responce = await axios.get<any>(`${API_URL}/auth/checkAuth`, { headers: { "API_KEY": localStorage['token'] }, withCredentials: true })

    switch (responce.data.statusCode) {
        case 'OK': // Все ОК
            dispatch(actions.setIsAuthorized(true))
            return { code: 'OK' }

        default: return { code: responce.data.statusCode }
    }
}

// Выход оператора из приложения
export const logout = () => async (dispatch: any) => {
    const responce = await api.logout()
    
    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                localStorage.removeItem('token')
                dispatch(actions.setLogout())
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Получение данных профиля оператора
export const getMyProfile = () => async (dispatch: any) => {
    const responce = await api.getMyProfile()

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.setIsAuthorized(true))
                dispatch(actions.setLoginProfile(
                    responce.data.login, responce.data.lastName, responce.data.firstName, responce.data.secondName,
                    responce.data.accountCreateDate, responce.data.loginAvatar
                ))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Загрузка аватарки пользователя
// export const uploadAvatar = (file: any) => async (dispatch: any) => {
//     const responce = await api.uploadAvatar(file)

//     if (!responce.code) {
//         switch (responce.data.statusCode) {
//             case 'OK': // Все ОК
//                 dispatch(getMyProfile())
//                 return { code: 'OK' }

//             default: return { code: responce.data.statusCode }
//         }
//     } else {
//         return { code: "ERR_NETWORK" } // API сервер не доступен
//     }
// }

// Изменение профиля оператора
// export const editMyProfile = (lastName: string, firstName: string, secondName: string, position: string, phoneNumber: string) => async (dispatch: any) => {
//     const responce = await api.editMyProfile(lastName, firstName, secondName, position, phoneNumber)

//     if (!responce.code) {
//         switch (responce.data.statusCode) {
//             case 'OK': // Все ОК
//                 dispatch(getMyProfile())
//                 return { code: 'OK' }

//             default: return { code: responce.data.statusCode }
//         }
//     } else {
//         return { code: "ERR_NETWORK" } // API сервер не доступен
//     }
// }

export const appInitialized = (navigate: any, msg: any) => (dispatch: any) => {
    let promGetMyProfile = dispatch(getMyProfile())

    Promise.all([
        promGetMyProfile, // Загрузка профиля оператора
    ]).then((result) => {
        let criticalErr: boolean = false

        switch (result[0]?.code) {
            case "ERR_NETWORK":
            case "ERR_UNEXPECTED":
            case "ERR_SERVER_AVAILABLE":
                criticalErr = true
                openNotification("Сервер не доступен...", msg.err_0001, "error", "bottomRight")
                break

            case "ERR_NOT_AUTHORIZED":
                criticalErr = true
                openNotification("Вы не авторизованы...", msg.err_0002, "error", "bottomRight")
                break
        }

        if (!criticalErr) {
            if (result[0]?.code === "NULL") { openNotification("Сервер не ответил...", msg.err_code, "error", "bottomRight") } // Сервер не ответил
            if (result[0]?.code === "ERR_QUERY_STRING") { openNotification("Ошибка в запросе...", msg.err_0004, "error", "bottomRight") } // Ошибка в запросе

            dispatch(actions.setAppInitialized(true))
        } else {
            navigate('/login')
            dispatch(logout())
        }
    })
}