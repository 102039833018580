import types from './commonTypes'

let initialState = {
    settings: { // Основные контактные данные о школе
        logo: '', 
        phone: '', 
        email:'', 
        vk: '', 
        telegram: ''
    },

    otherFiles: [], // Прочие файлы
    commentsAll: [], // Все отзывы
    
    landingData: [], // Данные по главной странице
    coursesList: [], // Список курсов
    commentsLandingList: [], // Список отзывов общий

    programList: [], // Список програм
    commentsCourseList: [], // Список отзывов по курсу
    questionsList: [], // Список вопросов
    
    courseData: { // Данные по курсу
        course_id: '',
        head_text: '',
        head_translit: '',
        sum_1: '',
        sum_2: '',
        sum_3: '',
        discount: '',
        b01: {},
        b02: {},
        b03: {},
        b04: {},
        b05: {},
        b06: {},
        b07: {},
        b08: {},
        b09: {},
        b10: {},
        b11: {}
    }, 
}

const commonReducer = (state = initialState, action: any) => {
    switch (action.type) {

        // Получение основных контактных данных о школе
        case types.COMMON_SET_SETTINGS: {
            return {
                ...state,
                settings: {
                    ...state.settings,
                    logo: action.logo,
                    phone: action.phone,
                    email:action.email,
                    vk: action.vk,
                    telegram: action.telegram
                }
            }
        }

        // Получение прочих файлов
        case types.COMMON_SET_OTHER_FILES: {
            return {
                ...state,
                otherFiles: action.items
            }
        }

        // Получение списка всех отзывов
        case types.COMMON_SET_COMMENTS_ALL: {
            return {
                ...state,
                commentsAll: action.items
            }
        }

        // Получение данных по главной странице
        case types.COMMON_SET_LANDING_DATA: {
            return {
                ...state,
                landingData: action.items
            }
        }

        // Получение списка курсов
        case types.COMMON_SET_COURSES: {
            return {
                ...state,
                coursesList: action.items
            }
        }

        // Получение списка отзывов общий
        case types.COMMON_SET_COMMENTS_LANDING: {
            return {
                ...state,
                commentsLandingList: action.items
            }
        }
        
        // Получение списка програм
        case types.COMMON_SET_PROGRAM: {
            return {
                ...state,
                programList: action.items
            }
        }

        // Получение списка отзывов по курсу
        case types.COMMON_SET_COMMENTS_COURSE: {
            return {
                ...state,
                commentsCourseList: action.items
            }
        }

        // Получение списка вопросов
        case types.COMMON_SET_QUESTIONS: {
            return {
                ...state,
                questionsList: action.items
            }
        }

        // Получение данных по курсу
        case types.COMMON_SET_COURSE_DATA: {
            return {
                ...state,
                courseData: {
                    ...state.courseData,
                    course_id: action.course_id,
                    head_text: action.head_text,
                    head_translit: action.head_translit,
                    sum_1: action.sum_1,
                    sum_2: action.sum_2,
                    sum_3: action.sum_3,
                    discount: action.discount,
                    b01: action.b01,
                    b02: action.b02,
                    b03: action.b03,
                    b04: action.b04,
                    b05: action.b05,
                    b06: action.b06,
                    b07: action.b07,
                    b08: action.b08,
                    b09: action.b09,
                    b10: action.b10,
                    b11: action.b11
                }
            }
        }
        
        default: return state
    }
}

export default commonReducer