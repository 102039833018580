import React from 'react'
import parse from 'html-react-parser'

import { BootstrapTooltip } from '../../../common/ui/tooltip'

const Section06 = (props: any) => {
    function priceWithSpaces(x: number) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    return (
        <section id='formats'>
            <article className='container CB06'>
                <BootstrapTooltip title="Редактировать" placement="top" arrow>
                    <h2 className='itemHover' onClick={() => props.openEditTextSecondary(true, '06', 'text_1', 'text', props.page.b06.text_1)}>
                        {parse(props.page.b06.text_1 || 'Редактировать')}
                    </h2>
                </BootstrapTooltip>

                <div className='items'>
                    <article className='item itemBorderColor_1'>
                        <BootstrapTooltip title="Редактировать" placement="top" arrow>
                            <h4 className='itemHover' onClick={() => props.openEditTextSecondary(true, '06', 'text_2', 'text', props.page.b06.text_2)}>
                                {parse(props.page.b06.text_2 || 'Редактировать')}
                            </h4>
                        </BootstrapTooltip>

                        <BootstrapTooltip title="Редактировать" placement="top-end" arrow>
                            <div className='description itemHover' onClick={() => props.openEditTextSecondary(true, '06', 'text_3', 'text', props.page.b06.text_3)}>
                                {parse(props.page.b06.text_3 || 'Редактировать')}
                            </div>
                        </BootstrapTooltip>

                        <div className='price'>
                            <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                                <span className='header itemHover' onClick={() => props.openEditTextPrimary(true, '06', 'text_4', 'text', props.page.b06.text_4 || '')}>
                                    {parse(props.page.b06.text_4 || 'Редактировать')}
                                </span>
                            </BootstrapTooltip>
                            
                            <span className='sumMain'>{priceWithSpaces(props.page.sum_1)} ₽</span>
                            {props.page.discount > 0 && <span className='withoutDisctount'>{priceWithSpaces(Math.round(props.page.sum_1 / (100 - props.page.discount)) * 100)} ₽ без скидки</span>}
                            {props.page.discount > 0 && <span className='discount'>-{priceWithSpaces((Math.round(props.page.sum_1 / (100 - props.page.discount)) * 100) - props.page.sum_1)} ₽ скидка</span>}
                        </div>

                        <button className='buttonCourse'>Принять участие</button>
                    </article>
                    
                    <article className='item itemBorderColor_2'>
                        <BootstrapTooltip title="Редактировать" placement="top" arrow>
                            <h4 className='itemHover' onClick={() => props.openEditTextSecondary(true, '06', 'text_5', 'text', props.page.b06.text_5)}>
                                {parse(props.page.b06.text_5 || 'Редактировать')}
                            </h4>
                        </BootstrapTooltip>

                        <BootstrapTooltip title="Редактировать" placement="top-end" arrow>
                            <div className='description itemHover' onClick={() => props.openEditTextSecondary(true, '06', 'text_6', 'text', props.page.b06.text_6)}>
                                {parse(props.page.b06.text_6 || 'Редактировать')}
                            </div>
                        </BootstrapTooltip>

                        <div className='price'>
                            <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                                <span className='header itemHover' onClick={() => props.openEditTextPrimary(true, '06', 'text_7', 'text', props.page.b06.text_7 || '')}>
                                    {parse(props.page.b06.text_7 || 'Редактировать')}
                                </span>
                            </BootstrapTooltip>

                            <span className='sumMain'>{priceWithSpaces(props.page.sum_2)} ₽</span>
                            {props.page.discount > 0 && <span className='withoutDisctount'>{priceWithSpaces(Math.round(props.page.sum_2 / (100 - props.page.discount)) * 100)} ₽ без скидки</span>}
                            {props.page.discount > 0 && <span className='discount'>-{priceWithSpaces((Math.round(props.page.sum_2 / (100 - props.page.discount)) * 100) - props.page.sum_2)} ₽ скидка</span>}
                        </div>

                        <button className='buttonCourse'>Принять участие</button>
                    </article>

                    <article className='item itemBorderColor_3'>
                        <BootstrapTooltip title="Редактировать" placement="top" arrow>
                            <h4 className='itemHover' onClick={() => props.openEditTextSecondary(true, '06', 'text_8', 'text', props.page.b06.text_8)}>
                                {parse(props.page.b06.text_8 || 'Редактировать')}
                            </h4>
                        </BootstrapTooltip>

                        <BootstrapTooltip title="Редактировать" placement="top-end" arrow>
                            <div className='description itemHover' onClick={() => props.openEditTextSecondary(true, '06', 'text_9', 'text', props.page.b06.text_9)}>
                                {parse(props.page.b06.text_9 || 'Редактировать')}
                            </div>
                        </BootstrapTooltip>

                        <div className='price'>
                            <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                                <span className='header itemHover' onClick={() => props.openEditTextPrimary(true, '06', 'text_10', 'text', props.page.b06.text_10 || '')}>
                                    {parse(props.page.b06.text_10 || 'Редактировать')}
                                </span>
                            </BootstrapTooltip>

                            <span className='sumMain'>{priceWithSpaces(props.page.sum_3)} ₽</span>
                            {props.page.discount > 0 && <span className='withoutDisctount'>{priceWithSpaces(Math.round(props.page.sum_3 / (100 - props.page.discount)) * 100)} ₽ без скидки</span>}
                            {props.page.discount > 0 && <span className='discount'>-{priceWithSpaces((Math.round(props.page.sum_3 / (100 - props.page.discount)) * 100) - props.page.sum_3)} ₽ скидка</span>}
                        </div>

                        <button className='buttonCourse'>Принять участие</button>
                    </article>
                </div>

                <section className='myPopoverVertical'>
                    <h5>Блок № 6</h5>
                    
                    <div className='dividerPrimary' style={{ height: '40px' }}></div>
    
                    <BootstrapTooltip title="Видимость блока" placement="bottom" arrow>
                        <input type="checkbox"
                            checked={props.page.b06.is_active === 1 ? true : false}
                            onChange={(e) => props.onChangeBlockActive('06', 'is_active', 'isActive', e.target.checked)}
                        />
                    </BootstrapTooltip>
                </section>
            </article>
        </section>
    )
}

export default Section06