import React from 'react'
import parse from 'html-react-parser'
import { NavLink } from 'react-router-dom'

const Section09 = (props: any) => {
    return (
        <section>
            <div className='container CB09' style={{ background: `${props.page.b09.bg_color} url(${props.page.b09.bg_img_path}) no-repeat center/100% 100%` }}>
                <article className='content'>
                    <h2>{parse(props.page.b09.text_1 || '')}</h2>

                    <div className='items'>
                        {!props.commentsCourseList.length
                            ? ''
                            : props.commentsCourseList.map((item: any) => {
                                return (
                                    <div className='item' key={item.dcc_id}>
                                        <img src={item.img_path} alt={item.header} />

                                        <article>
                                            <h4>{item.header}</h4>
                                            {parse(item.text || '')}
                                        </article>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <NavLink to='/comments' className='buttonSecondary_2' target='_blank'>Смотреть все отзывы</NavLink>
                </article>
            </div>
        </section>
    )
}

export default Section09