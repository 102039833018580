let initialState = {
    inf_0001: "Вы не ввели <b>Логин оператора</b> и <b>Пароль оператора</b> для входа в личный кабинет.",
    inf_0002: "Не верный логин или пароль.",
    // inf_0003: "Вы действительно хотите выйти из системы?",
    
    // success_0001: "<b>Отзыв успешно удален.</b>",
    // success_0002: "<b>Вопрос успешно удален.</b>",
    // success_0003: "<b>Дисциплина успешно удалена.</b>",
    // success_0004: "<b>Курс успешно клонирован.</b>",
    // success_0005: "<b>Файл успешно удалён.</b>",

    // warn_0001: "У Вас отсутствует доступ к разделу УМКД.",
    // warn_0002: "У Вас отсутствует доступ к разделу Администрирование.",
    // warning_0001: "Необходимо выбрать дисциплину из списка.",
    warning_0002: "Необходимо ввести имя курса.",
    // warning_0003: "Необходимо выбрать отзыв из списка.",
    // warning_0004: "Необходимо выбрать вопрос из списка.",
    // warning_0005: "Данный отзыв используется в курсах. Удалить его не возможно, для начала удалите его из курса.",
    // warning_0006: "Данный вопрос используется в курсах. Удалить его не возможно, для начала удалите его из курса.",
    // warning_0007: "Данная дисцплина используется в курсах. Удалить её не возможно, для начала удалите её из курса.",
    // warning_0008: "Необходимо выбрать курс из списка.",

    // warning_0002: "Вы не указали <b>логин</b> оператора.",
    // warning_0003: "Пароль не должен быть <b>меньше восьми символов</b>, пароль должен содержать <b>специальные символы</b>, <b>цифры</b>, </b>маленькие и заглавные</b> латинские буквы.",
    // warning_0004: "Вы не указали <b>фамилию</b> оператора.",
    // warning_0005: "Вы не указали <b>имя</b> оператора.",

    // err_api: "Код ошибки: API. </br> Ваш ключ приложения более не валидный. Попробуйте перезайти.",
    err_code: "Код ошибки NULL_CODE. </br> Сервер по не предвиденной причине не ответил.",

    err_0001: "Код ошибки: 0001. </br> Сервер временно не доступен.",
    err_0002: "Код ошибки: 0002. </br> Вы не авторизованны в системе. Приложение будет работать не корректно. Пожалуйста переавторизуйтесь.",
    err_0003: "Код ошибки: 0003. </br> Дублирование записи, такая запись уже существует.",
    err_0004: "Код ошибки: 0004. </br> Ошибка в запросе при инициализации приложения.",
    err_0005: "Код ошибки: 0005. </br> Ошибка в запросе, при проверке оператора.",
    
    // err_0010: "Код ошибки: 0010. </br> Ошибка загрузки курсов.",
    // err_0020: "Код ошибки: 0020. </br> Ошибка загрузки данных по странице.",
    // err_0012: "Код ошибки: 0012. </br> Приносим свои извинения, но при регистрации вопроса, произошла ошибка.",
    // err_0013: "Код ошибки: 0013. </br> Данный курс не найден. Обновите страницу.",
    // err_0014: "Код ошибки: 0014. </br> Приносим свои извинения, но при регистрации заявки, произошла ошибка.",
    
    err_0050: "Код ошибки: 0050. </br> Ошибка в запросе, при выходе из приложения.",
    err_0051: "Код ошибки: 0051. </br> Ошибка в запросе, при загрузке курсов.",
    err_0052: "Код ошибки: 0052. </br> Ошибка в запросе, при загрузке информации о курсе.",
    err_0053: "Код ошибки: 0053. </br> Ошибка в запросе, при редактировании информации о курсе.",
    err_0054: "Код ошибки: 0054. </br> Ошибка в запросе, при загрузке файла на сервер.",
    err_0055: "Код ошибки: 0055. </br> Файл не найден.",
    err_0056: "Код ошибки: 0056. </br> Не допустимое расширение файла. Разрешены файлы с расширением <b>.jpeg</b> <b>.jpg</b> <b>.png</b>",
    err_0057: "Код ошибки: 0057. </br> Файл не должен быть больше 2 мегабайт.",
    err_0058: "Код ошибки: 0058. </br> Ошибка в запросе, при изменении активности курса.",
    // err_0059: "Код ошибки: 0059. </br> Ошибка в запросе, при загрузке данные о странице курса.",
    err_0060: "Код ошибки: 0060. </br> Ошибка в запросе, при изменении данных страницы курса.",
    err_0061: "Код ошибки: 0061. </br> Ошибка в запросе, при получении списка дисциплин.",
    err_0062: "Код ошибки: 0062. </br> Ошибка в запросе, при создании дисциплин.",
    err_0063: "Код ошибки: 0063. </br> Ошибка в запросе, при редактировании дисциплин.",
    // err_0064: "Код ошибки: 0064. </br> Ошибка в запросе, при добавлении дисциплины.",
    // err_0065: "Код ошибки: 0065. </br> Ошибка в запросе, при удалении дисциплины.",
    // err_0066: "Код ошибки: 0066. </br> Ошибка в запросе, при изменении данных дисциплины.",
    // err_0067: "Код ошибки: 0067. </br> Ошибка в запросе, при удалении данных дисциплины.",
    err_0068: "Код ошибки: 0068. </br> Ошибка в запросе, при изменении активности блока.",
    err_0069: "Код ошибки: 0069. </br> Ошибка в запросе, при получении списка отзывов.",
    err_0070: "Код ошибки: 0070. </br> Ошибка в запросе, при создании отзыва.",
    err_0071: "Код ошибки: 0071. </br> Ошибка в запросе, при редактировании отзыва.",
    err_0072: "Код ошибки: 0072. </br> Ошибка в запросе, при создании курса.",
    // err_0073: "Код ошибки: 0073. </br> Ошибка в запросе, при добавлении отзыва.",
    // err_0074: "Код ошибки: 0074. </br> Ошибка в запросе, при удалении отзыва.",
    err_0075: "Код ошибки: 0075. </br> Ошибка в запросе, при получении списка вопросов.",
    err_0076: "Код ошибки: 0076. </br> Ошибка в запросе, при создании вопроса.",
    err_0077: "Код ошибки: 0077. </br> Ошибка в запросе, при редактировании вопроса.",
    // err_0078: "Код ошибки: 0078. </br> Ошибка в запросе, при добавлении вопроса.",
    // err_0079: "Код ошибки: 0079. </br> Ошибка в запросе, при удалении вопроса.",
    // err_0080: "Код ошибки: 0080. </br> Ошибка в запросе, при удалении изображения отзыва.",
    // err_0081: "Код ошибки: 0081. </br> Ошибка в запросе, при удалении отзыва.",
    // err_0082: "Код ошибки: 0082. </br> Ошибка в запросе, при удалении вопроса.",
    // err_0083: "Код ошибки: 0083. </br> Ошибка в запросе, при удалении дисциплины.",
    // err_0084: "Код ошибки: 0084. </br> Ошибка в запросе, при клонировании курса.",
    // err_0085: "Код ошибки: 0085. </br> Ошибка в запросе, при редактировании данных.",
    // err_0086: "Код ошибки: 0086. </br> Ошибка в запросе, при удалении данных.",
    err_0087: "Код ошибки: 0087. </br> Ошибка в запросе, при получении списка файлов.",
    err_0088: "Код ошибки: 0088. </br> Ошибка в запросе, при создании файла.",
    // err_0089: "Код ошибки: 0089. </br> Ошибка в запросе, при редактировании файла.",
    // err_0090: "Код ошибки: 0090. </br> Ошибка в запросе, при удалении файла.",
    err_0091: "Код ошибки: 0091. </br> Ошибка в запросе, при получении справочника типы разделов.",
    err_0092: "Код ошибки: 0092. </br> Файл не должен быть больше 10 мегабайт.",
    err_0093: "Код ошибки: 0093. </br> Ошибка в запросе при добавлении программы в курс.",
    err_0094: "Код ошибки: 0094. </br> Ошибка в запросе при удалении программы из курса.",
    err_0095: "Код ошибки: 0095. </br> Ошибка в запросе при получении программ.",
    err_0096: "Код ошибки: 0096. </br> Ошибка в запросе при получении отзывов.",
    err_0097: "Код ошибки: 0097. </br> Ошибка в запросе при добавлении отзыва в курс.",
    err_0098: "Код ошибки: 0098. </br> Ошибка в запросе при удалении отзыва из курса.",
    err_0099: "Код ошибки: 0099. </br> Ошибка в запросе при получении вопросов.",
    err_0100: "Код ошибки: 0100. </br> Ошибка в запросе при добавлении вопроса в курс.",
    err_0101: "Код ошибки: 0101. </br> Ошибка в запросе при удалении вопроса из курса.",
}

const messagesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        default: return state
    }
}

export default messagesReducer