import React from 'react'

import css from './nav-02.module.scss'

const Nav_02 = (props: any) => {
    return (
        <section className={css.main}>
            <h5>Структура и органы управления</h5>

            <p>
                <b>Учредитель:</b> Кармазин Виталий Юрьевич.
            </p>

            <p>
                <b>Генеральный директор:</b> Кармазин Виталий Юрьевич.
            </p>

            <p>
                <b>Телефон:</b> <a href="tel:8 800 888 88 88">8 800 888 88 88</a>
            </p>

            <p>
                <b>Электронный адрес:</b> <a href="mailto:info@apsitis.ru">info@apsitis.ru</a><br/>
            </p>

            <p>
                <b>Адрес регистрации:</b> Российская Федерация, 410069, Приволжский федеральный округ, Саратовская область, г. Саратов, ул. Куприянова, д. 7А, оф. 16.
            </p>

            <p>
                <b>Адрес места осуществления образовательной деятельности:</b> Российская Федерация, 410002, Приволжский федеральный округ, Саратовская область, город Саратов, ул. Мичурина, д. 150/154.
            </p>
        </section>
    )
}

export default Nav_02