import api from './../../api/apiLkDisciplines'
import actions from './lkDisciplinesActions'

// Получение списка дисциплин
export const getDisciplines = () => async (dispatch: any) => {
    const responce = await api.getDisciplines()

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.setDisciplinesList(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Создание дисциплины
export const createDisciplines = (disciplineName: string, disciplineDescription: string) => async (dispatch: any) => {
    const responce = await api.createDisciplines(disciplineName, disciplineDescription)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getDisciplines())
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Редактирование дисциплины
export const editDisciplines = (disciplineID: string, disciplineName: string, disciplineDescription: string) => async (dispatch: any) => {
    const responce = await api.editDisciplines(disciplineID, disciplineName, disciplineDescription)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getDisciplines())
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}



// Очистка списка дисциплин
export const clearDisciplines = () => async (dispatch: any) => {
    dispatch(actions.setDisciplinesList([]))
}