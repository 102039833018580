const LKCOURSES_SET_COURSES_LIST = 'LKCOURSES_SET_COURSES_LIST'
const LKCOURSES_SET_COURSE_INFO = 'LKCOURSES_SET_COURSE_INFO'
const LKCOURSES_SET_PROGRAMS = 'LKCOURSES_SET_PROGRAMS'
const LKCOURSES_SET_COMMENTS = 'LKCOURSES_SET_COMMENTS'
const LKCOURSES_SET_QUESTIONS = 'LKCOURSES_SET_QUESTIONS'

const lkCoursesTypes = {
    LKCOURSES_SET_COURSES_LIST,
    LKCOURSES_SET_COURSE_INFO,
    LKCOURSES_SET_PROGRAMS,
    LKCOURSES_SET_COMMENTS,
    LKCOURSES_SET_QUESTIONS
}

export default lkCoursesTypes