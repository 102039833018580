import types from './lkCoursesTypes'
import typesLogin from '../auth/authTypes'

let initialState = {
    coursesList: [], // Список курсов
    programsList: [], // Список программ
    commentsList: [], // Список отзывов
    questionsList: [], // Список вопросов

    courseInfo: { // Информация о курсе
        course_id: '',
        img_path: '',
        img_name: '',
        author: '',
        head_text: '',
        head_translit: '',
        title_text: '',
        duration: '',
        value_hours: '',
        date_start: '',
        is_active: '',
        sum_1: '',
        sum_2: '',
        sum_3: '',
        discount: '',
        date_time_create: '',
    },
}

const coursesReducer = (state = initialState, action: any) => {
    switch (action.type) {

        // Выход из системы
        case typesLogin.AUTH_SET_LOGOUT: {
            return {
                ...state = initialState
            }
        }
        
        // Получение курсов
        case types.LKCOURSES_SET_COURSES_LIST: {
            return {
                ...state,
                coursesList: action.items
            }
        }

        // Получение информации о курсе (Редактирование курса)
        case types.LKCOURSES_SET_COURSE_INFO: {
            return {
                ...state,
                courseInfo: {
                    ...state.courseInfo,
                    course_id: action.course_id,
                    img_path: action.img_path,
                    img_name: action.img_name,
                    author: action.author,
                    head_text: action.head_text,
                    head_translit: action.head_translit,
                    title_text: action.title_text,
                    duration: action.duration,
                    value_hours: action.value_hours,
                    date_start: action.date_start,
                    is_active: action.is_active,
                    sum_1: action.sum_1,
                    sum_2: action.sum_2,
                    sum_3: action.sum_3,
                    discount: action.discount,
                    date_time_create: action.date_time_create,
                }
            }
        }

        // Получение списка не добавленных и добавленных программ (Редактирование курса - Страница Контент курса)
        case types.LKCOURSES_SET_PROGRAMS: {
            return {
                ...state,
                programsList: action.items
            }
        }

        // Получение списка не добавленных и добавленных отзывов (Редактирование курса - Страница Контент курса)
        case types.LKCOURSES_SET_COMMENTS: {
            return {
                ...state,
                commentsList: action.items
            }
        }

        // Получение списка не добавленных и добавленных вопросов (Редактирование курса - Страница Контент курса)
        case types.LKCOURSES_SET_QUESTIONS: {
            return {
                ...state,
                questionsList: action.items
            }
        }

        default: return state
    }
}

export default coursesReducer