import React from 'react'
import parse from 'html-react-parser'

const Section02 = (props: any) => {
    return (
        <section>
            <div className='container CB02'>
                {props.page.b02.img_1_path && <img src={props.page.b02.img_1_path} alt={props.page.b02.text_1?.replace(/<[^>]+>/g, '')} />}

                <article>
                    <h2>{parse(props.page.b02.text_1 || '')}</h2>
                    <div>{parse(props.page.b02.text_2 || '')}</div>
                </article>
            </div>
        </section>
    )
}

export default Section02