import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { AppStateType } from '../../../bll/store'
import errorHandler from '../../../bll/errorHandler'
import openNotification from '../../common/ui/notification'
import { auth, checkAuth } from '../../../bll/auth/authOperations'
import Login from './login'

const LoginContainer = (props: any) => {
    const [getIsPasswordVisible, setIsPasswordVisible] = React.useState(false) // Установка видимости пароля
    const [getValueLogin, setValueLogin] = React.useState("") // Значение поля Логин оператора
    const [getValuePassword, setValuePassword] = React.useState("") // Значение поля Пароль оператора

    React.useEffect(() => {
        if (localStorage.getItem('token')) {
            props.checkAuth() // Проверка авторизации оператора
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Кнопка Вход в систему
    const onClickButtonInput = () => {
        if (getValueLogin && getValuePassword) {
            props.auth(getValueLogin, getValuePassword).then((result: any) => {
                switch (result?.code) {
                    case "ERR_QUERY_STRING": { return openNotification("В доступе отказано...", props.msg.err_0005, "error", "bottomRight") }
                    case "ERR_OPERATOR_NOT_FOUND": { return openNotification("В доступе отказано...", props.msg.inf_0002, "info", "bottomRight") }

                    default:  errorHandler(result?.code, props.msg); break
                }
            })
        } else {
            openNotification("Ошибка авторизации...", props.msg.inf_0001, "info", "bottomRight")
        }
    }

    // При нажатии кнопки Enter на поле пароль, вход в систему
    const onKeyPressPassword = (event: any) => {
        if (event.key === 'Enter') {
            onClickButtonInput()
        }
    }

    // Свойства передаваемые в компоненту Login
    const propsToLogin = {
        getIsPasswordVisible, setIsPasswordVisible, // Установка видимости пароля

        getValueLogin, setValueLogin, // Значение поля Логин оператора
        getValuePassword, setValuePassword, // Значение поля Пароль оператора

        onClickButtonInput, // Кнопка Вход в систему
        onKeyPressPassword, // При нажатии кнопки Enter на поле пароль, Вход в систему
    }


    if (props.login.isAuthorized) {
        return <Navigate to="/login/lk" />
    }

    return (
        <Login {...props} {...propsToLogin} />
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        login: state.auth,
    }
}

export default compose(connect(mapState, { auth, checkAuth }))(LoginContainer)