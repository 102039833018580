import React from 'react'
import parse from 'html-react-parser'

import { Image } from 'antd'

import CourseHeader from './courseHeader'
import FooterContainer from '../common/footer/footerContainer'
import Section01 from './section_01'
import Section02 from './section_02'
import Section03 from './section_03'
import Section04 from './section_04'
import Section05 from './section_05'
import Section06 from './section_06'
import Section07 from './section_07'
import Section08 from './section_08'
import Section09 from './section_09'
import Section10 from './section_10'
import Section11 from './section_11'
import { Modal } from 'rsuite'

const Course = (props: any) => {
    const [visible, setVisible] = React.useState(false) // Открыто или закрыто окно, просмотр лицензии

    return (
        <>
            <CreateApplication {...props} />
            <AnswerClient {...props} />

            <CourseHeader {...props} />
            {props.page.b01.is_active === 1 ? <Section01 {...props} /> : ''} {/* Основной блок */}
            {props.page.b02.is_active === 1 ? <Section02 {...props} /> : ''} {/* О курсе */}
            {props.page.b03.is_active === 1 ? <Section03 {...props} /> : ''} {/* Кому подойдет этот курс */}
            {props.page.b04.is_active === 1 ? <Section04 {...props} /> : ''} {/* Чему вы научитесь */}
            {props.page.b05.is_active === 1 ? <Section05 {...props} /> : ''} {/* Программа курса */}
            {props.page.b06.is_active === 1 ? <Section06 {...props} /> : ''} {/* Форматы обучения */}
            {props.page.b07.is_active === 1 ? <Section07 {...props} /> : ''} {/* Как проходит обучение */}
            {props.page.b08.is_active === 1 ? <Section08 {...props} licVisible={setVisible} /> : ''} {/* Лицензия */}
            {props.page.b09.is_active === 1 ? <Section09 {...props} /> : ''} {/* Отзывы */}
            {props.page.b10.is_active === 1 ? <Section10 {...props} /> : ''} {/* Вопросы */}
            {props.page.b11.is_active === 1 ? <Section11 {...props} /> : ''} {/* Вопрос */}
            <FooterContainer {...props} />

            <div style={{ display: 'none' }}>
                <Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis) }}>
                    {!props.files.license ? 'УПС' : props.files.license.map((item: any) => {
                        return (
                            <Image key={item.file_id} src={item.file_path} />
                        )
                    })}
                </Image.PreviewGroup>
            </div>
        </>
    )
}

// Регистрация заявки клиентом
const CreateApplication = (props: any) => {
    return (
        <Modal overflow={true} backdrop="static" size="sm" autoFocus={false} open={props.getIsOpenApplication} className='myModalCourse'>
            <div className="modalContent">
                <div className="modalHead">
                    Заявка на {props.getValuePackageName}
                </div>

                <div className="modalBody">
                    <input id='b1' type="text" placeholder='Ваше имя' className='txtSecondary' autoComplete='off'
                        value={props.getValueName} onChange={e => props.setValueName(e.target.value)}
                    />

                    <input id='b2' type="text" placeholder='Ваш телефон' className='txtSecondary' autoComplete='off'
                        value={props.getValuePhone} onChange={e => props.changeNumberValue(e.target.value)}
                    />

                    <div id='createApplication'>
                        Нажимая на кнопку «Отправить», я соглашаюсь на <a href={props.files.PERSONAL_DATA?.file_path} target='_blank' rel="noreferrer">обработку персональных данных</a> и <a href={props.files.USER_AGREEMENT?.file_path} target='_blank' rel="noreferrer">пользовательским соглашением</a>
                    </div>
                </div>

                <div className='modalFooter'>
                    <div className='buttonSecondary_2' onClick={() => props.openApplication(false)}>ОТМЕНА</div>
                    <div className='buttonPrimary' onClick={() => props.sendApplication()}>ОТПРАВИТЬ</div>
                </div>
            </div>
        </Modal>
    )
}

// Окно с ответом клиенту
const AnswerClient = (props: any) => {
    return (
        <Modal overflow={true} backdrop="static" size="sm" autoFocus={false} open={props.getIsOpenAnswer} className='myModalCourse'>
            <div className="modalContent">
                <div className="modalBody contentAnswer">
                    <div>{parse(props.getValueMessage || '')}</div>
                </div>

                <div className='buttonSecondary_2' style={{ width: '100%' }} onClick={() => props.setIsOpenAnswer(false)}>ЗАКРЫТЬ</div>
            </div>
        </Modal>
    )
}

export default Course