import React from 'react'

import { Modal } from 'rsuite'

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'

import { BootstrapTooltip } from '../../common/ui/tooltip'
import CourseInfoContainer from './info/courseInfoContainer'
import CourseContentContainer from './content/courseContentContainer'

import css from './courses.module.scss'

const Courses = (props: any) => {
    return (
        <>
            <CreateCourse {...props} />

            <CourseInfoContainer {...props} />
            <CourseContentContainer {...props} />

            <div className={css.main}>
                <header className={css.header}>
                    <h3>Курсы школы</h3>

                    <aside>
                        <div className='myInputTextPrimary' style={{ width: '350px' }} onClick={() => props.setFilter('')}>
                            <SearchOutlinedIcon />

                            <input type='text' placeholder='Фильтр...' autoComplete='off'
                                value={props.getFilter} onChange={e => props.setFilter(e.target.value)}
                            />

                            {props.getFilter 
                                ? <BootstrapTooltip title="Очистить" placement="bottom" arrow>
                                    <div className='myButtonIconPrimaryBorder'>
                                        <HighlightOffOutlinedIcon sx={{ fontSize: "24px" }} />
                                    </div>
                                </BootstrapTooltip> 
                                : ''
                            }
                        </div>

                        <div className='dividerPrimary' style={{ height: '40px', margin: 'auto 0' }}></div>

                        {/* <div className='myButtonIconTextPrimarySmall'>
                            <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_229_700)">
                                    <path d="M23.8333 6.11166L11.4725 18.4833L6.87913 13.89L8.40663 12.3625L11.4725 15.4283L22.3058 4.59499L23.8333 6.11166ZM13 22.1667C8.22246 22.1667 4.33329 18.2775 4.33329 13.5C4.33329 8.72249 8.22246 4.83332 13 4.83332C14.7008 4.83332 16.2933 5.33166 17.6366 6.18749L19.2075 4.61666C17.4416 3.39249 15.3075 2.66666 13 2.66666C7.01996 2.66666 2.16663 7.51999 2.16663 13.5C2.16663 19.48 7.01996 24.3333 13 24.3333C14.8741 24.3333 16.64 23.8567 18.1783 23.0117L16.5533 21.3867C15.47 21.885 14.2675 22.1667 13 22.1667ZM20.5833 16.75H17.3333V18.9167H20.5833V22.1667H22.75V18.9167H26V16.75H22.75V13.5H20.5833V16.75Z" fill="currentColor" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_229_700">
                                        <rect width="26" height="26" fill="currentColor" transform="translate(0 0.5)" />
                                    </clipPath>
                                </defs>
                            </svg>

                            <span>Клонировать</span>
                        </div> */}

                        <div className='myButtonIconTextPrimarySmall' onClick={() => props.setIsOpenCreateCourse(true)}>
                            <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_229_703)">
                                    <path d="M14.0833 8.08332H11.9166V12.4167H7.58329V14.5833H11.9166V18.9167H14.0833V14.5833H18.4166V12.4167H14.0833V8.08332ZM13 2.66666C7.01996 2.66666 2.16663 7.51999 2.16663 13.5C2.16663 19.48 7.01996 24.3333 13 24.3333C18.98 24.3333 23.8333 19.48 23.8333 13.5C23.8333 7.51999 18.98 2.66666 13 2.66666ZM13 22.1667C8.22246 22.1667 4.33329 18.2775 4.33329 13.5C4.33329 8.72249 8.22246 4.83332 13 4.83332C17.7775 4.83332 21.6666 8.72249 21.6666 13.5C21.6666 18.2775 17.7775 22.1667 13 22.1667Z" fill="currentColor" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_229_703">
                                        <rect width="26" height="26" fill="currentColor" transform="translate(0 0.5)" />
                                    </clipPath>
                                </defs>
                            </svg>

                            <span>Создать</span>
                        </div>
                    </aside>
                </header>

                <main className={css.content}>
                    <section className={css.tHead}>
                        <span id={css.uid}></span>
                        <span id={css.uid}>Активен</span>
                        <span id={css.name}>Название курса</span>
                        <span id={css.uid}>Дата создания</span>
                        <span id={css.btn}></span>
                    </section>

                    <section className={css.tBody}>
                        {!props.coursesList.length
                            ? ''
                            : props.coursesList.filter((val: any) => {
                                let stringReturn

                                let head_text = val.head_text.toLowerCase().includes(props.getFilter.toLowerCase())

                                if (head_text) {
                                    stringReturn = val
                                }

                                return stringReturn
                            }).map((item: any) => {
                                return (
                                    <div key={item.course_id} className={css.tRow}>
                                        <span id={css.uid}>{item.course_id}</span>
                                        <span id={css.uid}>{item.is_active === 1 ? 'Да' : 'Нет'}</span>
                                        <span id={css.name}>{item.head_text}</span>
                                        <span id={css.uid}>{item.date_time_create}</span>
                                        <div id={css.btn}>
                                            <BootstrapTooltip title="О курсе" placement="bottom" arrow>
                                                <div className='myButtonIconPrimaryBorder' onClick={() => props.openCourseInfo(true, item.course_id)}>
                                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clipPath="url(#clip0_232_722)">
                                                            <path d="M3.25 18.6875V22.75H7.3125L19.2942 10.7683L15.2317 6.70583L3.25 18.6875ZM22.4358 7.62666C22.8583 7.20416 22.8583 6.52166 22.4358 6.09916L19.9008 3.56416C19.4783 3.14166 18.7958 3.14166 18.3733 3.56416L16.3908 5.54666L20.4533 9.60916L22.4358 7.62666Z" fill="currentColor" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_232_722">
                                                                <rect width="26" height="26" fill="currentColor" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </div>
                                            </BootstrapTooltip>

                                            <BootstrapTooltip title="Контент" placement="bottom" arrow>
                                                <div className='myButtonIconPrimaryBorder' onClick={() => props.openCourseContent(true, item.course_id)}>
                                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.5625 17.875C12.3574 17.875 13.8125 16.4199 13.8125 14.625C13.8125 12.8301 12.3574 11.375 10.5625 11.375C8.76757 11.375 7.3125 12.8301 7.3125 14.625C7.3125 16.4199 8.76757 17.875 10.5625 17.875Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M5.42344 21.125C5.88462 20.1524 6.61234 19.3306 7.52205 18.7551C8.43175 18.1797 9.48607 17.8742 10.5625 17.8742C11.6389 17.8742 12.6933 18.1797 13.603 18.7551C14.5127 19.3306 15.2404 20.1524 15.7016 21.125H21.9375C22.153 21.125 22.3597 21.0394 22.512 20.887C22.6644 20.7347 22.75 20.528 22.75 20.3125V5.6875C22.75 5.47201 22.6644 5.26535 22.512 5.11298C22.3597 4.9606 22.153 4.875 21.9375 4.875H4.0625C3.84701 4.875 3.64035 4.9606 3.48798 5.11298C3.3356 5.26535 3.25 5.47201 3.25 5.6875V20.3125C3.25 20.528 3.3356 20.7347 3.48798 20.887C3.64035 21.0394 3.84701 21.125 4.0625 21.125H5.42344Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M17.875 17.875H19.5V8.125H6.5V9.75" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </div>
                                            </BootstrapTooltip>
                                        </div>
                                    </div>
                                )
                            })

                        }
                    </section>
                </main>
            </div>
        </>
    )
}

// Создание курса
const CreateCourse = (props: any) => {
    return (
        <Modal backdrop="static" size="sm" autoFocus={false} open={props.getIsOpenCreateCourse} className='myModalPrimary'>
            <div className="modalContent">
                <div className="modalHead" style={{ display: 'grid', gridTemplateColumns: '1fr auto' }}>
                    Создание курса...
                </div>

                <div className="modalBody">
                    <div className='myInputTextPrimary'>
                        <input type='text' placeholder='Введите название курса' autoComplete='off'
                            value={props.getValueCourseName} onChange={e => props.setValueCourseName(e.target.value)}
                        />
                    </div>
                </div>

                <div className="modalFooter">
                    <div className='myButtonSecondary' onClick={() => { props.setIsOpenCreateCourse(false); props.setValueCourseName('') }}>ОТМЕНА</div>
                    <div className='myButtonPrimary' onClick={() => props.applyCreateCourse()}>СОЗДАТЬ</div>
                </div>
            </div>
        </Modal>
    )
}

export default Courses