import React from 'react'
import { Navigate, NavLink, Route, Routes } from 'react-router-dom'

import css from './articles.module.scss'

import ArticlesHeader from './articlesHeader'
import FooterContainer from '../common/footer/footerContainer'
import Nav01Container from './nav-01/nav-01_Container'
import Nav02Container from './nav-02/nav-02_Container'

const Articles = (props: any) => {
    return (
        <>
            <ArticlesHeader {...props} />

            <main className={`container ${css.main}`}>
                <article>
                    <h3>О психологии</h3>
                </article>

                <div className={css.container}>
                    <aside className={css.nav}>
                        <NavLink id='nav-01' to="nachalo-psihologii" className={({ isActive }) => (isActive ? css.linkActive : '')}>
                            Начало психологии
                        </NavLink>

                        <NavLink id='nav-02' to="raznoobrazie-psihologii" className={({ isActive }) => (isActive ? css.linkActive : '')}>
                            Разнообразие психологии
                        </NavLink>
                    </aside>

                    <>
                        <Routes>
                            <Route path='/' element={<Navigate to="nachalo-psihologii" />} />
                            
                            <Route path='/nachalo-psihologii' element={<Nav01Container />} />
                            <Route path='/raznoobrazie-psihologii' element={<Nav02Container />} />
                        </Routes>
                    </>
                </div>
            </main>

            <FooterContainer {...props} />
        </>
    )
}

export default Articles