import api from './../../api/apiLkComments'
import actions from './lkCommentsActions'

// Получение отзывов
export const getComments = () => async (dispatch: any) => {
    const responce = await api.getComments()

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.setCommentsList(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Создание отзывов
export const createComments = (commentHeader: string, commentText: string) => async (dispatch: any) => {
    const responce = await api.createComments(commentHeader, commentText)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getComments())
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Редактирование отзывов
export const editComments = (commentID: string, commentHeader: string, commentText: string) => async (dispatch: any) => {
    const responce = await api.editComments(commentID, commentHeader, commentText)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getComments())
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Загрузка файла
export const uploadFile = (file: any, commentID: number, previousFileName: string, fileName: string) => async (dispatch: any) => {
    const responce = await api.uploadFile(file, commentID, previousFileName, fileName)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getComments())
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}



// Очистка списка комментариев
export const clearComments = () => async (dispatch: any) => {
    dispatch(actions.setCommentsList([]))
}