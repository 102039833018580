const AUTH_SET_LOGOUT = 'AUTH_SET_LOGOUT'
const AUTH_SET_IS_AUTHORIZED = 'AUTH_SET_IS_AUTHORIZED'
const AUTH_SET_MY_PROFILE = 'AUTH_SET_MY_PROFILE'
const AUTH_SET_APP_INITIALIZED = 'AUTH_SET_APP_INITIALIZED'

const authTypes = {
    AUTH_SET_LOGOUT,
    AUTH_SET_IS_AUTHORIZED,
    AUTH_SET_MY_PROFILE,
    AUTH_SET_APP_INITIALIZED
}

export default authTypes