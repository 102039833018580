import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Loader } from 'rsuite'

import { AppStateType } from '../../../bll/store'
import errorHandler from '../../../bll/errorHandler'
import openNotification from '../../common/ui/notification'
import { appInitialized, logout } from '../../../bll/auth/authOperations'
import MainLk from './mainLk'

const MainLkContainer = (props: any) => {
    const navigate = useNavigate()

    const [getIsVisibleCloseApp, setIsVisibleCloseApp] = React.useState(false) // Показывать окно выхода из приложения или нет

    React.useEffect(() => {
        props.appInitialized(navigate, props.msg)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Функция выхода из приложения
    const closeApp = () => {
        props.logout(navigate).then((result: any) => {            
            switch (result?.code) {
                case "OK": navigate('/login'); break
                case "ERR_QUERY_STRING": openNotification("Ошибка в запросе...", props.msg.err_0050, "error", "bottomRight"); break

                default: errorHandler(result?.code, props.msg); break
            }
        })

        setIsVisibleCloseApp(false)
    }

    // Свойства передаваемые в компоненту Main
    const propsToLoginLk = {
        closeApp, // Функция выхода из приложения
        getIsVisibleCloseApp, setIsVisibleCloseApp, // Метка, показывать окно выхода из приложения или нет
    }

    return (
        <>
            {!props.appInitialized
                ? <Loader backdrop size="md" content="Загрузка данных..." vertical />
                : <MainLk {...props} {...propsToLoginLk} />
            }
        </>
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        appInitialized: state.auth.isAppInitialized,
        myProfile: state.auth.myProfile,
    }
}

export default compose(connect(mapState, { appInitialized, logout }))(MainLkContainer)