import types from './lkCoursesTypes'

const setCoursesList = (items: []) => ({ type: types.LKCOURSES_SET_COURSES_LIST, items })
const setProgramsList = (items: []) => ({ type: types.LKCOURSES_SET_PROGRAMS, items })
const setCommentsList = (items: []) => ({ type: types.LKCOURSES_SET_COMMENTS, items })
const setQuestionsList = (items: []) => ({ type: types.LKCOURSES_SET_QUESTIONS, items })

const setCourseInfo = (
    course_id: '', img_path: '', img_name: '', author: '', head_text: '', head_translit: '',
    title_text: '', duration: '', value_hours: '', date_start: '', is_active: '',
    sum_1: '', sum_2: '', sum_3: '', discount: '', date_time_create: ''
) => ({
    type: types.LKCOURSES_SET_COURSE_INFO, course_id,
    img_path, img_name, author, head_text, head_translit,
    title_text, duration, value_hours, date_start, is_active,
    sum_1, sum_2, sum_3, discount, date_time_create
})

const lkCoursesActions = {
    setCoursesList,
    setCourseInfo,
    setProgramsList,
    setCommentsList,
    setQuestionsList
}

export default lkCoursesActions