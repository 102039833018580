import React from 'react'
import { NavLink } from 'react-router-dom'

import css from './nav-01.module.scss'

const Nav_01 = (props: any) => {
    return (
        <section className={css.main}>
            <h5>Начало психологии</h5>

            <p>
                Психология для меня — это живая материя. Если ты дышишь, значит и психология в тебе. Давайте разберёмся и вспомним, когда возникла психология - как наука. Считается, что как наука, психология возникла в 1879 году, Вильгемом Вундотом в Германии.
            </p>

            <p>
                На сегодняшний день, в области психологии очень много направлений, а это обозначает, что психология постоянно развивается и у людей есть потребность в науке. Мое мнение, что психологию необходимо внедрять во все общеобразовательные учреждения. Потому что, благодаря психологическим методам и различным способам, детям было бы легче понимать и воспринимать этот сложный и интересный мир. У большинства семей есть проблемы не только с воспитанием детей, но и с восприятием детского мира. Я считаю, что, если ввести психологию, как предмет, в школу, детские психологи помогли бы многим семьям выявить явные проблемы внутри семье, не допуская большие кризисы в будущем. 
            </p>

            <p>
                Мы все знаем, что, когда появляются дети, они рождаются с темпераментом, а характер формируется со временем. Дети еще не понимают, что правильно, а что нет. Дети рождаются без привычек, их привычки и поведение формируются окружающим миром, но прежде всего родителями. А если бы наши родители изучали психологию со школы у детей было бы меньше детских травм. Детские травмы – это серьезные проблемы, которые иногда приводят к серьезным последствиям.
            </p>

            <p>
                Многие родители изучают детскую психологию по различным источникам и пытаются сами решить проблемы в собственных семьях, но забывают о том, что каждый случай уникален. Бывает и такое, что некомпетентный родитель, иногда пробуя различные варианты выстраиваемая отношения с ребенком, допускает ряд ошибок, после которых у родителя и ребенка усугубляются межличностные отношения.
            </p>

            <p>
                На сегодняшний день люди стали больше обращаться к психологам, потому что начинают понимать, что теперь тебя не будут осуждать за то, что ты обратился за помощью к специалисту. Если есть проблема, то ее нужно решать быстро и своевременно. И если человек решает проблемы у психолога, это говорит о том, что человек не только ценит свою жизнь, но и время. И если в семье нет каких-то явных проблем, это не говорит, о том, что психология не важна, психология важна в нашей жизни и везде.
            </p>

            <p>
                Если Вас заинтересовали эти направления - можете пройти обучение по программе <NavLink to='/course/psikhologiya-dlya-nachinayushchikh' target='_blank'>«Психология для начинающих»</NavLink>
            </p>
        </section>
    )
}

export default Nav_01