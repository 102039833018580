import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Markup } from 'interweave'
import { useNavigate } from 'react-router-dom'

import { notification } from 'antd'

import Icon from '@mdi/react'
import { mdiWindowClose } from '@mdi/js'

import { AppStateType } from '../../bll/store'

import Landing from './landing'
import { createQuestionLanding, landingInitialized } from '../../bll/common/commonOperations'

const LandingContainer = (props: any) => {
    const navigate = useNavigate()

    const [getIsVisibleMenu, setIsVisibleMenu] = React.useState(false) // Видимость меню
    
    const [getIsVisibleAnswerClient, setIsVisibleAnswerClient] = React.useState(false) // Видимость ответа клиенту, при отправке вопроса
    const [getValueMessage, setValueMessage] = React.useState('') // Сообщение клиенту


    const [getValueName, setValueName] = React.useState('') // Имя клиента
    const [getValuePhone, setValuePhone] = React.useState('') // Телефон клиента

    React.useEffect(() => {
        props.landingInitialized()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    
    const openMsg = (
        title: string, message: string,
        type: 'info' | 'success' | 'warning' | 'error', 
        placement: 'topLeft' | 'top' | 'topRight' | 'bottomLeft' | 'bottom' | 'bottomRight') => {
        
        notification[type]({
            message: title,
            description: <Markup content={message} />,
            placement: placement,
            duration: 10,
            closeIcon: <Icon path={mdiWindowClose} size={0.8} />,
            style: { background: '#303B44', boxShadow: '0 0 10px 6px rgba(0, 0, 0, .6)' }
        })
    }
    

    // Кнопка отправить, в окне задать вопрос
    const sendQuestion = () => {
        let validateError = false

        if (getValueName.length === 0) {
            validateError = true
            openMsg("Ваше имя...", 'Чтобы задать вопрос, необходимо ввести <b>Ваше имя</b>.', "info", "bottomRight")
        }

        if (getValuePhone.length < 6) {
            validateError = true
            openMsg("Ваш телефон...", 'Чтобы задать вопрос, необходимо ввести <b>Ваш телефон</b>.', "info", "bottomRight")
        }

        if (!validateError) {
            props.createQuestionLanding(getValueName, getValuePhone).then((result: any) => {
                switch (result?.code) {
                    case 'OK': 
                        setValueMessage(`Спасибо за обращение <b>${getValueName}</b>!<br/>Наш оператор свяжется с Вами.`)
                        setIsVisibleAnswerClient(true)
                    break

                    case "ERR_TIME_LIMIT": 
                        setValueMessage(`<b>${getValueName}</b> повторить отправку <b>Вашего</b> вопроса возможно, только через 10 минут.`)
                        setIsVisibleAnswerClient(true)
                    break

                    case "ERR_QUERY_STRING": navigate('/error'); break
                    default: navigate('/error'); break
                }
            })
        }
    }

    // Редактирование цифровых полей с проверкой
    const changeNumberValue = (value: string) => {
        const re = /^[0-9\b]+$/
        if (value === '' || re.test(value)) {
            setValuePhone(value)
        }
    }

    // Свойства передаваемые в компоненту
    const propsToComponent = {
        getIsVisibleMenu, setIsVisibleMenu, // Видимость меню

        getIsVisibleAnswerClient, // Видимость ответа клиенту, при отправке вопроса
        getValueMessage, // Сообщение клиенту

        sendQuestion, // Кнопка отправить, в окне задать вопрос

        getValueName, setValueName, // Имя клиента
        getValuePhone, changeNumberValue, // Телефон клиента
    }

    return (
        <Landing {...props} {...propsToComponent} />
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        page: state.common.landingData,
        coursesList: state.common.coursesList,
        commentsLandingList: state.common.commentsLandingList,
        setting: state.common.settings,
        files: state.common.otherFiles
    }
}

export default compose(connect(mapState, { landingInitialized, createQuestionLanding }))(LandingContainer)