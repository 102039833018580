import React from 'react'
import parse from 'html-react-parser'

import WallpaperOutlinedIcon from '@mui/icons-material/WallpaperOutlined'
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined'

import { BootstrapTooltip } from '../../../common/ui/tooltip'

const Section11 = (props: any) => {
    return (
        <section id='question'>
            <div className='container CB11' style={{ background: `${props.page.b11.bg_color} url(${props.page.b11.bg_img_path}) no-repeat center/100% 100%` }}>
                <div className='content'>
                    <header>
                        <BootstrapTooltip title="Редактировать" placement="top" arrow>
                            <h2 className='itemHover' onClick={() => props.openEditTextSecondary(true, '11', 'text_1', 'text', props.page.b11.text_1)}>
                                {parse(props.page.b11.text_1 || 'Редактировать')}
                            </h2>
                        </BootstrapTooltip>
                        
                        <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                            <div className='comment itemHover' onClick={() => props.openEditTextSecondary(true, '11', 'text_2', 'text', props.page.b11.text_2)}>
                                {parse(props.page.b11.text_2 || 'Редактировать')}
                            </div>
                        </BootstrapTooltip>
                    </header>

                    <div className='form'>
                        <input type="text" placeholder='Ваше имя' className='txtPrimary' />
                        <input type="text" placeholder='Ваш телефон' className='txtPrimary' />
                        <button className='buttonCourse' style={{ borderRadius: '20px' }}>Отправить</button>

                        <div id='b4'>
                            Нажимая на кнопку «Отправить», я соглашаюсь на <a href={props.files.PERSONAL_DATA?.file_path} target='_blank' rel="noreferrer">обработку персональных данных</a> и <a href={props.files.USER_AGREEMENT?.file_path} target='_blank' rel="noreferrer">пользовательским соглашением</a>
                        </div>

                    </div>
                </div>


                <section className='myPopoverVertical'>
                    <h5>Блок № 11</h5>
                    
                    <div className='dividerPrimary' style={{ height: '40px' }}></div>

                    <BootstrapTooltip title="Видимость блока" placement="bottom" arrow>
                        <input type="checkbox"
                            checked={props.page.b11.is_active === 1 ? true : false}
                            onChange={(e) => props.onChangeBlockActive('11', 'is_active', 'isActive', e.target.checked)}
                        />
                    </BootstrapTooltip>

                    {/* <BootstrapTooltip title="Редактировать высоту изображение заднего фона" placement="left" arrow>
                        <div className='myButtonIconSecondaryBorder' onClick={() => props.openEditTextNumber(true, '01', 'b1_bg_img_height', 'bg_img_size', props.b1.bg?.img_height)}>
                            <HeightOutlinedIcon />
                        </div>
                    </BootstrapTooltip> */}

                    {/* <BootstrapTooltip title="Редактировать ширину изображение заднего фона" placement="left" arrow>
                        <div className='myButtonIconSecondaryBorder' onClick={() => props.openEditTextNumber(true, '01', 'b1_bg_img_width', 'bg_img_size', props.b1.bg?.img_width)}>
                            <MultipleStopOutlinedIcon />
                        </div>
                    </BootstrapTooltip> */}

                    {/* <BootstrapTooltip title="Редактировать позицию изображение заднего фона" placement="left" arrow>
                        <div className='myButtonIconSecondaryBorder' onClick={() => props.openEditPosition(true, '01', 'b1_bg_img_position', 'position', props.b1.bg?.img_position)}>
                            <PictureInPictureAltOutlinedIcon />
                        </div>
                    </BootstrapTooltip> */}

                    <div className='dividerPrimary' style={{ height: '40px' }}></div>

                    <BootstrapTooltip title="Редактировать изображение заднего фона" placement="bottom" arrow>
                        <div className="myButtonIconSecondaryBorder" onClick={() => props.openEditImage(true, '11', 'bg_img_path', 'image', props.page.b11.bg_img_path, props.page.b11.bg_img_name)}>
                            <WallpaperOutlinedIcon />
                        </div>
                    </BootstrapTooltip>

                    <BootstrapTooltip title="Редактировать задний фон блока" placement="bottom" arrow>
                        <div className="myButtonIconSecondaryBorder" onClick={() => props.openEditColor(true, '11', 'bg_color', 'color_background', props.page.b11.bg_color)}>
                            <PaletteOutlinedIcon />
                        </div>
                    </BootstrapTooltip>
                </section>
            </div>
        </section>
    )
}

export default Section11