const COMMON_SET_SETTINGS = 'COMMON_SET_SETTINGS'
const COMMON_SET_OTHER_FILES = 'COMMON_SET_OTHER_FILES'
const COMMON_SET_COMMENTS_ALL = 'COMMON_SET_COMMENTS_ALL'

const COMMON_SET_LANDING_DATA = 'COMMON_SET_LANDING_DATA'
const COMMON_SET_COURSES = 'COMMON_SET_COURSES'
const COMMON_SET_COMMENTS_LANDING = 'COMMON_SET_COMMENTS_LANDING'

const COMMON_SET_PROGRAM = 'COMMON_SET_PROGRAM'
const COMMON_SET_COMMENTS_COURSE = 'COMMON_SET_COMMENTS_COURSE'
const COMMON_SET_QUESTIONS = 'COMMON_SET_QUESTIONS'
const COMMON_SET_COURSE_DATA = 'COMMON_SET_COURSE_DATA'

const commonTypes = {
    COMMON_SET_SETTINGS,
    COMMON_SET_OTHER_FILES,
    COMMON_SET_COMMENTS_ALL,

    COMMON_SET_LANDING_DATA,
    COMMON_SET_COURSES,
    COMMON_SET_COMMENTS_LANDING,
    
    COMMON_SET_PROGRAM,
    COMMON_SET_COMMENTS_COURSE,
    COMMON_SET_QUESTIONS,
    COMMON_SET_COURSE_DATA
}

export default commonTypes