import React from 'react'
import parse from 'html-react-parser'
import Calendar from 'react-calendar'
import Dropzone from 'react-dropzone'
import SunEditor from 'suneditor-react'

import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'

import { Drawer, Modal } from 'rsuite'

import calendar from './../../../../css/img/calendar.svg'
import clock from './../../../../css/img/clock.svg'

import { BootstrapTooltip } from '../../../common/ui/tooltip'
import css from './courseInfo.module.scss'

const CourseInfo = (props: any) => {
    let courseAuthorRef = React.createRef<HTMLInputElement>()
    let courseNameRef = React.createRef<HTMLInputElement>()
    let courseDuration = React.createRef<HTMLInputElement>()
    let courseValueHours = React.createRef<HTMLInputElement>()
    let courseDateStart = React.createRef<HTMLInputElement>()
    let courseSum1 = React.createRef<HTMLInputElement>()
    let courseSum2 = React.createRef<HTMLInputElement>()
    let courseSum3 = React.createRef<HTMLInputElement>()
    let courseDiscount = React.createRef<HTMLInputElement>()

    return (
        <>
            <SelectDate {...props} />

            <Drawer backdrop='static' size='full' autoFocus={true} open={props.getIsOpenCourseInfo} style={{ maxWidth: '1542px' }}>
                <div className={css.main}>
                    <header className={css.header}>
                        <h4>Информация о курсе</h4>

                        <BootstrapTooltip title="Закрыть" placement="bottom" arrow>
                            <div className='myButtonIconPrimaryBorder' onClick={() => props.openCourseInfo(false)}>
                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_239_32)">
                                        <path d="M20.5833 6.94417L19.0558 5.41667L13 11.4725L6.94413 5.41667L5.41663 6.94417L11.4725 13L5.41663 19.0558L6.94413 20.5833L13 14.5275L19.0558 20.5833L20.5833 19.0558L14.5275 13L20.5833 6.94417Z" fill="currentColor" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_239_32">
                                            <rect width="26" height="26" fill="currentColor" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </BootstrapTooltip>
                    </header>

                    <main className={css.content}>
                        <section className={css.contentData}>

                            {/* Активность курса */}
                            <div className={css.courseActive}>
                                <span>Активность курса:</span>
                                <input type="checkbox"
                                    checked={props.courseInfo.is_active ? true : false}
                                    onChange={(e) => props.changeCourseActive(e.target.checked)}
                                />
                            </div>

                            <div className='dividerVerticalSecondary'></div>

                            {/* Автор курса */}
                            <div className={css.txtItem}>
                                <span className={css.itemHead}>Автор курса</span>
                                <div className='myInputTextPrimary' onClick={() => courseAuthorRef.current?.focus()}>
                                    <input ref={courseAuthorRef} type='text'
                                        placeholder='Автор курса' autoComplete='off' readOnly={!props.getIsEditableCourseAuthor}
                                        value={props.getValueCourseAuthor || ''} onChange={e => props.setValueCourseAuthor(e.target.value)}
                                    />

                                    <div onClick={(e) => { props.setIsEditableCourseAuthor(!props.getIsEditableCourseAuthor) }} style={{ margin: 'auto 0' }}>
                                        {props.getIsEditableCourseAuthor
                                            ? <BootstrapTooltip title="Применить изменения" placement="bottom" arrow>
                                                <div className='myButtonIconPrimaryBorder' onClick={() => props.changesFields('author')}>
                                                    <div className='circle'>
                                                        <div className='red-medium-circle'>
                                                            <DoneAllOutlinedIcon sx={{ fontSize: "24px" }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </BootstrapTooltip>

                                            : <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                                                <div className='myButtonIconPrimaryBorder'>
                                                    <EditOutlinedIcon sx={{ fontSize: "24px" }} />
                                                </div>
                                            </BootstrapTooltip>
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* От скольки дней ---- От скольки часов ---- Старт курса */}
                            <div className={css.items}>

                                {/* От скольки дней */}
                                <div className={css.txtItem}>
                                    <span className={css.itemHead}>От скольки дней</span>
                                    <div className='myInputTextPrimary' onClick={() => courseDuration.current?.focus()}>
                                        <CalendarTodayOutlinedIcon sx={{ fontSize: "24px", color: '#48FFFE' }} />

                                        <input ref={courseDuration} type='text'
                                            placeholder='От скольки дней' autoComplete='off' readOnly={!props.getIsEditableCourseDuration}
                                            value={props.getValueDuration} onChange={e => props.changeNumberValue('duration', e.target.value)}
                                        />

                                        <div onClick={(e) => { props.setIsEditableCourseDuration(!props.getIsEditableCourseDuration) }} style={{ margin: 'auto 0' }}>
                                            {props.getIsEditableCourseDuration
                                                ? <BootstrapTooltip title="Применить изменения" placement="bottom" arrow>
                                                    <div className='myButtonIconPrimaryBorder' onClick={() => props.changesFields('duration')}>
                                                        <div className='circle'>
                                                            <div className='red-medium-circle'>
                                                                <DoneAllOutlinedIcon sx={{ fontSize: "24px" }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </BootstrapTooltip>

                                                : <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                                                    <div className='myButtonIconPrimaryBorder'>
                                                        <EditOutlinedIcon sx={{ fontSize: "24px" }} />
                                                    </div>
                                                </BootstrapTooltip>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* От скольки часов */}
                                <div className={css.txtItem}>
                                    <span className={css.itemHead}>От скольки часов</span>
                                    <div className='myInputTextPrimary' onClick={() => courseValueHours.current?.focus()}>
                                        <AccessTimeIcon sx={{ fontSize: "24px", color: '#48FFFE' }} />

                                        <input ref={courseValueHours} type='text'
                                            placeholder='От скольки часов' autoComplete='off' readOnly={!props.getIsEditableCourseValueHours}
                                            value={props.getValueHours} onChange={e => props.changeNumberValue('value_hours', e.target.value)}
                                        />

                                        <div onClick={(e) => { props.setIsEditableCourseValueHours(!props.getIsEditableCourseValueHours) }} style={{ margin: 'auto 0' }}>
                                            {props.getIsEditableCourseValueHours
                                                ? <BootstrapTooltip title="Применить изменения" placement="bottom" arrow>
                                                    <div className='myButtonIconPrimaryBorder' onClick={() => props.changesFields('value_hours')}>
                                                        <div className='circle'>
                                                            <div className='red-medium-circle'>
                                                                <DoneAllOutlinedIcon sx={{ fontSize: "24px" }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </BootstrapTooltip>

                                                : <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                                                    <div className='myButtonIconPrimaryBorder'>
                                                        <EditOutlinedIcon sx={{ fontSize: "24px" }} />
                                                    </div>
                                                </BootstrapTooltip>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Старт курса */}
                                <div className={css.txtItem}>
                                    <span className={css.itemHead}>Старт курса</span>
                                    <div className='myInputTextPrimary' onClick={() => courseDateStart.current?.focus()}>
                                        <CalendarMonthOutlinedIcon sx={{ fontSize: "24px", color: '#48FFFE' }} />

                                        <input ref={courseDateStart} type='text'
                                            placeholder='Старт курса' autoComplete='off' readOnly={true}
                                            value={props.getValueDateStart} onChange={e => props.setValueDateStart(e.target.value)}
                                        />

                                        <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                                            <div className='myButtonIconPrimaryBorder' onClick={() => props.setIsVisibleSelectDate(true)}>
                                                <CalendarMonthOutlinedIcon sx={{ fontSize: "24px" }} />
                                            </div>
                                        </BootstrapTooltip>
                                    </div>
                                </div>
                            </div>

                            <div className='dividerVerticalSecondary'></div>

                            {/* Название курса */}
                            <div className={css.txtItem}>
                                <span className={css.itemHead}>Название курса</span>
                                <div className='myInputTextPrimary' onClick={() => courseNameRef.current?.focus()}>
                                    <input ref={courseNameRef} type='text'
                                        placeholder='Название курса' autoComplete='off' readOnly={!props.getIsEditableCourseName}
                                        value={props.getValueCourseName} onChange={e => props.setValueCourseName(e.target.value)}
                                    />

                                    <div onClick={(e) => { props.setIsEditableCourseName(!props.getIsEditableCourseName) }} style={{ margin: 'auto 0' }}>
                                        {props.getIsEditableCourseName
                                            ? <BootstrapTooltip title="Применить изменения" placement="bottom" arrow>
                                                <div className='myButtonIconPrimaryBorder' onClick={() => props.changesFields('head_text')}>
                                                    <div className='circle'>
                                                        <div className='red-medium-circle'>
                                                            <DoneAllOutlinedIcon sx={{ fontSize: "24px" }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </BootstrapTooltip>

                                            : <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                                                <div className='myButtonIconPrimaryBorder'>
                                                    <EditOutlinedIcon sx={{ fontSize: "24px" }} />
                                                </div>
                                            </BootstrapTooltip>
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* Сумма пакет 1 ---- Сумма пакет 2 ---- Сумма пакет 3 ---- Скидка */}
                            <div className={css.items}>

                                {/* Сумма пакет 1 */}
                                <div className={css.txtItem}>
                                    <span className={css.itemHead}>Сумма Пакет 1</span>
                                    <div className='myInputTextPrimary' onClick={() => courseSum1.current?.focus()}>
                                        <span style={{ color: '#48FFFE' }}>₽</span>

                                        <input ref={courseSum1} type='text'
                                            placeholder='Пакет 1' autoComplete='off' readOnly={!props.getIsEditableCourseSum1}
                                            value={props.getValueSum1} onChange={e => props.changeNumberValue('sum_1', e.target.value)}
                                        />

                                        <div onClick={(e) => { props.setIsEditableCourseSum1(!props.getIsEditableCourseSum1) }} style={{ margin: 'auto 0' }}>
                                            {props.getIsEditableCourseSum1
                                                ? <BootstrapTooltip title="Применить изменения" placement="bottom" arrow>
                                                    <div className='myButtonIconPrimaryBorder' onClick={() => props.changesFields('sum_1')}>
                                                        <div className='circle'>
                                                            <div className='red-medium-circle'>
                                                                <DoneAllOutlinedIcon sx={{ fontSize: "24px" }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </BootstrapTooltip>

                                                : <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                                                    <div className='myButtonIconPrimaryBorder'>
                                                        <EditOutlinedIcon sx={{ fontSize: "24px" }} />
                                                    </div>
                                                </BootstrapTooltip>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Сумма пакет 2 */}
                                <div className={css.txtItem}>
                                    <span className={css.itemHead}>Сумма Пакет 2</span>
                                    <div className='myInputTextPrimary' onClick={() => courseSum2.current?.focus()}>
                                        <span style={{ color: '#48FFFE' }}>₽</span>

                                        <input ref={courseSum2} type='text'
                                            placeholder='Пакет 2' autoComplete='off' readOnly={!props.getIsEditableCourseSum2}
                                            value={props.getValueSum2} onChange={e => props.changeNumberValue('sum_2', e.target.value)}
                                        />

                                        <div onClick={(e) => { props.setIsEditableCourseSum2(!props.getIsEditableCourseSum2) }} style={{ margin: 'auto 0' }}>
                                            {props.getIsEditableCourseSum2
                                                ? <BootstrapTooltip title="Применить изменения" placement="bottom" arrow>
                                                    <div className='myButtonIconPrimaryBorder' onClick={() => props.changesFields('sum_2')}>
                                                        <div className='circle'>
                                                            <div className='red-medium-circle'>
                                                                <DoneAllOutlinedIcon sx={{ fontSize: "24px" }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </BootstrapTooltip>

                                                : <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                                                    <div className='myButtonIconPrimaryBorder'>
                                                        <EditOutlinedIcon sx={{ fontSize: "24px" }} />
                                                    </div>
                                                </BootstrapTooltip>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Сумма пакет 3 */}
                                <div className={css.txtItem}>
                                    <span className={css.itemHead}>Сумма Пакет 3</span>
                                    <div className='myInputTextPrimary' onClick={() => courseSum3.current?.focus()}>
                                        <span style={{ color: '#48FFFE' }}>₽</span>

                                        <input ref={courseSum3} type='text'
                                            placeholder='Пакет 3' autoComplete='off' readOnly={!props.getIsEditableCourseSum3}
                                            value={props.getValueSum3} onChange={e => props.changeNumberValue('sum_3', e.target.value)}
                                        />

                                        <div onClick={(e) => { props.setIsEditableCourseSum3(!props.getIsEditableCourseSum3) }} style={{ margin: 'auto 0' }}>
                                            {props.getIsEditableCourseSum3
                                                ? <BootstrapTooltip title="Применить изменения" placement="bottom" arrow>
                                                    <div className='myButtonIconPrimaryBorder' onClick={() => props.changesFields('sum_3')}>
                                                        <div className='circle'>
                                                            <div className='red-medium-circle'>
                                                                <DoneAllOutlinedIcon sx={{ fontSize: "24px" }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </BootstrapTooltip>

                                                : <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                                                    <div className='myButtonIconPrimaryBorder'>
                                                        <EditOutlinedIcon sx={{ fontSize: "24px" }} />
                                                    </div>
                                                </BootstrapTooltip>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Скидка */}
                                <div className={css.txtItem}>
                                    <span className={css.itemHead}>Скидка</span>
                                    <div className='myInputTextPrimary' onClick={() => courseDiscount.current?.focus()}>
                                        <span style={{ color: '#48FFFE' }}>%</span>

                                        <input ref={courseDiscount} type='text'
                                            placeholder='Скидка' autoComplete='off' readOnly={!props.getIsEditableCourseDiscount}
                                            value={props.getValueDiscount} onChange={e => props.changeNumberValue('discount', e.target.value)}
                                        />

                                        <div onClick={(e) => { props.setIsEditableCourseDiscount(!props.getIsEditableCourseDiscount) }} style={{ margin: 'auto 0' }}>
                                            {props.getIsEditableCourseDiscount
                                                ? <BootstrapTooltip title="Применить изменения" placement="bottom" arrow>
                                                    <div className='myButtonIconPrimaryBorder' onClick={() => props.changesFields('discount')}>
                                                        <div className='circle'>
                                                            <div className='red-medium-circle'>
                                                                <DoneAllOutlinedIcon sx={{ fontSize: "24px" }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </BootstrapTooltip>

                                                : <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                                                    <div className='myButtonIconPrimaryBorder'>
                                                        <EditOutlinedIcon sx={{ fontSize: "24px" }} />
                                                    </div>
                                                </BootstrapTooltip>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='dividerVerticalSecondary'></div>

                            {/* Описание курса */}
                            <div className={css.txtItem}>
                                <div className={css.itemHead} style={{ marginBottom: '10px' }}>
                                    <h6>Описание курса</h6>

                                    <div className="myButtonIconTextPrimarySmall" onClick={() => props.changesFields('title_text')}>
                                        <SaveOutlinedIcon sx={{ fontSize: "24px" }} />
                                        <span>Сохранить описание курса</span>
                                    </div>
                                </div>

                                <SunEditor lang='ru' setDefaultStyle="font-size: 20px;"
                                    setContents={props.editorState.length === 0 ? props.courseInfo.title_text : props.editorState} 
                                    onChange={props.setEditorState}
                                    setOptions={{
                                        mode: 'classic',
                                        width: '100%',
                                        height: 'auto',
                                        minHeight: '250',
                                        maxHeight: '300',
                                        buttonList: [
                                            [
                                                "undo", // Отменить
                                                "redo", // Вернуть
                                            ],
                                            [
                                                "removeFormat", // Очистить форматирование
                                            ],
                                            [
                                                "bold", // Полужирный шрифт
                                                "italic", // Курсив шрифта
                                                "underline", // Подчеркнутый шрифт
                                                "strike", // Зачеркнутый шрифт
                                            ],
                                            [
                                                "fontColor", // Цвет шрифта
                                                "hiliteColor", // Цвет фона шрифта
                                            ],
                                            [
                                                "outdent", // Уменьшить отступ
                                                "indent", // Увеличить отступ
                                            ],
                                            [
                                                "list", // Списки
                                                "align", // Выравнивание
                                                "lineHeight", // Высота линии
                                            ],
                                            [
                                                "blockquote", // Цитата
                                                "horizontalRule", // Вставить горизонтальную линию
                                                "link", // Ссылка
                                            ],


                                            // [
                                            //     "-right",
                                            //     "save", // Сохранить
                                            // ],
                                            [
                                                "-right",
                                                "preview", // Предварительный просмотр
                                                "print" // Печать
                                            ],
                                            [
                                                "-right",
                                                "codeView", // Редактировать HTML
                                                "fullScreen", // Развернуть на полный экран
                                                "showBlocks", // Блочный вид
                                            ],
                                            // [
                                            //     // "font", // Шрифт
                                            //     // "fontSize", // Размер шрифта
                                            //     // "formatBlock", // Стиль абзаца
                                            //     // "paragraphStyle", // Стиль абзаца
                                            //     // "subscript", // Шрифт в виде нижнего индекса
                                            //     // "superscript", // Шрифт в виде верхнего индекса
                                            //     // "textStyle", // Стиль текста
                                            //     // "table", // Таблица
                                            //     // "image", // Изображение
                                            //     // "video", // Видео
                                            //     // "audio", // Аудио
                                            // ]
                                        ]
                                    }}
                                />
                            </div>

                        </section>


                        {/* Макет секции курса, на лэндинговой странице */}
                        <section className={css.item}>
                            <Dropzone onDropAccepted={(files) => props.fileUploader(files, props.courseInfo.img_name)} multiple={false}>
                                {({ getRootProps, getInputProps, isDragAccept, isDragActive }) => (
                                    <div {...getRootProps({ className: isDragAccept ? `${css.dropZone}` : `${css.img}` })} style={{ background: `#03392A url(${props.courseInfo.img_path}) no-repeat center/100% 100%` }}>
                                        <input {...getInputProps()} />
                                        <h4>{parse(props.courseInfo.head_text || '')}</h4>
                                    </div>
                                )}
                            </Dropzone>

                            <div className={css.info}>
                                <header>
                                    <p>Автор курса: {props.courseInfo.author}</p>
                                    <div className={css.meta}>
                                        <figure>
                                            <img src={calendar} alt="Количество дней" />
                                            <figcaption>от {props.courseInfo.duration} дней</figcaption>
                                        </figure>

                                        <figure>
                                            <img src={clock} alt="Количество часов" />
                                            <figcaption>от {props.courseInfo.value_hours} часов</figcaption>
                                        </figure>
                                    </div>
                                </header>

                                <div className={css.description}>
                                    {parse(props.courseInfo.title_text || '')}
                                </div>

                                <p className={css.start}>Старт курса: {new Date(props.courseInfo.date_start).toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric' })}</p>

                                <div className='buttonCourse btnHover'>Узнать подробнее</div>
                            </div>
                        </section>
                    </main>
                </div>
            </Drawer>
        </>
    )
}

// Выбор даты старта курса
const SelectDate = (props: any) => {
    return (
        <Modal backdrop="static" size="xs" autoFocus={false} open={props.getIsVisibleSelectDate} className='myModalPrimary'>
            <div className="modalContent">
                <div className="modalHead">
                    Дата старта курса...
                </div>

                <div className="modalBody" style={{ alignItems: 'center' }}>
                    <Calendar value={props.getDateState} onChange={(e: any) => props.setDateState(e)} />
                </div>

                <div className="modalFooter">
                    <div className='myButtonSecondary' onClick={() => { props.setIsVisibleSelectDate(false); props.setDateState(undefined) }}>ОТМЕНА</div>
                    <div className='myButtonPrimary' onClick={() => props.changesFields('date_start')}>ПРИМЕНИТЬ</div>
                </div>
            </div>
        </Modal>
    )
}

export default CourseInfo