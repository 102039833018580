import React from 'react'

import css from './nav-01.module.scss'

const Nav_01 = (props: any) => {
    return (
        <section className={css.main}>
            <h5>Основные сведения</h5>

            <p>
                <b>Полное наименование организации:</b> Общество с ограниченной ответственностью «Информационно-коммуникативные технологии плюс».
            </p>

            <p>
                <b>Сокращённое наименование организации:</b> ООО «ИКТ +».
            </p>

            <p>
                <b>Дата создания организации:</b> Общество с ограниченной ответственностью «Информационно-коммуникативные технологии плюс» зарегистрировано 21 января 2013 г. регистратором Инспекции Федеральной налоговой службы по Ленинскому району г. Саратова.
            </p>

            <p>
                <b>Учредитель (генеральный директор):</b> Кармазин Виталий Юрьевич.
            </p>

            <p>
                <b>Адрес регистрации (почтовый):</b> Российская Федерация, 410069, Приволжский федеральный округ, Саратовская область, г. Саратов, ул. Куприянова, д. 7А, оф. 16.
            </p>

            <p>
                <b>Адрес места осуществления образовательной деятельности:</b> Российская Федерация, 410002, Приволжский федеральный округ, Саратовская область, город Саратов, ул. Мичурина, д. 150/154.
            </p>

            <p>
                <b>Режим и график работы:</b> с понедельника по пятницу с 9.00 до 19.00.
            </p>

            <ul>
                <li><b>Контактная информация:</b></li>
                <ol>
                    <li>Официальный сайт: <a href="https://apsitis.ru">apsitis.ru</a></li>
                    <li>Электронный адрес: <a href="mailto:info@apsitis.ru">info@apsitis.ru</a></li>
                    <li>Телефон: <a href="tel:8 800 888 88 88">8 800 888 88 88</a></li>
                </ol>
            </ul>
        </section>
    )
}

export default Nav_01