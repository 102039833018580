import React from 'react'
import parse from 'html-react-parser'

import empty from './../../../../css/img/empty.png'
import { BootstrapTooltip } from '../../../common/ui/tooltip'

const Section02 = (props: any) => {
    return (
        <section>
            <div className='container CB02'>
                <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                    <img className='itemHover' src={props.page.b02.img_1_path || empty} alt={props.page.b02.text_1?.replace(/<[^>]+>/g, '')} 
                        onClick={() => props.openEditImage(true, '02', 'img_1_path', 'image', props.page.b02.img_1_path, props.page.b02.img_1_name)}
                    />
                </BootstrapTooltip>

                <article>
                    <BootstrapTooltip title="Редактировать" placement="top" arrow>
                        <h2 className='itemHover' onClick={() => props.openEditTextSecondary(true, '02', 'text_1', 'text', props.page.b02.text_1)}>
                            {parse(props.page.b02.text_1 || 'Редактировать')}
                        </h2>
                    </BootstrapTooltip>

                    <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                        <div className='itemHover' onClick={() => props.openEditTextSecondary(true, '02', 'text_2', 'text', props.page.b02.text_2)}>
                            {parse(props.page.b02.text_2 || 'Редактировать')}
                        </div>
                    </BootstrapTooltip>
                </article>

                <section className='myPopoverVertical'>
                    <h5>Блок № 2</h5>
                    
                    <div className='dividerPrimary' style={{ height: '40px' }}></div>
    
                    <BootstrapTooltip title="Видимость блока" placement="bottom" arrow>
                        <input type="checkbox"
                            checked={props.page.b02.is_active === 1 ? true : false}
                            onChange={(e) => props.onChangeBlockActive('02', 'is_active', 'isActive', e.target.checked)}
                        />
                    </BootstrapTooltip>
                </section>
            </div>
        </section>
    )
}

export default Section02