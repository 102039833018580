import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { AppStateType } from '../../bll/store'
import Articles from './articles'
import { articlesInitialized } from '../../bll/common/commonOperations'

const ArticlesContainer = (props: any) => {
    const [getIsVisibleMenu, setIsVisibleMenu] = React.useState(false) // Видимость меню

    React.useEffect(() => {
        props.articlesInitialized()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Свойства передаваемые в компоненту
    const propsToComponent = {
        getIsVisibleMenu, setIsVisibleMenu, // Видимость меню
    }

    return (
        <Articles {...props} {...propsToComponent} />
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        setting: state.common.settings,
        files: state.common.otherFiles
    }
}

export default compose(connect(mapState, { articlesInitialized }))(ArticlesContainer)