import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import './css/ui.scss'
import './css/index.scss'
import './css/landing.scss'
import './css/course.scss'

import { store } from './bll/store'
import LandingContainer from './components/landing/landingContainer'
import CourseContainer from './components/course/courseContainer'
import LoginContainer from './components/admin/login/loginContainer'
import MainLkContainer from './components/admin/mainLk/mainLkContainer'
import ErrorPage from './components/errorPage/errorPage'
import OrganizationContainer from './components/organization/organizationContainer'
import ContactsContainer from './components/contacts/contactsContainer'
import EducationContainer from './components/education/educationContainer'
import CommentsContainer from './components/comments/commentsContainer'
import ArticlesContainer from './components/articles/articlesContainer'

const root = ReactDOM.createRoot(document.getElementById('myApp') as HTMLElement)

root.render(
    <BrowserRouter>
        <Provider store={store}>
            <Routes>
                <Route path='/' element={<LandingContainer />} />
                <Route path={'/course/:id'} element={<CourseContainer />} />
                
                <Route path={'/organization/*'} element={<OrganizationContainer />} />
                <Route path={'/contacts/'} element={<ContactsContainer />} />
                <Route path={'/education/'} element={<EducationContainer />} />
                <Route path={'/comments/'} element={<CommentsContainer />} />
                <Route path={'/articles/*'} element={<ArticlesContainer />} />

                <Route path={`/login/*`} element={<LoginContainer />} />
                <Route path={`/login/lk/*`} element={<MainLkContainer />} />
                
                <Route path={`/error`} element={<ErrorPage />} />
            </Routes>
        </Provider>
    </BrowserRouter>
)