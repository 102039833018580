import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import openNotification from '../../common/ui/notification'
import errorHandler from '../../../bll/errorHandler'
import { AppStateType } from '../../../bll/store'
import { clearCourses, createCourses, getCourses } from '../../../bll/lkCourses/lkCoursesOperations'
import Course from './courses'

const CoursesContainer = (props: any) => {
    const [getIsOpenCreateCourse, setIsOpenCreateCourse] = React.useState(false) // Видимость окна (Создание курса)
    
    const [getIsOpenCourseInfo, setIsOpenCourseInfo] = React.useState(false) // Видимость окна (Информация о курсе)
    const [getIsOpenCourseContent, setIsOpenCourseContent] = React.useState(false) // Видимость окна (Контент курса)
    
    const [getEditableCourseID, setEditableCourseID] = React.useState('') // Редактируемый идентификатор курса
    const [getValueCourseName, setValueCourseName] = React.useState('') // Имя курса

    const [getFilter, setFilter] = React.useState('') // Фильтр отзывов


    React.useEffect(() => {
        props.getCourses().then((result: any) => {
            switch (result?.code) {
                case "ERR_QUERY_STRING": { return openNotification("Список курсов...", props.msg.err_0051, "error", "bottomRight") }
                default: errorHandler(result?.code, props.msg); break
            }
        })

        return () => {
            props.clearCourses()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    // Кнопка создать в окне создания курса
    const applyCreateCourse = () => {
        if (!getValueCourseName.trim()) {
            openNotification("Создание курса...", props.msg.warning_0002, "warning", "bottomRight")
        } else {
            props.createCourses(getValueCourseName).then((result: any) => {
                switch (result?.code) {
                    case "OK": 
                        setValueCourseName('')
                        setIsOpenCreateCourse(false)
                    break

                    case "ERR_QUERY_STRING": { return openNotification("Создание курса...", props.msg.err_0072, "error", "bottomRight") }
                    default: errorHandler(result?.code, props.msg); break
                }
            })
        }
    }

    // Открытие окна (Информация о курсе)
    const openCourseInfo = (isOpen: boolean, courseID: string = '') => {
        setEditableCourseID(courseID)
        setIsOpenCourseInfo(isOpen)
    }

    // Открытие окна (Контент курса)
    const openCourseContent = (isOpen: boolean, courseID: string = '') => {
        setEditableCourseID(courseID)
        setIsOpenCourseContent(isOpen)
    }

    // Свойства передаваемые в компоненту
    const propsToComponent = {
        getIsOpenCreateCourse, setIsOpenCreateCourse, // Видимость окна (Создание курса)

        openCourseInfo, // Открытие окна (Информация о курсе)
        openCourseContent, // Открытие окна (Контент курса)

        getIsOpenCourseInfo, // Видимость окна (Информация о курсе)
        getIsOpenCourseContent, // Видимость окна (Контент курса)
        getEditableCourseID, // Редактируемый идентификатор курса

        getValueCourseName, setValueCourseName, // Имя курса

        applyCreateCourse, // Кнопка создать в окне создания курса

        getFilter, setFilter, // Фильтр отзывов
    }

    return (
        <Course {...props} {...propsToComponent} />
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        coursesList: state.courses.coursesList
    }
}

export default compose(connect(mapState, { getCourses, clearCourses, createCourses }))(CoursesContainer)