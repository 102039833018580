import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { AppStateType } from '../../../../bll/store'
import AddDeleteQuestions from './addDeleteQuestions'
import openNotification from '../../../common/ui/notification'
import errorHandler from '../../../../bll/errorHandler'
import { addQuestionToCourse, clearQuestions, deleteQuestionInCourse, getQuestionsLK } from '../../../../bll/lkCourses/lkCoursesOperations'

const AddDeleteQuestionsContainer = (props: any) => {
    const [selectedAddID, setSelectedAddID] = React.useState('') // Выбранный идентификатор отзыва для добавления
    const [selectedDeleteID, setSelectedDeleteID] = React.useState('') // Выбранный идентификатор отзыва для удаления


    React.useEffect(() => {
        if (props.getOpenAddDeleteQuestions) {
            props.getQuestionsLK(props.getEditableCourseID).then((result: any) => {
                switch (result?.code) {
                    case 'OK': break

                    case "ERR_QUERY_STRING": { return openNotification("Вопросы...", props.msg.err_0099, "error", "bottomRight") }
                    default: errorHandler(result?.code, props.msg); break
                }
            })
        }

        return () => {
            props.clearQuestions()
        }
    }, [props.getOpenAddDeleteQuestions]) // eslint-disable-line react-hooks/exhaustive-deps


    // Добавление дисциплины в курс
    const eventAddToCourse = () => {
        props.addQuestionToCourse(props.getEditableCourseID, selectedAddID).then((result: any) => {
            switch (result?.code) {
                case 'OK': 
                    setSelectedAddID('')
                break

                case "ERR_QUERY_STRING": { return openNotification("Добавление вопроса...", props.msg.err_0100, "error", "bottomRight") }
                default: errorHandler(result?.code, props.msg); break
            }
        })
    }

    // Удаление дисциплины из курса
    const eventDeleteInCourse = () => {
        props.deleteQuestionInCourse(selectedDeleteID, props.getEditableCourseID).then((result: any) => {
            switch (result?.code) {
                case 'OK': 
                    setSelectedDeleteID('')
                break

                case "ERR_QUERY_STRING": { return openNotification("Удаление вопроса...", props.msg.err_0101, "error", "bottomRight") }
                default: errorHandler(result?.code, props.msg); break
            }
        })
    }


    // Свойства передаваемые в компоненту
    const propsToComponent = {
        selectedAddID, setSelectedAddID, // Выбранная дисциплина для добавления
        selectedDeleteID, setSelectedDeleteID, // Выбранная дисциплина для удаления

        eventAddToCourse, // Добавление дисциплины в курс
        eventDeleteInCourse, // Удаление дисциплины из курса
    }

    return (
        <AddDeleteQuestions {...props} {...propsToComponent} />
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        questions: state.courses.questionsList
    }
}

export default compose(connect(mapState, {
    getQuestionsLK, clearQuestions, addQuestionToCourse, deleteQuestionInCourse
}))(AddDeleteQuestionsContainer)