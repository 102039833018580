import api from './api'

export default class ApiLkDisciplines {

    // Получение списка дисциплин
    static async getDisciplines(): Promise<any> {
        try {
            return await api.get('/disciplines/getDisciplines')
        } catch (error) {
            return error
        }
    }
    
    // Создание дисциплины
    static async createDisciplines(disciplineName: string, disciplineDescription: string): Promise<any> {
        try {
            return await api.post(`/disciplines/createDisciplines?disciplineName=${disciplineName}&disciplineDescription=${disciplineDescription}`)
        } catch (error) {
            return error
        }
    }

    // Редактирование дисциплины
    static async editDisciplines(disciplineID: string, disciplineName: string, disciplineDescription: string): Promise<any> {
        try {
            return await api.put(`/disciplines/editDisciplines?disciplineID=${disciplineID}&disciplineName=${disciplineName}&disciplineDescription=${disciplineDescription}`)
        } catch (error) {
            return error
        }
    }

}