import React from 'react'
import parse from 'html-react-parser'

import { Collapse } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

const { Panel } = Collapse

const Section05 = (props: any) => {
    return (
        <section id='program'>
            <article className='container CB05'>
                <h2>{parse(props.page.b05.text_1 || '')}</h2>

                <Collapse accordion bordered={false} className='items' expandIconPosition='end' expandIcon={({ isActive }) => <PlusOutlined style={{ color: `#FF9900`, fontSize: '26px' }} rotate={isActive ? -45 : 0} />}>
                    {!props.programList.length
                        ? ''
                        : props.programList.map((item: any) => {
                            return (
                                <Panel header={`${item.discipline_number}. ${item.discipline_name}`} id={item.cpd_id} key={item.cpd_id} className='item'>
                                    {parse(item.discipline_description || '')}
                                </Panel>
                            )
                        })
                    }
                </Collapse>
            </article>
        </section>
    )
}

export default Section05