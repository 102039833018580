import React from 'react'
// import { Image } from 'antd'

import css from './nav-08.module.scss'

const Nav_08 = (props: any) => {
    return (
        <section className={css.main}>
            <h5>Стипендии и иные виды материальной поддержки</h5>

            <span>Информация о наличии и условиях предоставления обучающимся стипендий</span>
            <span><b>Стипендии не предоставляются.</b></span>

            <span>Информация о наличии общежития, интерната, в том числе приспособленных для использования инвалидами и лицами с ограниченными возможностями здоровья, количестве жилых помещений в общежитии, интернате для иногородних обучающихся, формировании платы за проживание в общежитии</span>
            <span><b>Общежития, интерната, жилых помещений нет.</b></span>
            
            
            <span>Информация о трудоустройстве выпускников</span>
            <span><b>Трудоустройство – не предоставляется. Информация о мерах социальной поддержки Обучающимся предоставляется возможность оплаты обучения в рассрочку (частями).</b></span>
        </section>
    )
}

export default Nav_08