import React from 'react'
import parse from 'html-react-parser'

import WallpaperOutlinedIcon from '@mui/icons-material/WallpaperOutlined'
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined'
import ExposureOutlinedIcon from '@mui/icons-material/ExposureOutlined'

import { BootstrapTooltip } from '../../../common/ui/tooltip'

const Section09 = (props: any) => {
    return (
        <section>
            <div className='container CB09' style={{ background: `${props.page.b09.bg_color} url(${props.page.b09.bg_img_path}) no-repeat center/100% 100%` }}>
                <article className='content'>
                    <BootstrapTooltip title="Редактировать" placement="top" arrow>
                        <h2 className='itemHover' onClick={() => props.openEditTextSecondary(true, '09', 'text_1', 'text', props.page.b09.text_1)}>
                            {parse(props.page.b09.text_1 || 'Редактировать')}
                        </h2>
                    </BootstrapTooltip>

                    <div className='items'>
                        {!props.commentsCourseList.length
                            ? ''
                            : props.commentsCourseList.map((item: any) => {
                                return (
                                    <div className='item' key={item.dcc_id}>
                                        <img src={item.img_path} alt={item.header} />

                                        <article>
                                            <h4>{item.header}</h4>
                                            {parse(item.text || '')}
                                        </article>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <button className='buttonSecondary_2'>Смотреть все отзывы</button>
                </article>

                <section className='myPopoverVertical'>
                    <h5>Блок № 9</h5>
                    
                    <div className='dividerPrimary' style={{ height: '40px' }}></div>

                    <BootstrapTooltip title="Видимость блока" placement="bottom" arrow>
                        <input type="checkbox"
                            checked={props.page.b09.is_active === 1 ? true : false}
                            onChange={(e) => props.onChangeBlockActive('09', 'is_active', 'isActive', e.target.checked)}
                        />
                    </BootstrapTooltip>

                    {/* <BootstrapTooltip title="Редактировать высоту изображение заднего фона" placement="left" arrow>
                        <div className='myButtonIconSecondaryBorder' onClick={() => props.openEditTextNumber(true, '01', 'b1_bg_img_height', 'bg_img_size', props.b1.bg?.img_height)}>
                            <HeightOutlinedIcon />
                        </div>
                    </BootstrapTooltip> */}

                    {/* <BootstrapTooltip title="Редактировать ширину изображение заднего фона" placement="left" arrow>
                        <div className='myButtonIconSecondaryBorder' onClick={() => props.openEditTextNumber(true, '01', 'b1_bg_img_width', 'bg_img_size', props.b1.bg?.img_width)}>
                            <MultipleStopOutlinedIcon />
                        </div>
                    </BootstrapTooltip> */}

                    {/* <BootstrapTooltip title="Редактировать позицию изображение заднего фона" placement="left" arrow>
                        <div className='myButtonIconSecondaryBorder' onClick={() => props.openEditPosition(true, '01', 'b1_bg_img_position', 'position', props.b1.bg?.img_position)}>
                            <PictureInPictureAltOutlinedIcon />
                        </div>
                    </BootstrapTooltip> */}

                    <div className='dividerPrimary' style={{ height: '40px' }}></div>

                    <BootstrapTooltip title="Добавить / Удалить отзыв" placement="bottom" arrow>
                        <div className="myButtonIconSecondaryBorder" onClick={() => props.setOpenAddDeleteComments(true)}>
                            <ExposureOutlinedIcon />
                        </div>
                    </BootstrapTooltip>

                    <BootstrapTooltip title="Редактировать изображение заднего фона" placement="bottom" arrow>
                        <div className="myButtonIconSecondaryBorder" onClick={() => props.openEditImage(true, '09', 'bg_img_path', 'image', props.page.b09.bg_img_path, props.page.b09.bg_img_name)}>
                            <WallpaperOutlinedIcon />
                        </div>
                    </BootstrapTooltip>

                    <BootstrapTooltip title="Редактировать задний фон блока" placement="bottom" arrow>
                        <div className="myButtonIconSecondaryBorder" onClick={() => props.openEditColor(true, '09', 'bg_color', 'color_background', props.page.b09.bg_color)}>
                            <PaletteOutlinedIcon />
                        </div>
                    </BootstrapTooltip>
                </section>
            </div>
        </section>
    )
}

export default Section09