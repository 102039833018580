import React from 'react'
import parse from 'html-react-parser'

const Section11 = (props: any) => {
    return (
        <section id='question'>
            <div className='container CB11' style={{ background: `${props.page.b11.bg_color} url(${props.page.b11.bg_img_path}) no-repeat center/100% 100%` }}>
                <div className='content'>
                    <header style={{ display: props.getIsVisibleAnswerClient ? 'none' : 'flex' }}>
                        <h2>{parse(props.page.b11.text_1 || '')}</h2>
                        <div className='comment'>{parse(props.page.b11.text_2 || '')}</div>
                    </header>

                    {props.getIsVisibleAnswerClient
                        ? <div className='answerClient'>
                            {parse(props.getValueMessage || '')}
                        </div>

                        : <form className='form'>
                            <input id='b1' type="text" placeholder='Ваше имя' className='txtPrimary' autoComplete='off'
                                value={props.getValueName} onChange={e => props.setValueName(e.target.value)}
                            />

                            <input id='b2' type="text" placeholder='Ваш телефон' className='txtPrimary' autoComplete='off'
                                value={props.getValuePhone} onChange={e => props.changeNumberValue(e.target.value)}
                            />

                            <button id='b3' type='button' className='buttonCourse' style={{ borderRadius: '20px' }} onClick={props.sendQuestion}>Отправить</button>

                            <div id='b4'>
                                Нажимая на кнопку «Отправить», я соглашаюсь на <a href={props.files.PERSONAL_DATA?.file_path} target='_blank' rel="noreferrer">обработку персональных данных</a> и <a href={props.files.USER_AGREEMENT?.file_path} target='_blank' rel="noreferrer">пользовательским соглашением</a>
                            </div>
                        </form>
                    }
                </div>
            </div>
        </section>
    )
}

export default Section11