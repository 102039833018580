import React from 'react'

import MyHeader from '../common/header/header'
import FooterContainer from '../common/footer/footerContainer'

import css from './contacts.module.scss'

const Contacts = (props: any) => {
    return (
        <>
            <MyHeader {...props} />

            <main className={`container ${css.main}`}>
                <article>
                    <h3>Контакты</h3>
                </article>

                <div className={css.container}>
                    <span>Обучение проводится дистанционно, в любом городе!</span>

                    <span>
                        <b>Режим и график работы:</b> с понедельника по пятницу с 9.00 до 19.00.
                    </span>

                    <span>
                        <b>Телефон:</b> <a href={`tel:${props.setting.phone}`}>{props.setting.phone}</a>
                    </span>

                    <span>
                        <b>Электронный адрес:</b> <a href={`mailto:${props.setting.email}`}>{props.setting.email}</a>
                    </span>

                    <span>
                        <b>Адрес:</b> Российская Федерация, 410002, Приволжский федеральный округ, Саратовская область, город Саратов, ул. Мичурина, д. 150/154.
                    </span>

                </div>
            </main>

            <FooterContainer {...props} />
        </>
    )
}

export default Contacts