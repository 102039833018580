import api from './api'

export default class ApiAuth {

    // Авторизация
    static async login(login: string, password: string): Promise<any> {
        try {
            return await api.post('/auth/login', { login, password })
        } catch (error) {
            return error
        }
    }

    // Выход оператора из приложения
    static async logout(): Promise<any> {
        try {
            return await api.post('/auth/logout')
        } catch (error) {
            return error
        }
    }

    // Получение данных профиля оператора
    static async getMyProfile(): Promise<any> {
        try {
            return await api.get('/auth/getMyProfile')
        } catch (error) {
            return error
        }
    }

    // Загрузка аватарки пользователя
    // static async uploadAvatar(file: any): Promise<any> {
    //     try {
    //         const formData = new FormData()
    //         formData.append("avatar", file)

    //         return await api.post('/uploadAvatar', formData, { headers: { "Content-Type": "multipart/form-data" } })
    //     } catch (error) {
    //         return error
    //     }
    // }

    // Изменение профиля оператора
    // static async editMyProfile(lastName: string, firstName: string, secondName: string, position: string, phoneNumber: string): Promise<any> {
    //     try {
    //         return await api.put('/editMyProfile', { lastName, firstName, secondName, position, phoneNumber })
    //     } catch (error) {
    //         return error
    //     }
    // }
}