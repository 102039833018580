import api from './api'

export default class ApiLkFiles {

    // Получение справочника - тип раздела файлов
    static async getFileTypes(): Promise<any> {
        try {
            return await api.get('/files/getFileTypes')
        } catch (error) {
            return error
        }
    }



    // Получение списка файлов
    static async getFiles(): Promise<any> {
        try {
            return await api.get('/files/getFiles')
        } catch (error) {
            return error
        }
    }
    
    // Создание файла
    static async createFile(fileTypeID: string, comment: string): Promise<any> {
        try {
            return await api.post(`/files/createFile?fileTypeID=${fileTypeID}&comment=${comment}`)
        } catch (error) {
            return error
        }
    }

    // Редактирование файла
    static async editFiles(fileID: string, fileTypeID: string, comment: string): Promise<any> {
        try {
            return await api.put(`/files/editFiles?fileID=${fileID}&fileTypeID=${fileTypeID}&comment=${comment}`)
        } catch (error) {
            return error
        }
    }
    
    // Загрузка файла
    static async uploadFile(file: any, fileID: number, previousFileName: string, fileName: string): Promise<any> {
        const formData = new FormData()
        formData.append("file", file)

        try {
            return await api.put(`/files/uploadFile?fileID=${fileID}&previousFileName=${previousFileName}&fileName=${fileName}`, formData, { headers: { "Content-Type": `multipart/form-data` } })
        } catch (error) {
            return error
        }
    }

}