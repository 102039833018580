import React from 'react'
import parse from 'html-react-parser'
import { NavLink } from 'react-router-dom'

import calendar from './../../css/img/calendar.svg'
import clock from './../../css/img/clock.svg'
import emptyDoc from './../../css/img/empty_documents.svg'

import Empty from '../common/ui/empty'

const Section04 = (props: any) => {
    return (
        <section id='courses'>
            <article className='container LB04'>
                <h2>{parse(props.page.b04_text_1?.value || '')}</h2>

                <div className='content'>
                    {!props.coursesList.length 
                        ? <Empty image={emptyDoc} description="Курсы не найдены" mainHeight='auto' />
                        : props.coursesList.map((item: any) => {
                            return (
                                <NavLink to={`/course/${item.head_translit}`} key={item.course_id} target='_blank' className='item'>
                                        <div className='img' style={{ background: `#03392A url(${item.img_path}) no-repeat center/100% 100%` }}>
                                            <h4>{parse(item.head_text || '')}</h4>
                                        </div>

                                        <div className='info'>
                                            <header>
                                                <p>Автор курса: {item.author}</p>
                                                <div className='meta'>
                                                    <figure>
                                                        <img src={calendar} alt="Количество дней" />
                                                        <figcaption>от {item.duration} дней</figcaption>
                                                    </figure>

                                                    <figure>
                                                        <img src={clock} alt="Количество часов" />
                                                        <figcaption>от {item.value_hours} часов</figcaption>
                                                    </figure>
                                                </div>
                                            </header>

                                            <div className='description'>
                                                {parse(item.title_text)}
                                            </div>

                                            <span className='start'>Старт курса: {new Date(item.date_start).toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric' })}</span>

                                            <div className='buttonCourse btnHover'>Узнать подробнее</div>
                                        </div>
                                </NavLink>
                            )
                        })
                    }
                </div>
            </article>
        </section>
    )
}

export default Section04