import React from 'react'
import parse from 'html-react-parser'

const Section01 = (props: any) => {
    return (
        <section>
            <div className='container LB01' style={{ background: `${props.page.b01_bg_color?.value} url(${props.page.b01_bg_img_path?.value}) no-repeat center/100% 100%` }}>
                <div className='block'>
                    <div className='content'>
                        <article>
                            <h1>{parse(props.page.b01_text_1?.value || '')}</h1>
                            <h2>{parse(props.page.b01_text_2?.value || '')}</h2>
                        </article>

                        <aside>
                            <a href='#courses' className='buttonPrimary'>Выбрать курс</a>
                            <a href='#question' className='buttonSecondary'>Задать вопрос</a>
                        </aside>
                    </div>

                    <img src={props.page.b01_img_1_path?.value} alt={props.page.b01_text_1?.value?.replace(/<[^>]+>/g, '')} />
                </div>
            </div>
        </section>
    )
}

export default Section01