import types from './lkFilesTypes'

const setFileTypes = (items: []) => ({ type: types.LKFILES_SET_FILE_TYPES, items })
const setFilesList = (items: []) => ({ type: types.LKFILES_SET_FILES_LIST, items })

const lkFilesActions = {
    setFileTypes,
    setFilesList,
}

export default lkFilesActions