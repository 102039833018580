import { getPrograms, getCommentsCourse, getQuestions } from './../common/commonOperations';
import { getCourseData } from '../common/commonOperations'

import api from './../../api/apiLkCourses'
import actions from './lkCoursesActions'

// Получение курсов
export const getCourses = () => async (dispatch: any) => {
    const responce = await api.getCourses()

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.setCoursesList(responce.data.itemsData))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Создание курса
export const createCourses = (courseName: string) => async (dispatch: any) => {
    const responce = await api.createCourses(courseName)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getCourses())
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}



// Получение информации о курсе (Редактирование курса - Страница Информация о курсе)
export const getCourseInfo = (courseID: number) => async (dispatch: any) => {
    const responce = await api.getCourseInfo(courseID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.setCourseInfo(
                    responce.data[0].course_id,
                    responce.data[0].img_path,
                    responce.data[0].img_name,
                    responce.data[0].author,
                    responce.data[0].head_text,
                    responce.data[0].head_translit,
                    responce.data[0].title_text,
                    responce.data[0].duration,
                    responce.data[0].value_hours,
                    responce.data[0].date_start,
                    responce.data[0].is_active,
                    responce.data[0].sum_1,
                    responce.data[0].sum_2,
                    responce.data[0].sum_3,
                    responce.data[0].discount,
                    responce.data[0].date_time_create,
                ))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Редактирование информации о курсе (Редактирование курса - Страница Информация о курсе)
export const editCourseInfo = (courseID: number, fieldName: string, fieldValue: any) => async (dispatch: any) => {
    const responce = await api.editCourseInfo(courseID, fieldName, fieldValue)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getCourses())
                dispatch(getCourseInfo(courseID))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Загрузка файла (Редактирование курса - Страница Информация о курсе)
export const uploadFileCourseInfo = (file: any, courseID: number, previousFileName: string, fileName: string) => async (dispatch: any) => {
    const responce = await api.uploadFileCourseInfo(file, courseID, previousFileName, fileName)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getCourseInfo(courseID))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}



// Изменение данных страницы курса (Редактирование курса - Страница Контент курса)
export const editCoursePageData = (courseID: string, action: string, blockNum: string, blockName: string, blockType: string, blockValue: any) => async (dispatch: any) => {
    const responce = await api.editCoursePageData(courseID, action, blockNum, blockName, blockType, blockValue)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getCourseData(courseID))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Загрузка файла (Редактирование страницы курса)
export const uploadFileCoursePageData = (file: any, courseID: string, previousFileName: string, fileName: string, blockNum: string, blockName: string) => async (dispatch: any) => {
    const responce = await api.uploadFileCoursePageData(file, courseID, previousFileName, fileName, blockNum, blockName)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getCourseData(courseID))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}


// Получение списка не добавленных и добавленных программ (Редактирование курса - Страница Контент курса)
export const getProgramsLK = (courseID: string) => async (dispatch: any) => {
    const responce = await api.getPrograms(courseID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.setProgramsList(responce.data[0]))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Добавление программы в курс (Редактирование курса - Страница Контент курса)
export const addProgramToCourse = (courseID: string, disciplineID: string) => async (dispatch: any) => {
    const responce = await api.addProgramToCourse(courseID, disciplineID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getPrograms(courseID))
                dispatch(getProgramsLK(courseID))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Удаление программы из курса (Редактирование курса - Страница Контент курса)
export const deleteProgramInCourse = (cpdID: string, courseID: string) => async (dispatch: any) => {
    const responce = await api.deleteProgramInCourse(cpdID, courseID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getPrograms(courseID))
                dispatch(getProgramsLK(courseID))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}


// Получение списка не добавленных и добавленных отзывов (Редактирование курса - Страница Контент курса)
export const getComments = (courseID: string) => async (dispatch: any) => {
    const responce = await api.getComments(courseID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.setCommentsList(responce.data[0]))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Добавление отзыва в курс (Редактирование курса - Страница Контент курса)
export const addCommentToCourse = (courseID: string, commentID: string) => async (dispatch: any) => {
    const responce = await api.addCommentToCourse(courseID, commentID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getCommentsCourse(courseID))
                dispatch(getComments(courseID))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Удаление отзыва из курса (Редактирование курса - Страница Контент курса)
export const deleteCommentInCourse = (dccID: string, courseID: string) => async (dispatch: any) => {
    const responce = await api.deleteCommentInCourse(dccID, courseID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getCommentsCourse(courseID))
                dispatch(getComments(courseID))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}


// Получение списка не добавленных и добавленных вопросов (Редактирование курса - Страница Контент курса)
export const getQuestionsLK = (courseID: string) => async (dispatch: any) => {
    const responce = await api.getQuestions(courseID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(actions.setQuestionsList(responce.data[0]))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Добавление вопроса в курс (Редактирование курса - Страница Контент курса)
export const addQuestionToCourse = (courseID: string, questionID: string) => async (dispatch: any) => {
    const responce = await api.addQuestionToCourse(courseID, questionID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getQuestions(courseID))
                dispatch(getQuestionsLK(courseID))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}

// Удаление вопроса из курса (Редактирование курса - Страница Контент курса)
export const deleteQuestionInCourse = (dcqID: string, courseID: string) => async (dispatch: any) => {
    const responce = await api.deleteQuestionInCourse(dcqID, courseID)

    if (!responce.code) {
        switch (responce.data.statusCode) {
            case 'OK': // Все ОК
                dispatch(getQuestions(courseID))
                dispatch(getQuestionsLK(courseID))
                return { code: 'OK' }

            default: return { code: responce.data.statusCode }
        }
    } else {
        return { code: "ERR_NETWORK" } // API сервер не доступен
    }
}



// Очистка курсов
export const clearCourses = () => async (dispatch: any) => {
    dispatch(actions.setCoursesList([]))
}

// Очистка информации о курсе (Редактирование курса)
export const clearCourseInfo = () => async (dispatch: any) => {
    dispatch(actions.setCourseInfo('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''))
}

// Очистка программ
export const clearPrograms = () => async (dispatch: any) => {
    dispatch(actions.setProgramsList([]))
}

// Очистка отзывов
export const clearComments = () => async (dispatch: any) => {
    dispatch(actions.setCommentsList([]))
}

// Очистка вопросов
export const clearQuestions = () => async (dispatch: any) => {
    dispatch(actions.setQuestionsList([]))
}
