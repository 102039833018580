import React from 'react'
import { Modal } from 'rsuite'
import Dropzone from 'react-dropzone'

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'

import { BootstrapTooltip } from '../../common/ui/tooltip'
import css from './files.module.scss'

const Files = (props: any) => {
    return (
        <>
            <AddEditFile {...props} />
            <UploadFile {...props} />

            <div className={css.main}>
                <header className={css.header}>
                    <h3>Файлы школы</h3>

                    <aside>
                        <div className='myInputTextPrimary' style={{ width: '350px' }} onClick={() => props.setFilter('')}>
                            <SearchOutlinedIcon />

                            <input type='text' placeholder='Фильтр...' autoComplete='off'
                                value={props.getFilter} onChange={e => props.setFilter(e.target.value)}
                            />

                            {props.getFilter 
                                ? <BootstrapTooltip title="Очистить" placement="bottom" arrow>
                                    <div className='myButtonIconPrimaryBorder'>
                                        <HighlightOffOutlinedIcon sx={{ fontSize: "24px" }} />
                                    </div>
                                </BootstrapTooltip> 
                                : ''
                            }
                        </div>

                        <div className='dividerPrimary' style={{ height: '40px', margin: 'auto 0' }}></div>

                        <div className='myButtonIconTextPrimarySmall' onClick={() => props.openAddEditFile(true, 'add')}>
                            <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_229_703)">
                                    <path d="M14.0833 8.08332H11.9166V12.4167H7.58329V14.5833H11.9166V18.9167H14.0833V14.5833H18.4166V12.4167H14.0833V8.08332ZM13 2.66666C7.01996 2.66666 2.16663 7.51999 2.16663 13.5C2.16663 19.48 7.01996 24.3333 13 24.3333C18.98 24.3333 23.8333 19.48 23.8333 13.5C23.8333 7.51999 18.98 2.66666 13 2.66666ZM13 22.1667C8.22246 22.1667 4.33329 18.2775 4.33329 13.5C4.33329 8.72249 8.22246 4.83332 13 4.83332C17.7775 4.83332 21.6666 8.72249 21.6666 13.5C21.6666 18.2775 17.7775 22.1667 13 22.1667Z" fill="currentColor" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_229_703">
                                        <rect width="26" height="26" fill="currentColor" transform="translate(0 0.5)" />
                                    </clipPath>
                                </defs>
                            </svg>

                            <span>Создать</span>
                        </div>
                    </aside>
                </header>

                <main className={css.content}>
                    <section className={css.tHead}>
                        <span id={css.name}>Раздел файла</span>
                        <span id={css.name}>Комментарий к файлу</span>
                        <span id={css.uid}>Дата создания</span>
                        <span id={css.btn}></span>
                    </section>

                    <section className={css.tBody}>
                        {!props.filesList.length
                            ? ''
                            : props.filesList.filter((val: any) => {
                                let stringReturn

                                let file_type_description = val.file_type_description.toLowerCase().includes(props.getFilter.toLowerCase())
                                let file_comment = val.file_comment.toLowerCase().includes(props.getFilter.toLowerCase())

                                if (file_type_description || file_comment) {
                                    stringReturn = val
                                }

                                return stringReturn
                            }).map((item: any) => {
                                return (
                                    <div key={item.file_id} className={css.tRow}>
                                        <span id={css.name}>{item.file_type_description}</span>
                                        <span id={css.name}>{item.file_comment}</span>
                                        <span id={css.uid}>{item.date_time_create}</span>
                                        <div id={css.btn}>
                                            {item.file_path && 
                                            <BootstrapTooltip title="Копировать ссылку в буфер обмена" placement="left" arrow>
                                            <div className='myButtonIconPrimaryBorder' onClick={() => props.copyLink(item.file_path)}>
                                                <LinkOutlinedIcon />
                                            </div>
                                        </BootstrapTooltip>
                                        
                                            }

                                            <BootstrapTooltip title="Добавить / Заменить файл" placement="bottom-end" arrow>
                                                <div className='myButtonIconPrimaryBorder' onClick={() => props.openUploadFile(true, item.file_id, item.file_name)}>
                                                    <CloudUploadOutlinedIcon />
                                                </div>
                                            </BootstrapTooltip>

                                            <BootstrapTooltip title="Редактировать" placement="bottom-end" arrow>
                                                <div className='myButtonIconPrimaryBorder' onClick={() => props.openAddEditFile(true, 'edit', item.file_id, item.file_comment, item.file_type_id)}>
                                                    <ModeEditOutlinedIcon />
                                                </div>
                                            </BootstrapTooltip>

                                        </div>
                                    </div>
                                )
                            })

                        }
                    </section>
                </main>

            </div>
        </>
    )
}

// Создание (Редактирование) файла
const AddEditFile = (props: any) => {
    return (
        <Modal backdrop="static" size="sm" autoFocus={false} open={props.getIsVisibleFiles} className='myModalPrimary'>
            <div className="modalContent">
                <div className="modalHead">
                    {
                        (props.getAction === '' && 'Создание / Редактирование файла...') || 
                        (props.getAction === 'add' && 'Создание файла...') || 
                        (props.getAction === 'edit' && 'Редактирование файла...')
                    }
                </div>

                <div className="modalBody">

                    {/* Комментарий к файлу */}
                    <div className={css.txtItem}>
                        <span className={css.itemHead}>Комментарий к файлу</span>
                        <div className='myInputTextPrimary'>
                            <input type='text' placeholder='Комментарий к файлу' autoComplete='off' 
                                value={props.getValueComment} onChange={e => props.setValueComment(e.target.value)}
                            />
                        </div>
                    </div>

                    {/* Раздел файла */}
                    <div className={css.txtItem}>
                        <span className={css.itemHead}>Раздел файла</span>

                        <div className={css.fileTypeItem}>
                            <div className={css.content}>
                                {props.fileTypesList.map((item: any) => {
                                    return (
                                        <div key={item.file_type_id} className={css.fileRow}>
                                            <input type="checkbox" 
                                                checked={props.getCheckedFileTypeID === item.file_type_id ? true : false} 
                                                onChange={() => props.setCheckedFileTypeID(item.file_type_id)}
                                            />

                                            <span>{item.file_type_description}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                </div>

                <div className="modalFooter">
                    <div className='myButtonSecondary' onClick={() => props.openAddEditFile(false)}>ОТМЕНА</div>
                    <div className='myButtonPrimary' onClick={() => props.applyButton()}>
                        {props.getAction === 'add' && 'ДОБАВИТЬ'}
                        {props.getAction === 'edit' && 'ПРИМЕНИТЬ'}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

// Добавление / Замена файла
const UploadFile = (props: any) => {
    return (
        <Modal backdrop="static" size="sm" autoFocus={false} open={props.getIsVisibleUploadFile} className='myModalPrimary'>
            <div className="modalContent">
                <div className="modalHead" style={{ display: 'grid', gridTemplateColumns: '1fr auto' }}>
                    <div>Добавить / Заменить файл...</div>
                </div>

                <div className="modalBody">
                    <Dropzone onDropAccepted={(files) => props.fileUploader(files)} multiple={false}>
                        {({ getRootProps, getInputProps, isDragAccept, isDragActive }) => (
                            <div {...getRootProps({ className: isDragAccept ? `myDropZone` : `` })} style={{ width: `100%`, height: '200px', cursor: 'pointer', color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '20px' }}>
                                <input {...getInputProps()} />
                                <div style={{ textAlign: 'center' }}>
                                    Перетащите файл в эту область<br/>Или кликните по данной области для выбора файла
                                </div>
                            </div>
                        )}
                    </Dropzone>
                </div>

                <div className='myButtonSecondary' onClick={() => props.openUploadFile(false)}>ОТМЕНА</div>
            </div>
        </Modal>
    )
}

export default Files