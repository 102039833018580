import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { AppStateType } from '../../../../bll/store'
import AddDeletePrograms from './addDeletePrograms'
import openNotification from '../../../common/ui/notification'
import errorHandler from '../../../../bll/errorHandler'
import { addProgramToCourse, clearPrograms, deleteProgramInCourse, getProgramsLK } from '../../../../bll/lkCourses/lkCoursesOperations'

const AddDeleteProgramsContainer = (props: any) => {
    const [selectedAddID, setSelectedAddID] = React.useState('') // Выбранный идентификатор дисциплины для добавления
    const [selectedDeleteID, setSelectedDeleteID] = React.useState('') // Выбранный идентификатор дисциплины для удаления


    React.useEffect(() => {
        if (props.getOpenAddDeletePrograms) {
            props.getProgramsLK(props.getEditableCourseID).then((result: any) => {
                switch (result?.code) {
                    case 'OK': break

                    case "ERR_QUERY_STRING": { return openNotification("Программы...", props.msg.err_0095, "error", "bottomRight") }
                    default: errorHandler(result?.code, props.msg); break
                }
            })
        }

        return () => {
            props.clearPrograms()
        }
    }, [props.getOpenAddDeletePrograms]) // eslint-disable-line react-hooks/exhaustive-deps


    // Добавление дисциплины в курс
    const eventAddToCourse = () => {
        props.addProgramToCourse(props.getEditableCourseID, selectedAddID).then((result: any) => {
            switch (result?.code) {
                case 'OK': 
                    setSelectedAddID('')
                break

                case "ERR_QUERY_STRING": { return openNotification("Добавление программы...", props.msg.err_0093, "error", "bottomRight") }
                default: errorHandler(result?.code, props.msg); break
            }
        })
    }

    // Удаление дисциплины из курса
    const eventDeleteInCourse = () => {
        props.deleteProgramInCourse(selectedDeleteID, props.getEditableCourseID).then((result: any) => {
            switch (result?.code) {
                case 'OK': 
                    setSelectedDeleteID('')
                break

                case "ERR_QUERY_STRING": { return openNotification("Удаление программы...", props.msg.err_0094, "error", "bottomRight") }
                default: errorHandler(result?.code, props.msg); break
            }
        })
    }


    // Свойства передаваемые в компоненту
    const propsToComponent = {
        selectedAddID, setSelectedAddID, // Выбранная дисциплина для добавления
        selectedDeleteID, setSelectedDeleteID, // Выбранная дисциплина для удаления

        eventAddToCourse, // Добавление дисциплины в курс
        eventDeleteInCourse, // Удаление дисциплины из курса
    }

    return (
        <AddDeletePrograms {...props} {...propsToComponent} />
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        programs: state.courses.programsList
    }
}

export default compose(connect(mapState, { 
    getProgramsLK, clearPrograms, addProgramToCourse, deleteProgramInCourse
}))(AddDeleteProgramsContainer)