import React from 'react'
import parse from 'html-react-parser'
import { NavLink } from 'react-router-dom'

const BlockSection07 = (props: any) => {
    return (
        <section>
            <article className='container LB07'>
                <h2>{parse(props.page.b07_text_1?.value || '')}</h2>

                <div className='content'>
                    {!props.commentsLandingList.length
                        ? ''
                        : props.commentsLandingList.map((item: any) => {
                            return (
                                <div className='item' key={item.comment_id}>
                                    <img src={item.img_path} alt={item.header} />

                                    <article>
                                        <h4>{item.header}</h4>
                                        <div>{parse(item.text || '')}</div>
                                    </article>
                                </div>
                            )
                        })
                    }
                </div>

                <NavLink to='/comments' className='buttonSecondary_2' target='_blank'>Смотреть все отзывы</NavLink>
            </article>
        </section>
    )
}

export default BlockSection07