import React from 'react'
import parse from 'html-react-parser'

const Section02 = (props: any) => {
    return (
        <section>
            <div className='container LB02'>
                <img src={props.page.b02_img_1_path?.value} alt={props.page.b02_text_1?.value?.replace(/<[^>]+>/g, '')} />

                <article>
                    <h2>{parse(props.page.b02_text_1?.value || '')}</h2>
                    <div>{parse(props.page.b02_text_2?.value || '')}</div>
                </article>
            </div>
        </section>
    )
}

export default Section02