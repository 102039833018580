import openNotification from "../components/common/ui/notification"

const errorHandler = (err: any, msg: any) => {
    switch (err) {
        case "ERR_NETWORK":
        case "ERR_UNEXPECTED": 
        case "ERR_SERVER_AVAILABLE": { // Сервер не доступен
            return openNotification("Сервер не доступен...", msg.err_0001, "error", "bottomRight") 
        }

        case "ERR_NOT_AUTHORIZED": openNotification("Вы не авторизованы...", msg.err_0002, "error", "bottomRight"); break
        case "ERR_QUERY_DUPLICATE": openNotification("Дублирование записи...", msg.err_0003, "error", "bottomRight"); break
        case "NULL": { return openNotification("Сервер не ответил...", msg.err_code, "error", "bottomRight") }
    }
}

export default errorHandler