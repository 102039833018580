import React from 'react'
// import { Image } from 'antd'

import css from './nav-09.module.scss'

const Nav_09 = (props: any) => {
    return (
        <section className={css.main}>
            <h5>Платные образовательные услуги</h5>

            <span>Платные образовательные услуги представлены на сайте в разделе «Курсы школы».</span>
        </section>
    )
}

export default Nav_09