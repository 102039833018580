import types from './authTypes'

const setAppInitialized = (isAppInitialized: boolean) => ({ type: types.AUTH_SET_APP_INITIALIZED, isAppInitialized })

const setLogout = () => ({ type: types.AUTH_SET_LOGOUT })
const setIsAuthorized = (isAuthorized: boolean) => ({ type: types.AUTH_SET_IS_AUTHORIZED, isAuthorized })

const setLoginProfile = (login: string, lastName: string, firstName: string, secondName: string, accountCreateDate: string, avatar: string) => ({ 
    type: types.AUTH_SET_MY_PROFILE, login, lastName, firstName, secondName, accountCreateDate, avatar
})

const authActions = {
    setAppInitialized,
    
    setLogout,
    setIsAuthorized,
    setLoginProfile,
}

export default authActions