import api from './api'

export default class ApiLkCourses {

    // Получение списка всех курсов
    static async getCourses(): Promise<any> {
        try {
            return await api.get('/courses/getCourses')
        } catch (error) {
            return error
        }
    }

    // Создание курса
    static async createCourses(courseName: string): Promise<any> {
        try {
            return await api.post(`/courses/createCourses?courseName=${courseName}`)
        } catch (error) {
            return error
        }
    }



    // Получение информации о курсе (Редактирование курса - Страница Информация о курсе)
    static async getCourseInfo(courseID: number): Promise<any> {
        try {
            return await api.get(`/courses/getCourseInfo?courseID=${courseID}`)
        } catch (error) {
            return error
        }
    }

    // Редактирование информации о курсе (Редактирование курса - Страница Информация о курсе)
    static async editCourseInfo(courseID: number, fieldName: string, fieldValue: any): Promise<any> {
        try {
            return await api.put(`/courses/editCourseInfo?courseID=${courseID}&fieldName=${fieldName}`, {fieldValue}, { headers: { "Content-Type": "multipart/form-data" } })
        } catch (error) {
            return error
        }
    }

    // Загрузка файла (Редактирование курса - Страница Информация о курсе)
    static async uploadFileCourseInfo(file: any, courseID: number, previousFileName: string, fileName: string): Promise<any> {
        const formData = new FormData()
        formData.append("logoCourse", file)

        try {
            return await api.put(`/courses/uploadFileCourseInfo?courseID=${courseID}&previousFileName=${previousFileName}&fileName=${fileName}`, formData, { headers: { "Content-Type": `multipart/form-data` } })
        } catch (error) {
            return error
        }
    }



    // Изменение данных страницы курса (Редактирование курса - Страница Контент курса)
    static async editCoursePageData(courseID: string, action: string, blockNum: string, blockName: string, blockType: string, blockValue: any): Promise<any> {
        try {
            return await api.put(`/courses/editCoursePageData?courseID=${courseID}&action=${action}&blockNum=${blockNum}&blockName=${blockName}&blockType=${blockType}`, {blockValue}, { headers: { 'Content-Type': 'application/json' } })
        } catch (error) {
            return error
        }
    }

    // Загрузка файла (Редактирование курса - Страница Контент ккурса)
    static async uploadFileCoursePageData(file: any, courseID: string, previousFileName: string, fileName: string, blockNum: string, blockName: string): Promise<any> {
        const formData = new FormData()
        formData.append("file", file)

        try {
            return await api.put(`/courses/uploadFileCoursePageData?courseID=${courseID}&previousFileName=${previousFileName}&fileName=${fileName}&blockName=${blockName}&blockNum=${blockNum}`, formData, { headers: { "Content-Type": `multipart/form-data` } })
        } catch (error) {
            return error
        }
    }


    // Получение списка не добавленных и добавленных программ (Редактирование курса - Страница Контент курса)
    static async getPrograms(courseID: string): Promise<any> {
        try {
            return await api.get(`/courses/getPrograms?courseID=${courseID}`)
        } catch (error) {
            return error
        }
    }

    // Добавление программы в курс (Редактирование курса - Страница Контент курса)
    static async addProgramToCourse(courseID: string, disciplineID: string): Promise<any> {
        try {
            return await api.post(`/courses/addProgramToCourse?courseID=${courseID}&disciplineID=${disciplineID}`)
        } catch (error) {
            return error
        }
    }

    // Удаление программы из курса (Редактирование курса - Страница Контент курса)
    static async deleteProgramInCourse(cpdID: string, courseID: string): Promise<any> {
        try {
            return await api.delete(`/courses/deleteProgramInCourse?courseID=${courseID}&cpdID=${cpdID}`)
        } catch (error) {
            return error
        }
    }


    // Получение списка не добавленных и добавленных отзывов (Редактирование курса - Страница Контент курса)
    static async getComments(courseID: string): Promise<any> {
        try {
            return await api.get(`/courses/getComments?courseID=${courseID}`)
        } catch (error) {
            return error
        }
    }

    // Добавление отзыва в курс (Редактирование курса - Страница Контент курса)
    static async addCommentToCourse(courseID: string, commentID: string): Promise<any> {
        try {
            return await api.post(`/courses/addCommentToCourse?courseID=${courseID}&commentID=${commentID}`)
        } catch (error) {
            return error
        }
    }

    // Удаление отзыва из курса (Редактирование курса - Страница Контент курса)
    static async deleteCommentInCourse(dccID: string, courseID: string): Promise<any> {
        try {
            return await api.delete(`/courses/deleteCommentInCourse?courseID=${courseID}&dccID=${dccID}`)
        } catch (error) {
            return error
        }
    }


    // Получение списка не добавленных и добавленных вопросов (Редактирование курса - Страница Контент курса)
    static async getQuestions(courseID: string): Promise<any> {
        try {
            return await api.get(`/courses/getQuestions?courseID=${courseID}`)
        } catch (error) {
            return error
        }
    }

    // Добавление вопроса в курс (Редактирование курса - Страница Контент курса)
    static async addQuestionToCourse(courseID: string, questionID: string): Promise<any> {
        try {
            return await api.post(`/courses/addQuestionToCourse?courseID=${courseID}&questionID=${questionID}`)
        } catch (error) {
            return error
        }
    }

    // Удаление вопроса из курса (Редактирование курса - Страница Контент курса)
    static async deleteQuestionInCourse(dcqID: string, courseID: string): Promise<any> {
        try {
            return await api.delete(`/courses/deleteQuestionInCourse?courseID=${courseID}&dcqID=${dcqID}`)
        } catch (error) {
            return error
        }
    }
    
}