import React from 'react'
import parse from 'html-react-parser'

import MyHeader from '../common/header/header'
import FooterContainer from '../common/footer/footerContainer'

import css from './comments.module.scss'

const Comments = (props: any) => {
    return (
        <>
            <MyHeader {...props} />

            <main className={`container ${css.main}`}>
                <article>
                    <h3>Отзывы наших выпускников</h3>
                </article>

                <div className={css.container}>
                    {!props.commentsAll.length
                        ? ''
                        : props.commentsAll.map((item: any) => {
                            return (
                                <div className={css.item} key={item.comment_id}>
                                    <img src={item.img_path} alt={item.header} />

                                    <article>
                                        <h4>{item.header}</h4>
                                        <div>{parse(item.text || '')}</div>
                                    </article>
                                </div>
                            )
                        })
                    }
                </div>
            </main>

            <FooterContainer {...props} />
        </>
    )
}

export default Comments