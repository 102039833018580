import React from 'react'
// import { Image } from 'antd'

import css from './nav-13.module.scss'

const Nav_13 = (props: any) => {
    return (
        <section className={css.main}>
            <h5>Международное сотрудничество</h5>

            <span>ООО «ИКТ +» не участвует в международной образовательной деятельности.</span>
        </section>
    )
}

export default Nav_13