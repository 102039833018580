import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { AppStateType } from '../../bll/store'
import Organization from './organization'
import { organizationInitialized } from '../../bll/common/commonOperations'

const OrganizationContainer = (props: any) => {
    const [getIsVisibleMenu, setIsVisibleMenu] = React.useState(false) // Видимость меню

    React.useEffect(() => {
        props.organizationInitialized()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Свойства передаваемые в компоненту
    const propsToComponent = {
        getIsVisibleMenu, setIsVisibleMenu, // Видимость меню
    }

    return (
        <Organization {...props} {...propsToComponent} />
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        setting: state.common.settings,
        files: state.common.otherFiles
    }
}

export default compose(connect(mapState, { organizationInitialized }))(OrganizationContainer)