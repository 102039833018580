import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { AppStateType } from '../../bll/store'
import Education from './education'
import { educationInitialized } from '../../bll/common/commonOperations'

const EducationContainer = (props: any) => {
    React.useEffect(() => {
        props.educationInitialized()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Education {...props} />
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        setting: state.common.settings,
        files: state.common.otherFiles
    }
}

export default compose(connect(mapState, { educationInitialized }))(EducationContainer)