import React from 'react'
import hexRgb from 'hex-rgb'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useColor } from "react-color-palette"

import { AppStateType } from '../../../../bll/store'
import { RGBToHSL } from '../../../../bll/selectors'
import { courseInitialized } from '../../../../bll/common/commonOperations'
import CourseContent from './courseContent'
import openNotification from '../../../common/ui/notification'
import errorHandler from '../../../../bll/errorHandler'
import { editCoursePageData, uploadFileCoursePageData } from '../../../../bll/lkCourses/lkCoursesOperations'

const CourseContentContainer = (props: any) => {
    const [getOpenEditTextPrimary, setOpenEditTextPrimary] = React.useState(false) // Видимость окна редактирования текста стандарт
    const [getOpenEditTextSecondary, setOpenEditTextSecondary] = React.useState(false) // Видимость окна редактирования текста расширенное
    const [getOpenEditImage, setOpenEditImage] = React.useState(false) // Видимость окна редактирования изображения
    const [getOpenEditColor, setOpenEditColor] = React.useState(false) // Видимость окна редактирования цвета
    
    const [getOpenAddDeletePrograms, setOpenAddDeletePrograms] = React.useState(false) // Видимость окна добавления / удаления программ
    const [getOpenAddDeleteComments, setOpenAddDeleteComments] = React.useState(false) // Видимость окна добавления / удаления отзывов
    const [getOpenAddDeleteQuestions, setOpenAddDeleteQuestions] = React.useState(false) // Видимость окна добавления / удаления вопросов

    const [getBlockNum, setBlockNum] = React.useState('') // Номер редактируемого блока
    const [getBlockName, setBlockName] = React.useState('') // Имя редактируемого блока
    const [getBlockType, setBlockType] = React.useState('') // Тип редактируемого блока
    const [getBlockValue, setBlockValue] = React.useState('') // Значение редактируемого блока
    const [getImageName, setImageName] = React.useState('') // Имя редактируемого изображения
    const [getColor, setColor] = useColor("hex", '#000000') // Выбранный цвет из палитры

    React.useEffect(() => {
        props.getIsOpenCourseContent && props.courseInitialized(props.getEditableCourseID)
    }, [props.getIsOpenCourseContent]) // eslint-disable-line react-hooks/exhaustive-deps



    // Открытие (Закрытие) окна редактирования текста стандарт
    const openEditTextPrimary = (isOpen: boolean, blockNum: string = '', blockName: string = '', blockType: string = '', blockValue: string = '') => {
        setBlockNum(blockNum)
        setBlockName(blockName)
        setBlockType(blockType)
        setBlockValue(blockValue)

        setOpenEditTextPrimary(isOpen)
    }

    // Открытие (Закрытие) окна редактирования текста расширенное
    const openEditTextSecondary = (isOpen: boolean, blockNum: string = '', blockName: string = '', blockType: string = '', blockValue: string = '') => {
        setBlockNum(blockNum)
        setBlockName(blockName)
        setBlockType(blockType)
        setBlockValue(blockValue)

        setOpenEditTextSecondary(isOpen)
    }

    // Открытие (Закрытие) окна редактирования изображения
    const openEditImage = (isOpen: boolean, blockNum: string = '', blockName: string = '', blockType: string = '', blockValue: string = '', imageName: string = '') => {
        setBlockNum(blockNum)
        setBlockType(blockType)
        setBlockName(blockName)
        setBlockValue(blockValue)

        setImageName(imageName)

        setOpenEditImage(isOpen)
    }
    
    // Открытие (Закрытие) окна редактирования цвета
    const openEditColor = (isOpen: boolean, blockNum: string = '', blockName: string = '', blockType: string = '', blockValue: string = '') => {
        let bgColor = '#000000'

        if (isOpen) { // Открытие окна
            bgColor = blockValue === 'transparent' ? '#ffffff' : blockValue
        }

        let rgba = hexRgb(bgColor)
        let r = rgba.red; let g = rgba.green; let b = rgba.blue

        let hsl = RGBToHSL(r, g, b)
        let h = hsl[0]; let s = hsl[1]; let v = hsl[2]

        setColor({ hex: bgColor, rgb: { r: r, g: g, b: b, a: undefined }, hsv: { h: h, s: s, v: v, a: undefined } })

        setBlockNum(blockNum)
        setBlockType(blockType)
        setBlockName(blockName)

        setOpenEditColor(isOpen)
    }



    // Кнопка применить в окнах редактирования блоков
    const applyData = () => {
        let newBlockValue = getBlockValue // Присваиваем значение в новую переменную

        switch (getBlockType) {
            case 'color_text':
            case 'color_button':
            case 'color_background':
                newBlockValue = getColor.hex
            break;
        }

        props.editCoursePageData(props.getEditableCourseID, 'update', getBlockNum, getBlockName, getBlockType, newBlockValue).then((result: any) => {
            switch (result?.code) {
                case "OK":  
                    openEditTextPrimary(false)
                    openEditTextSecondary(false)
                    openEditImage(false)
                    openEditColor(false)
                break

                case "ERR_QUERY_STRING": { return openNotification("Изменение данных курса...", props.msg.err_0060, "error", "bottomRight") }
                default: errorHandler(result?.code, props.msg); break
            }
        })
    }

    // Кнопка удалить в окнах редактирования блоков
    const deleteData = () => {
        props.editCoursePageData(props.getEditableCourseID, 'delete', getBlockNum, getBlockName, getBlockType, getImageName).then((result: any) => {
            switch (result?.code) {
                case "OK": 
                    if (getBlockType === 'image') {
                        setBlockNum('')
                        setBlockType('')
                        setBlockName('')
                        setBlockValue('')
                
                        setImageName('')
                
                        setOpenEditImage(false)
                    }

                    setBlockValue('')
                    setOpenEditColor(false)
                break

                case "ERR_QUERY_STRING": { return openNotification("Удаление данных курса...", props.msg.err_0060, "error", "bottomRight") }
                default: errorHandler(result?.code, props.msg); break
            }
        })
    }

    // Функция по загрузке файлов на сервер
    const fileUploader = (fileList: any) => {
        fileList.forEach((file: any) => {
            props.uploadFileCoursePageData(file, props.getEditableCourseID, getImageName, file.name, getBlockNum, getBlockName).then((result: any) => {
                switch (result?.code) {
                    case 'OK': 
                        openEditImage(false)
                    break

                    case "ERR_QUERY_STRING": { return openNotification("Ошибка загрузки файла...", props.msg.err_0054, "error", "bottomRight") }
                    case "ERR_FILE_NOT_FOUND": { return openNotification("Ошибка загрузки файла...", props.msg.err_0055, "error", "bottomRight") }
                    case "ERR_FILE_EXTNAME": { return openNotification("Ошибка загрузки файла...", props.msg.err_0056, "error", "bottomRight") }
                    case "ERR_FILE_SIZE": { return openNotification("Ошибка загрузки файла...", props.msg.err_0057, "error", "bottomRight") }
                    default: errorHandler(result?.code, props.msg); break
                }
            })
        })
    }

    // Функция изменения активности блока
    const onChangeBlockActive = (blockNum: string, blockName: string, blockType: string, isChecked: boolean) => {
        props.editCoursePageData(props.getEditableCourseID, 'update', blockNum, blockName, blockType, isChecked).then((result: any) => {
            switch (result?.code) {
                case "OK":  break
                case "ERR_QUERY_STRING": { return openNotification("Изменение активности блока...", props.msg.err_0068, "error", "bottomRight") }
                default: errorHandler(result?.code, props.msg); break
            }
        })
    }




    // Свойства передаваемые в компоненту
    const propsToComponent = {
        openEditTextPrimary, // Открытие (Закрытие) окна редактирования текста стандарт
        openEditTextSecondary, // Открытие (Закрытие) окна редактирования текста расширенное
        openEditImage, // Открытие (Закрытие) окна редактирования изображения
        openEditColor, // Открытие (Закрытие) окна редактирования цвета

        getOpenAddDeletePrograms, setOpenAddDeletePrograms, // Видимость окна добавления / удаления программ
        getOpenAddDeleteComments, setOpenAddDeleteComments, // Видимость окна добавления / удаления отзывов
        getOpenAddDeleteQuestions, setOpenAddDeleteQuestions, // Видимость окна добавления / удаления вопросов
    
        applyData, // Кнопка применить в окнах редактирования блоков
        deleteData, // Кнопка удалить в окнах редактирования блоков
        fileUploader, // Функция по загрузке файлов на сервер
        onChangeBlockActive, // Функция изменения активности блока

        getOpenEditTextPrimary, // Видимость окна редактирования текста стандарт
        getOpenEditTextSecondary, // Видимость окна редактирования текста расширенное
        getOpenEditImage, // Видимость окна редактирования изображения
        getOpenEditColor, // Видимость окна редактирования цвета

        getBlockValue, setBlockValue, // Значение редактируемого блока
        getColor, setColor, // Выбранный цвет из палитры
    }

    return (
        <CourseContent {...props} {...propsToComponent} />
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        programList: state.common.programList,
        commentsCourseList: state.common.commentsCourseList,
        questionsList: state.common.questionsList,
        page: state.common.courseData,
        setting: state.common.settings,
        files: state.common.otherFiles
    }
}

export default compose(connect(mapState, { 
    courseInitialized, editCoursePageData, uploadFileCoursePageData 
}))(CourseContentContainer)