import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { AppStateType } from '../../../bll/store'
import Comments from './comments'
import { clearComments, createComments, editComments, getComments, uploadFile } from '../../../bll/lkComments/lkCommentsOperations'
import openNotification from '../../common/ui/notification'
import errorHandler from '../../../bll/errorHandler'

const CommentsContainer = (props: any) => {
    const [getIsVisibleComments, setIsVisibleComments] = React.useState(false) // Видимость окна создания (редактирования) отзыва
    const [getIsVisibleImage, setIsVisibleImage] = React.useState(false) // Видимость окна редактирования картинки отзыва
    
    const [getActionComments, setActionComments] = React.useState('') // Действие (создание или редактирование)

    const [getValueCommentID, setValueCommentID] = React.useState('') // Идентификатор отзыва
    const [getValueCommentHead, setValueCommentHead] = React.useState('') // Заголовок отзыва в окне создания (редактирования)
    const [getValueCommentText, setValueCommentText] = React.useState('') // Текст отзыва в окне создания (редактирования)
    const [getValueImgPath, setValueImgPath] = React.useState('') // Путь редактируемого изображения
    const [getValueImgName, setValueImgName] = React.useState('') // Имя редактируемого изображения

    const [getFilter, setFilter] = React.useState('') // Фильтр отзывов


    React.useEffect(() => {
        props.getComments().then((result: any) => {
            switch (result?.code) {
                case 'OK': break
                case "ERR_QUERY_STRING": { return openNotification("Список отзывов...", props.msg.err_0069, "error", "bottomRight") }
                default: errorHandler(result?.code, props.msg); break
            }
        })

        return () => {
            props.clearComments()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    // Открытие (закрытие) окна создания (редактирования) отзыва
    const openAddEditComment = (isOpen: boolean, action: string = '', id: string = '', name: string = '', description: string = '') => {
        setValueCommentID(id)
        setValueCommentHead(name)
        setValueCommentText(description)

        setActionComments(action)
        setIsVisibleComments(isOpen)
    }

    // Открытие (закрытие) окна редактирования картинки отзыва
    const openImageEdit = (isOpen: boolean, id: string = '', imgPath: string = '', imgName: string = '') => {
        setValueCommentID(id)
        setValueImgPath(imgPath)
        setValueImgName(imgName)

        setIsVisibleImage(isOpen)
    }

    // Кнопка применить в окне создания (редактирования) отзыва
    const applyButton = () => {
        if (getActionComments === 'add') { // Создание отзыва
            props.createComments(getValueCommentHead, getValueCommentText).then((result: any) => {
                switch (result?.code) {
                    case "OK":  
                        openAddEditComment(false)
                    break

                    case "ERR_QUERY_STRING": { return openNotification("Создание отзыва...", props.msg.err_0070, "error", "bottomRight") }
                    default: errorHandler(result?.code, props.msg); break
                }
            })
        }

        if (getActionComments === 'edit') { // Редактирование отзыва
            props.editComments(getValueCommentID, getValueCommentHead, getValueCommentText).then((result: any) => {
                switch (result?.code) {
                    case "OK":  
                        openAddEditComment(false)
                    break

                    case "ERR_QUERY_STRING": { return openNotification("Редактирование отзыва...", props.msg.err_0071, "error", "bottomRight") }
                    default: errorHandler(result?.code, props.msg); break
                }
            })
        }
    }

    // Функция по загрузке файлов на сервер
    const fileUploader = (fileList: any, previousFileName: string) => {
        fileList.forEach((file: any) => {
            props.uploadFile(file, getValueCommentID, previousFileName, file.name).then((result: any) => {
                switch (result?.code) {
                    case "OK": 
                        openImageEdit(false)
                    break

                    case "ERR_QUERY_STRING": { return openNotification("Ошибка загрузки файла...", props.msg.err_0054, "error", "bottomRight") }
                    case "ERR_FILE_NOT_FOUND": { return openNotification("Ошибка загрузки файла...", props.msg.err_0055, "error", "bottomRight") }
                    case "ERR_FILE_EXTNAME": { return openNotification("Ошибка загрузки файла...", props.msg.err_0056, "error", "bottomRight") }
                    case "ERR_FILE_SIZE": { return openNotification("Ошибка загрузки файла...", props.msg.err_0057, "error", "bottomRight") }
                    default: errorHandler(result?.code, props.msg); break
                }
            })
        })
    }
    
    // Свойства передаваемые в компоненту
    const propsToComponents = {
        getIsVisibleComments, // Видимость окна создания (редактирования) отзыва
        openAddEditComment, // Открытие (закрытие) окна создания (редактирования) отзыва
        getActionComments, // Действие (создание или редактирование)
        applyButton, // Кнопка применить в окне создания (редактирования) отзыва

        getValueCommentHead, setValueCommentHead, // Заголовок отзыва в окне создания (редактирования)
        getValueCommentText, setValueCommentText, // Текст отзыва в окне создания (редактирования)

        getFilter, setFilter, // Фильтр отзывов

        getIsVisibleImage, // Видимость окна редактирования картинки отзыва
        getValueImgPath, getValueImgName, // Путь и имя редактируемого изображения
        openImageEdit, // Открытие (закрытие) окна редактирования картинки отзыва
        fileUploader, // Функция по загрузке файлов на сервер
    }


    return (
        <Comments {...props} {...propsToComponents} />
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        commentsList: state.comments.commentsList,
    }
}

export default compose(connect(mapState, { 
    getComments, clearComments, createComments, editComments, uploadFile
}))(CommentsContainer)