import api from './api'

export default class ApiLkComments {

    // Получение отзывов
    static async getComments(): Promise<any> {
        try {
            return await api.get('/comments/getComments')
        } catch (error) {
            return error
        }
    }

    // Создание отзывов
    static async createComments(commentHeader: string, commentText: string): Promise<any> {
        try {
            return await api.post(`/comments/createComments?commentHeader=${commentHeader}&commentText=${commentText}`)
        } catch (error) {
            return error
        }
    }

    // Редактирование отзывов
    static async editComments(commentID: string, commentHeader: string, commentText: string): Promise<any> {
        try {
            return await api.put(`/comments/editComments?commentID=${commentID}&commentHeader=${commentHeader}&commentText=${commentText}`)
        } catch (error) {
            return error
        }
    }
 
    // Загрузка файла
    static async uploadFile(file: any, commentID: number, previousFileName: string, fileName: string): Promise<any> {
        const formData = new FormData()
        formData.append("file", file)

        try {
            return await api.put(`/comments/uploadFile?commentID=${commentID}&previousFileName=${previousFileName}&fileName=${fileName}`, formData, { headers: { "Content-Type": `multipart/form-data` } })
        } catch (error) {
            return error
        }
    }

}