import React from 'react'
import parse from 'html-react-parser'

const Section01 = (props: any) => {
    return (
        <section>
            <div className='container CB01' style={{ background: `${props.page.b01.bg_color} url(${props.page.b01.bg_img_path}) no-repeat center/100% 100%` }}>
                <div className='block'>
                    <div className='content'>
                        <article>
                            <h1>{parse(props.page.b01.text_1 || '')}</h1>
                            <h2>{parse(props.page.b01.text_2 || '')}</h2>
                        </article>

                        <aside>
                            <a href='#formats' className='buttonPrimary'>Записаться на курс</a>
                            <a href='#question' className='buttonSecondary'>Задать вопрос</a>
                        </aside>

                        <h4>{`${props.page.b01.text_3} ${new Date(props.page.b01.date_start).toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric' })}`}</h4>
                    </div>

                    <img src={props.page.b01.img_1_path} alt={props.page.b01.text_1?.replace(/<[^>]+>/g, '')} />
                </div>
            </div>
        </section>
    )
}

export default Section01