import api from './api'

export default class ApiLkQuestions {

    // Получение вопросов
    static async getQuestions(): Promise<any> {
        try {
            return await api.get('/questions/getQuestions')
        } catch (error) {
            return error
        }
    }

    // Создание вопросов
    static async createQuestions(header: string, text: string): Promise<any> {
        try {
            return await api.post(`/questions/createQuestions?header=${header}&text=${text}`)
        } catch (error) {
            return error
        }
    }

    // Редактирование вопросов
    static async editQuestions(questionID: string, header: string, text: string): Promise<any> {
        try {
            return await api.put(`/questions/editQuestions?questionID=${questionID}&header=${header}&text=${text}`)
        } catch (error) {
            return error
        }
    }

    // Удаление вопросов
    static async deleteQuestions(questionID: string): Promise<any> {
        try {
            return await api.delete(`/questions/deleteQuestions?questionID=${questionID}`)
        } catch (error) {
            return error
        }
    }

}