import React from 'react'
// import { Image } from 'antd'

import css from './nav-05.module.scss'

const Nav_05 = (props: any) => {
    return (
        <section className={css.main}>
            <h5>Образовательные стандарты</h5>

            <p>При обучении Слушателей используются ФГОСы и Профессиональные стандарты.</p>
        </section>
    )
}

export default Nav_05