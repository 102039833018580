import React from 'react'
import parse from 'html-react-parser'

import { Collapse } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import ExposureOutlinedIcon from '@mui/icons-material/ExposureOutlined'

import { BootstrapTooltip } from '../../../common/ui/tooltip'

const { Panel } = Collapse

const Section05 = (props: any) => {
    return (
        <section id='program'>
            <article className='container CB05'>
                <BootstrapTooltip title="Редактировать" placement="top" arrow>
                    <h2 className='itemHover' onClick={() => props.openEditTextSecondary(true, '05', 'text_1', 'text', props.page.b05.text_1)}>
                        {parse(props.page.b05.text_1 || 'Редактировать')}
                    </h2>
                </BootstrapTooltip>

                <Collapse accordion bordered={false} className='items' expandIconPosition='end' expandIcon={({ isActive }) => <PlusOutlined style={{ color: `#FF9900`, fontSize: '26px' }} rotate={isActive ? -45 : 0} />}>
                    {!props.programList.length
                        ? ''
                        : props.programList.map((item: any) => {
                            return (
                                <Panel header={`${item.discipline_number}. ${item.discipline_name}`} id={item.cpd_id} key={item.cpd_id} className='item'>
                                    {parse(item.discipline_description || '')}
                                </Panel>
                            )
                        })
                    }
                </Collapse>

                <section className='myPopoverVertical'>
                    <h5>Блок № 5</h5>
                    
                    <div className='dividerPrimary' style={{ height: '40px' }}></div>
    
                    <BootstrapTooltip title="Видимость блока" placement="bottom" arrow>
                        <input type="checkbox"
                            checked={props.page.b05.is_active === 1 ? true : false}
                            onChange={(e) => props.onChangeBlockActive('05', 'is_active', 'isActive', e.target.checked)}
                        />
                    </BootstrapTooltip>

                    <div className='dividerPrimary' style={{ height: '40px' }}></div>

                    <BootstrapTooltip title="Добавить / Удалить программу" placement="bottom" arrow>
                        <div className="myButtonIconSecondaryBorder" onClick={() => props.setOpenAddDeletePrograms(true)}>
                            <ExposureOutlinedIcon />
                        </div>
                    </BootstrapTooltip>
                </section>
            </article>
        </section>
    )
}

export default Section05