import React from 'react'
import parse from 'html-react-parser'

import img01 from './../../css/img/courseB04_img01.png'
import img02 from './../../css/img/courseB04_img02.png'
import img03 from './../../css/img/courseB04_img03.png'
import img04 from './../../css/img/courseB04_img04.png'
import img05 from './../../css/img/courseB04_img05.png'
import img06 from './../../css/img/courseB04_img06.png'

const Section04 = (props: any) => {
    return (
        <section>
            <article className='container CB04'>
                <h2>{parse(props.page.b04.text_1 || '')}</h2>

                <div className='items'>
                    <div className='item'>
                        <img src={img01} alt={props.page.b04.text_2?.replace(/<[^>]+>/g, '')} />
                        <h6>{parse(props.page.b04.text_2 || '')}</h6>
                        <div>{parse(props.page.b04.text_3 || '')}</div>
                    </div>

                    <div className='item'>
                        <img src={img02} alt={props.page.b04.text_4?.replace(/<[^>]+>/g, '')} />
                        <h6>{parse(props.page.b04.text_4 || '')}</h6>
                        <div>{parse(props.page.b04.text_5 || '')}</div>
                    </div>

                    <div className='item'>
                        <img src={img03} alt={props.page.b04.text_6?.replace(/<[^>]+>/g, '')} />
                        <h6>{parse(props.page.b04.text_6 || '')}</h6>
                        <div>{parse(props.page.b04.text_7 || '')}</div>
                    </div>

                    <div className='item'>
                        <img src={img04} alt={props.page.b04.text_8?.replace(/<[^>]+>/g, '')} />
                        <h6>{parse(props.page.b04.text_8 || '')}</h6>
                        <div>{parse(props.page.b04.text_9 || '')}</div>
                    </div>

                    {props.page.b04.text_10
                        && <div className='item'>
                            <img src={img05} alt={props.page.b04.text_10?.replace(/<[^>]+>/g, '')} />
                            <h6>{parse(props.page.b04.text_10 || '')}</h6>
                            <div>{parse(props.page.b04.text_11 || '')}</div>
                        </div>
                    }

                    {props.page.b04.text_12
                        && <div className='item'>
                            <img src={img06} alt={props.page.b04.text_12?.replace(/<[^>]+>/g, '')} />
                            <h6>{parse(props.page.b04.text_12 || '')}</h6>
                            <div>{parse(props.page.b04.text_13 || '')}</div>
                        </div>
                    }
                </div>
            </article>
        </section>
    )
}

export default Section04