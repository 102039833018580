import React from 'react'
// import { Image } from 'antd'

import css from './nav-10.module.scss'

const Nav_10 = (props: any) => {
    return (
        <section className={css.main}>
            <h5>Финансово-хозяйственная деятельность</h5>

            <span>Результаты финансово-хозяйственной деятельности определяются за каждый календарный год по данным бухгалтерского учета и отчетности.</span>

            <div className={css.table}>
                <div className={css.tRow} style={{ fontWeight: '600' }}>
                    <span id={css.center}>№</span>
                    <span>Наименование показателя</span>
                    <span id={css.center}>Плановое значение показателя, %</span>
                </div>

                <div className='dividerVerticalSecondary'></div>

                <div className={css.tRow} style={{ fontWeight: '600' }}>
                    <span id={css.center}>1</span>
                    <span>Доходы</span>
                    <span id={css.center}>100</span>
                </div>

                <div className='dividerVerticalSecondary'></div>

                <div className={css.tRow}>
                    <span id={css.center}>1.1</span>
                    <span>Обучение по программам профессиональной переподготовки</span>
                    <span id={css.center}>49</span>
                </div>

                <div className='dividerVerticalSecondary'></div>

                <div className={css.tRow}>
                    <span id={css.center}>1.2</span>
                    <span>Обучение по программам повышения квалификации</span>
                    <span id={css.center}>48</span>
                </div>

                <div className='dividerVerticalSecondary'></div>

                <div className={css.tRow}>
                    <span id={css.center}>1.3</span>
                    <span>Экспертно-консультационная деятельность</span>
                    <span id={css.center}>1</span>
                </div>

                <div className='dividerVerticalSecondary'></div>

                <div className={css.tRow}>
                    <span id={css.center}>1.4</span>
                    <span>Редакционно-издательская деятельность, разработка программ</span>
                    <span id={css.center}>1</span>
                </div>

                <div className='dividerVerticalSecondary'></div>

                <div className={css.tRow}>
                    <span id={css.center}>1.5</span>
                    <span>Разработка обучающего материала для использования в собственном учебном процессе и (или) для реализации физическим и (или) юридическим лицам по отдельным договорам</span>
                    <span id={css.center}>0,5</span>
                </div>

                <div className='dividerVerticalSecondary'></div>

                <div className={css.tRow} style={{ fontWeight: '600' }}>
                    <span id={css.center}>2</span>
                    <span>Расходы</span>
                    <span id={css.center}>100</span>
                </div>

                <div className='dividerVerticalSecondary'></div>

                <div className={css.tRow}>
                    <span id={css.center}>2.1</span>
                    <span>Оплата труда</span>
                    <span id={css.center}>65,2</span>
                </div>

                <div className='dividerVerticalSecondary'></div>

                <div className={css.tRow}>
                    <span id={css.center}>2.2</span>
                    <span>Услуги связи</span>
                    <span id={css.center}>3</span>
                </div>

                <div className='dividerVerticalSecondary'></div>

                <div className={css.tRow}>
                    <span id={css.center}>2.3</span>
                    <span>Реклама</span>
                    <span id={css.center}>21</span>
                </div>

                <div className='dividerVerticalSecondary'></div>

                <div className={css.tRow}>
                    <span id={css.center}>2.4</span>
                    <span>Приобретение бланков строгой отчетности</span>
                    <span id={css.center}>2</span>
                </div>

                <div className='dividerVerticalSecondary'></div>

                <div className={css.tRow}>
                    <span id={css.center}>2.5</span>
                    <span>Налоги</span>
                    <span id={css.center}>5,8</span>
                </div>

                <div className='dividerVerticalSecondary'></div>

                <div className={css.tRow}>
                    <span id={css.center}>2.6</span>
                    <span>Прочие расходы</span>
                    <span id={css.center}>3</span>
                </div>
            </div>
        </section>
    )
}

export default Nav_10