export const getTypeDay = (day: number) => {
    let nameTypeDay = ''

    if ((day === 1) || (day > 20 && day % 10 === 1)) {
        nameTypeDay = 'день'
    } else if ((day >= 2 && day <= 4) || (day > 20 && day % 10 >= 1 && day % 10 <= 4)) {
        nameTypeDay = 'дня'
    } else { nameTypeDay = 'дней' }

    return nameTypeDay
}

export const RGBToHSL = (r: number, g: number, b: number) => {
    r /= 255
    g /= 255
    b /= 255
    const l = Math.max(r, g, b)
    const s = l - Math.min(r, g, b)
    const h = s
        ? l === r
            ? (g - b) / s
            : l === g
                ? 2 + (b - r) / s
                : 4 + (r - g) / s
        : 0
    return [
        60 * h < 0 ? 60 * h + 360 : 60 * h,
        100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0),
        (100 * (2 * l - s)) / 2,
    ]
}
