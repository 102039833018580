import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Markup } from 'interweave'
import { useNavigate, useParams } from 'react-router-dom'

import { notification } from 'antd'

import Icon from '@mdi/react'
import { mdiWindowClose } from '@mdi/js'

import { AppStateType } from '../../bll/store'
import { courseInitialized, createApplicationCourse, createQuestionCourse } from '../../bll/common/commonOperations'
import Course from './course'

const CourseContainer = (props: any) => {
    const urlParams = useParams()
    const navigate = useNavigate()

    const [getIsVisibleMenu, setIsVisibleMenu] = React.useState(false) // Видимость меню

    const [getIsOpenApplication, setIsOpenApplication] = React.useState(false) // Открыто или закрыто окно, регистрация заявки
    const [getIsOpenAnswer, setIsOpenAnswer] = React.useState(false) // Открыто или закрыто окно, ответа клиенту

    const [getIsVisibleAnswerClient, setIsVisibleAnswerClient] = React.useState(false) // Видимость ответа клиенту, при отправке вопроса
    const [getValueMessage, setValueMessage] = React.useState('') // Сообщение клиенту

    const [getValueName, setValueName] = React.useState('') // Имя клиента
    const [getValuePhone, setValuePhone] = React.useState('') // Телефон клиента
    const [getValuePackageName, setValuePackageName] = React.useState('') // Имя пакета при регистрации заявки

    React.useEffect(() => {
        props.courseInitialized(urlParams.id)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const openMsg = (
        title: string, message: string,
        type: 'info' | 'success' | 'warning' | 'error', 
        placement: 'topLeft' | 'top' | 'topRight' | 'bottomLeft' | 'bottom' | 'bottomRight') => {
        
        notification[type]({
            message: title,
            description: <Markup content={message} />,
            placement: placement,
            duration: 10,
            closeIcon: <Icon path={mdiWindowClose} size={0.8} />,
            style: { background: '#303B44', boxShadow: '0 0 10px 6px rgba(0, 0, 0, .6)' }
        })
    }

    
    // Открытие (Закрытие) окна, регистрация заявки
    const openApplication = (isOpen: boolean, packageName: string = '') => {
        setValuePackageName(packageName)
        setValueName('')
        setValuePhone('')

        setIsOpenApplication(isOpen)
    }

    // Кнопка отправить, в окне регистрации заявки
    const sendApplication = () => {
        let validateError = false

        if (getValueName.length === 0) {
            validateError = true
            openMsg("Ваше имя...", 'Чтобы отправить заявку, необходимо ввести <b>Ваше имя</b>.', "info", "bottomRight")
        }

        if (getValuePhone.length < 6) {
            validateError = true
            openMsg("Ваш телефон...", 'Чтобы отправить заявку, необходимо ввести <b>Ваш телефон</b>.', "info", "bottomRight")
        }

        if (!validateError) {
            props.createApplicationCourse(urlParams.id, getValueName, getValuePhone, getValuePackageName).then((result: any) => {
                switch (result?.code) {
                    case 'OK':
                        setValueName('')
                        setValuePhone('')
                        setIsOpenApplication(false)

                        setValueMessage(`Заявка успешно зарегистрированна!<br/>В ближайшее время мы Вам перезвоним.`)
                        setIsOpenAnswer(true)
                    break

                    case "ERR_TIME_LIMIT": 
                        setIsOpenApplication(false)
                        setValueMessage(`<b>${getValueName}</b> повторить регистрацию заявки <b>Вы</b> сможете, только через 10 минут.`)
                        setIsOpenAnswer(true)
                    break

                    case "ERR_QUERY_STRING": navigate('/error'); break
                    case "ERR_COURSE": navigate('/error'); break
                    default: navigate('/error'); break
                }
            })
        }
    }

    // Кнопка отправить, в окне задать вопрос
    const sendQuestion = () => {
        let validateError = false

        if (getValueName.length === 0) {
            validateError = true
            openMsg("Ваше имя...", 'Чтобы задать вопрос, необходимо ввести <b>Ваше имя</b>.', "info", "bottomRight")
        }

        if (getValuePhone.length < 6) {
            validateError = true
            openMsg("Ваш телефон...", 'Чтобы задать вопрос, необходимо ввести <b>Ваш телефон</b>.', "info", "bottomRight")
        }

        if (!validateError) {
            props.createQuestionCourse(urlParams.id, getValueName, getValuePhone).then((result: any) => {
                switch (result?.code) {
                    case 'OK': 
                        setValueMessage(`Спасибо за обращение <b>${getValueName}</b>!<br/>Наш оператор свяжется с Вами.`)
                        setIsVisibleAnswerClient(true)
                    break

                    case "ERR_TIME_LIMIT": 
                        setValueMessage(`<b>${getValueName}</b> повторить отправку <b>Вашего</b> вопроса возможно, только через 10 минут.`)
                        setIsVisibleAnswerClient(true)
                    break

                    case "ERR_QUERY_STRING": navigate('/error'); break
                    default: navigate('/error'); break
                }
            })
        }
    }

    // Редактирование цифровых полей с проверкой
    const changeNumberValue = (value: string) => {
        const re = /^[0-9\b]+$/
        if (value === '' || re.test(value)) {
            setValuePhone(value)
        }
    }

    // Свойства передаваемые в компоненту
    const propsToComponent = {
        getIsVisibleMenu, setIsVisibleMenu, // Видимость меню

        getIsOpenAnswer, setIsOpenAnswer, // Открыто или закрыто окно, ответа клиенту

        getIsOpenApplication, // Открыто или закрыто окно, регистрация заявки
        getIsVisibleAnswerClient, // Видимость ответа клиенту, при отправке вопроса
        getValueMessage, // Сообщение клиенту

        openApplication, // Открытие (Закрытие) окна, регистрация заявки
        sendApplication, // Кнопка отправить, в окне регистрации заявки
        sendQuestion, // Кнопка отправить, в окне задать вопрос

        getValueName, setValueName, // Имя клиента
        getValuePhone, changeNumberValue, // Телефон клиента
        getValuePackageName, // Имя пакета при регистрации заявки
    }
    
    
    return (
        <Course {...props} {...propsToComponent} />
    )
}

let mapState = (state: AppStateType) => {
    return {
        msg: state.msg,
        programList: state.common.programList,
        commentsCourseList: state.common.commentsCourseList,
        questionsList: state.common.questionsList,
        page: state.common.courseData,
        setting: state.common.settings,
        files: state.common.otherFiles
    }
}

export default compose(connect(mapState, { courseInitialized, createQuestionCourse, createApplicationCourse }))(CourseContainer)