import React from 'react'
import parse from 'html-react-parser'

// import { Popover } from 'antd'

// import HeightOutlinedIcon from '@mui/icons-material/HeightOutlined'
// import MultipleStopOutlinedIcon from '@mui/icons-material/MultipleStopOutlined'
// import PictureInPictureAltOutlinedIcon from '@mui/icons-material/PictureInPictureAltOutlined'
import WallpaperOutlinedIcon from '@mui/icons-material/WallpaperOutlined'
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined'

import empty from './../../../../css/img/empty.png'
import { BootstrapTooltip } from '../../../common/ui/tooltip'

const Section01 = (props: any) => {
    return (
        <section>
            <div className='container CB01' style={{ background: `${props.page.b01.bg_color} url(${props.page.b01.bg_img_path}) no-repeat center/100% 100%`, marginTop: '40px' }}>
                <div className='block'>
                    <div className='content'>
                        <article>
                            <BootstrapTooltip title="Редактировать" placement="top-end" arrow>
                                <h1 className='itemHover' onClick={() => props.openEditTextSecondary(true, '01', 'text_1', 'text', props.page.b01.text_1)}>
                                    {parse(props.page.b01.text_1 || 'Редактировать')}
                                </h1>
                            </BootstrapTooltip>

                            <BootstrapTooltip title="Редактировать" placement="bottom-end" arrow>
                                <h2 className='itemHover' onClick={() => props.openEditTextSecondary(true, '01', 'text_2', 'text', props.page.b01.text_2)}>
                                    {parse(props.page.b01.text_2 || 'Редактировать')}
                                </h2>
                            </BootstrapTooltip>
                        </article>

                        <aside>
                            <div className='buttonPrimary'>Записаться на курс</div>
                            <div className='buttonSecondary'>Задать вопрос</div>
                        </aside>

                        <BootstrapTooltip title="Редактировать" placement="bottom-end" arrow>
                            <h4 className='itemHover' onClick={() => props.openEditTextPrimary(true, '01', 'text_3', 'text', props.page.b01.text_3 || '')}>
                                {`${props.page.b01.text_3 || 'Редактировать'} ${new Date(props.page.b01.date_start).toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric' })}`}
                            </h4>
                        </BootstrapTooltip>
                    </div>

                    <BootstrapTooltip title="Редактировать" placement="bottom" arrow>
                        <img className='itemHover' style={{ maxWidth: '400px' }}
                            src={props.page.b01.img_1_path || empty} alt={props.page.b01.text_1?.replace(/<[^>]+>/g, ' ')} 
                            onClick={() => props.openEditImage(true, '01', 'img_1_path', 'image', props.page.b01.img_1_path, props.page.b01.img_1_name)}
                        />
                    </BootstrapTooltip>
                </div>

                <section className='myPopoverVertical'>
                    <h5>Блок № 1</h5>
                    
                    <div className='dividerPrimary' style={{ height: '40px' }}></div>

                    <BootstrapTooltip title="Видимость блока" placement="bottom" arrow>
                        <input type="checkbox"
                            checked={props.page.b01.is_active === 1 ? true : false}
                            onChange={(e) => props.onChangeBlockActive('01', 'is_active', 'isActive', e.target.checked)}
                        />
                    </BootstrapTooltip>

                    {/* <BootstrapTooltip title="Редактировать высоту изображение заднего фона" placement="left" arrow>
                        <div className='myButtonIconSecondaryBorder' onClick={() => props.openEditTextNumber(true, '01', 'b1_bg_img_height', 'bg_img_size', props.b1.bg?.img_height)}>
                            <HeightOutlinedIcon />
                        </div>
                    </BootstrapTooltip> */}

                    {/* <BootstrapTooltip title="Редактировать ширину изображение заднего фона" placement="left" arrow>
                        <div className='myButtonIconSecondaryBorder' onClick={() => props.openEditTextNumber(true, '01', 'b1_bg_img_width', 'bg_img_size', props.b1.bg?.img_width)}>
                            <MultipleStopOutlinedIcon />
                        </div>
                    </BootstrapTooltip> */}

                    {/* <BootstrapTooltip title="Редактировать позицию изображение заднего фона" placement="left" arrow>
                        <div className='myButtonIconSecondaryBorder' onClick={() => props.openEditPosition(true, '01', 'b1_bg_img_position', 'position', props.b1.bg?.img_position)}>
                            <PictureInPictureAltOutlinedIcon />
                        </div>
                    </BootstrapTooltip> */}

                    <div className='dividerPrimary' style={{ height: '40px' }}></div>

                    <BootstrapTooltip title="Редактировать изображение заднего фона" placement="bottom" arrow>
                        <div className="myButtonIconSecondaryBorder" onClick={() => props.openEditImage(true, '01', 'bg_img_path', 'image', props.page.b01.bg_img_path, props.page.b01.bg_img_name)}>
                            <WallpaperOutlinedIcon />
                        </div>
                    </BootstrapTooltip>

                    <BootstrapTooltip title="Редактировать задний фон блока" placement="bottom" arrow>
                        <div className="myButtonIconSecondaryBorder" onClick={() => props.openEditColor(true, '01', 'bg_color', 'color_background', props.page.b01.bg_color)}>
                            <PaletteOutlinedIcon />
                        </div>
                    </BootstrapTooltip>

                </section>
            </div>
        </section>
    )
}

export default Section01